import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AppDuc } from 'sales-app/modules/App/duc'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { NotFoundBlock } from 'sales-app/utils/sharedComponents/Error'
import Login from 'sales-app/modules/Auth/containers/Login'
import ForgotPassword from 'sales-app/modules/Auth/containers/ForgotPassword'
import ResetPassword from 'sales-app/modules/Auth/containers/ResetPassword'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Wrapper } from 'sales-app/utils/sharedComponents/Wrapper'
import { Headers } from 'sales-app/utils/sharedComponents/Header'

import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { TwoStepVerification } from './containers/TwoStepVerification'

const Illustration = styled.img`
	width: 75%;
`

const Heading = styled.p`
	font-size: 24px;
	width: 100%;
	font-weight: medium;
	text-align: center;
	letter-spacing: 0px;
	font-family: Roboto;
	color: #224099;
	lineheight: 36px;
	opacity: 1;
`

const ComponentsMap = {
	'sign-in': Login,
	'forgot-password': ForgotPassword,
	'reset-password': ResetPassword,
	'two-step-verification': TwoStepVerification,
}

const AuthCore = () => {
	const { t } = useTranslation()
	const { payload = {} } = useSelector(AuthDuc.selectors.location)
	const { action } = payload
	const CoreComponent = ComponentsMap[action] || NotFoundBlock
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	return (
		<>
			<Headers />
			<Wrapper>
				{isMobile && <Spacer size={20} />}
				<Box
					row={!isMobile}
					style={{
						alignItems: 'flex-start',
						width: '100%',
						minHeight: '100vh',
						height: '100%',
						position: 'relative',
						overflowY: 'auto',
					}}
				>
					{!isMobile && (
						<Box
							style={{
								width: '42%',
								height: '100%',
								background:
									' #E9F2FF 0% 0% no-repeat padding-box',
								opacity: 1,
							}}
							alignItems="flex-end"
						>
							<Box
								center
								justifyContent="center"
								alignSelf="center"
								style={{
									width: '90%',
									position: 'relative',
									top: '20%',
									overflow: 'initial',
									display: 'flex',
									justifyContent: 'center',
									alignSelf: 'center',
									alignItems: 'center',
									flexDirection: 'column',
									padding: '0%',
									zIndex: theme.zIndex.level2,
								}}
							>
								<Spacer size={32} />
								<Box
									style={{
										width: '100%',
										height: '100%',
										alignItems: 'center',
									}}
								>
									<Illustration
										src="/img/register.svg"
										alt="dibiz"
									/>
									<Spacer size={120} />
									<Heading
										style={{
											fontWeight: 'bold',
										}}
									>
										{t('login.signUpHeading')}
									</Heading>
									<Spacer size={10} />
									<Heading
										style={{
											fontSize: '18px',
											lineheight: '27px',
										}}
									>
										{t('login.signUpNote')}
									</Heading>
								</Box>
							</Box>
						</Box>
					)}
					<Box
						style={{
							zIndex: 1,
							width: isMobile ? '100%' : '60%',
							height: '100%',
						}}
					>
						<CoreComponent />
					</Box>
				</Box>

				{/* <Footers /> */}
			</Wrapper>
		</>
	)
}

export default AuthCore
