import React from 'react'
import { getIn, setIn, addLast, merge } from 'timm'
import { convertFirstCharToLower } from 'sales-app/utils/helpers'
import { useTranslation } from 'react-i18next'
import { Text } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import Icon404 from 'ui-lib/icons/404.svg'
import theme from 'ui-lib/utils/base-theme'
import styled from 'styled-components'

export const initiateSort = (
	dispatch,
	meta,
	targetActionCreator,
	location,
	queryRoot
) => {
	const sortRoot = `${meta.docType}|${meta.field}`
	let currentFiltersForType = getIn(location, ['query', meta.type]) || []
	currentFiltersForType = Array.isArray(currentFiltersForType)
		? currentFiltersForType
		: [currentFiltersForType]
	currentFiltersForType = currentFiltersForType.filter(
		key => key.indexOf(sortRoot) === 0
	)

	if (meta.order) {
		const sortKey = `${sortRoot}|${meta.order}`

		currentFiltersForType = addLast(currentFiltersForType, sortKey)
	}
	dispatch(
		targetActionCreator(
			meta.docType,
			[meta.type],
			setIn(
				location,
				['query', queryRoot || meta.type],
				currentFiltersForType
			),
			true
		)
	)
}

export const transformSortStringsToBEQueries = (str = []) =>
	str.reduce((agg, _str = '') => {
		const splitArr = _str.split('|')

		if (splitArr.length < 3) return agg

		const root = splitArr.shift()
		const sortString = `${splitArr[1]}(${splitArr[0]})`

		return merge(agg, { [root]: sortString })
	}, {})

export const extractOrgIDsFromResponses = (responses = [], type) => {
	const uniqueOrgIds = []
	const allLists = responses.reduce((agg, resp) => {
		return agg.concat(getIn(resp, ['data', 'list']) || [])
	}, [])
	if (type === 'partner') {
		allLists.forEach(list => {
			if (!uniqueOrgIds.includes(list.initiatorID)) {
				uniqueOrgIds.push(list.initiatorID)
			}
			if (!uniqueOrgIds.includes(list.receiverID)) {
				uniqueOrgIds.push(list.receiverID)
			}
		})
	} else
		allLists.forEach(list => {
			if (!uniqueOrgIds.includes(list.initiatingPartyID)) {
				uniqueOrgIds.push(list.initiatingPartyID)
			}

			if (!uniqueOrgIds.includes(list.receivingPartyID)) {
				uniqueOrgIds.push(list.receivingPartyID)
			}
		})

	return uniqueOrgIds
}

export const extractSortQueries = (responses = {}, currentSortQueries = []) => {
	let _currentSortQueries = [...currentSortQueries]
	const newQueries = Object.keys(responses).reduce((agg, key) => {
		const sortsApplied = getIn(responses, [key, 'sortBy']) || []
		_currentSortQueries = _currentSortQueries.filter(
			_key => _key.indexOf(key) < 0
		)
		if (sortsApplied.length) {
			return agg.concat(
				...sortsApplied.map(({ field, descending }) => {
					const rootKey = `${key}|${convertFirstCharToLower(field)}`

					return `${rootKey}|${descending ? 'desc' : 'asc'}`
				})
			)
		}

		return agg
	}, [])

	return [..._currentSortQueries, ...newQueries]
}

/** Parses query filter strings to be part of backend query group */
export const transformFilterStringsToBEQueries = (filters = {}) => {
	return Object.keys(filters).reduce((agg, key) => {
		const aggregator = agg
		let criterias = filters[key] || []
		criterias = Array.isArray(criterias) ? criterias : [criterias]

		const queryGroup = []
		// group values by the filter condition

		const criteriasGrouped = criterias.reduce((_agg, _filterStr) => {
			// here the final one should be singled out into a string of key value pairs

			const _aggregator = _agg
			let parts = _filterStr.split('|')

			if (key === 'query') {
				parts = [key, _filterStr]
			}

			if (parts.length !== 2) {
				// default to equal
				parts = ['eq', parts[0]]
			}

			const criteria = parts[0]
			const value = parts[1]

			if (criteria === 'eq' && ['any', 'state->any'].includes(value)) {
				// skip these since they are default filters
				return _aggregator
			}

			const existing = getIn(_aggregator, [criteria]) || []

			if (!existing.includes(value))
				_aggregator[criteria] = addLast(existing, value)

			return _aggregator
		}, {})

		if (criteriasGrouped.lte && criteriasGrouped.gte) {
			// possible range, lets group them as such.
			const start = criteriasGrouped.lte[0] // singular values we ignore rest
			const end = criteriasGrouped.gte[0] // singular values we ignore rest
			if (start && end) queryGroup.push(`eq(${start}~${end})`)
		} else if (criteriasGrouped.lte) {
			queryGroup.push(`lte(${criteriasGrouped.lte.join(',')})`)
		} else if (criteriasGrouped.gte) {
			queryGroup.push(`gte(${criteriasGrouped.gte.join(',')})`)
		} else if (criteriasGrouped.lt) {
			queryGroup.push(`lt(${criteriasGrouped.lt.join(',')})`)
		} else if (criteriasGrouped.gte) {
			queryGroup.push(`gt(${criteriasGrouped.gt.join(',')})`)
		}

		// check for other criterias
		if (criteriasGrouped.in) {
			// logical or
			queryGroup.push(`in(${criteriasGrouped.in.join(',')})`)
		}

		if (criteriasGrouped.eq) {
			// logical and
			queryGroup.push(`eq(${criteriasGrouped.eq.join(',')})`)
		}

		if (criteriasGrouped.query) {
			queryGroup.push(criteriasGrouped.query)
		}

		aggregator[key] = queryGroup

		return aggregator
	}, {})
}
export const extractFilterQueries = (response = {}) =>
	(getIn(response, ['filterBy']) || []).reduce(
		(agg, { field, operator, value }) => {
			let { stateTree, queryTree } = agg
			let _filters = getIn(stateTree, [field, operator]) || []
			let _queries = getIn(queryTree, [field]) || []

			// if value is a range, set it appropriately
			if (value.indexOf('->') > -1) {
				// if the filter has prefix, exclude it
				_queries = addLast(
					_queries,
					`${operator}|${value.split('->')[1]}`
				)
				_filters = addLast(_filters, value.split('->')[1])
			} else if (value.indexOf('~') > -1) {
				const [start, end] = value.split('~')

				_queries = addLast(_queries, `lte|${start}`)
				_queries = addLast(_queries, `gte|${end}`)

				queryTree = setIn(queryTree, [field], _queries)

				stateTree = setIn(stateTree, [field, 'lte'], [start])
				stateTree = setIn(stateTree, [field, 'gte'], [end])
			} else {
				// break values into parts
				const targetValue = value.split(',')
				_queries = addLast(
					_queries,
					targetValue.map(t => `${operator}|${t}`)
				)
				queryTree = setIn(queryTree, [field], _queries)

				_filters = addLast(_filters, targetValue)
				// push the new data into the set
				stateTree = setIn(stateTree, [field, operator], _filters)
			}

			return { stateTree, queryTree }
		},
		{ queryTree: {}, stateTree: {} }
	)

export const getTargetFilterQueries = (
	currentFilters,
	filterSegment,
	filterValue,
	prefix = ''
) => {
	let targetQueries = currentFilters || []
	targetQueries = Array.isArray(targetQueries)
		? targetQueries
		: [targetQueries]

	let targetFilterValue = `eq|${prefix}${filterValue}`
	const addMultiple = filterSegment === 'multiple'
	const notEqual = filterSegment === 'not-equal'
	const isStartDate = filterSegment === 'start_date'
	const isEndDate = filterSegment === 'end_date'
	const dateRange = filterSegment === 'date_range'

	if (isStartDate) {
		// remove all existing start dates
		targetQueries = targetQueries.filter(q => q.indexOf('gte|') < 0)

		// add the end date range
		targetFilterValue = `gte|${prefix}${filterValue}`
	}

	if (isEndDate) {
		targetQueries = targetQueries.filter(q => q.indexOf('lte|') < 0)
		// add the end date range
		targetFilterValue = `lte|${prefix}${filterValue}`
	}

	if (addMultiple) {
		targetFilterValue = `in|${prefix}${filterValue}`
	}

	if (notEqual) {
		targetFilterValue = `neq|${prefix}${filterValue}`
	}
	if (dateRange) {
		targetFilterValue = `eq|${getIn(filterValue, [
			'startDate',
		])}~${getIn(filterValue, ['endDate'])}`
	}

	// check if already in the filter
	if (!targetQueries.includes(targetFilterValue))
		targetQueries = addLast(targetQueries, targetFilterValue)

	return targetQueries
}

export const APIConfigError = () => {
	const { t } = useTranslation()

	return (
		<Box
			center
			style={{
				background: theme.color.grey0,
				width: '100vw',
				height: '100vh',
			}}
		>
			<IconWrapper style={{ width: 400, height: 400 }}>
				<Icon glyph={Icon404} />
			</IconWrapper>
			<Text
				style={{
					margin: '60px 0 16px',
					color: '#172B4D',
					fontSize: 32,
				}}
			>
				Uh Oh!
			</Text>
			<Text style={{ color: '#172B4D', fontSize: 32 }}>
				{t('common.apiConfigNotAvailable')}
			</Text>
		</Box>
	)
}
export const CTAWrapper = styled(Box)(p => ({
	padding: 10,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	display: 'flex',
	flexDirection: 'row',
	border: `1px solid ${theme.color.grey4}`,
}))
