import React from 'react'
import { Input } from 'ui-lib/components/Input'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Label } from 'ui-lib/components/Label'
import theme from 'ui-lib/utils/base-theme'

export const CustomInput = ({
	value,
	handleChange,
	handleBlur,
	touched,
	errors,
	label,
	name,
	type,
	placeholder = '',
	required = true,
}) => {
	return (
		<Box style={{ width: '100%' }}>
			<Label
				required={required}
				style={{
					fontWeight: '400px',
					fontSize: '16px',
					color: theme.color.accent2,
				}}
			>
				{label}
			</Label>
			<Spacer size={8} />
			<Input
				value={value}
				name={name}
				type={type}
				placeholder={placeholder}
				onChange={handleChange}
				onBlur={handleBlur}
				error={touched && errors}
				extendStyles={{
					background:
						value.length === 0
							? `${theme.color.grey11} 0% 0% no-repeat padding-box`
							: `${theme.color.white} 0% 0% no-repeat padding-box`,
					boxShadow:
						value.length === 0
							? '0px 6px 16px #05050707'
							: `0px 2px 8px ${theme.color.blue6}`,
					border:
						value.length === 0
							? `1px solid ${theme.color.grey4}`
							: `1px solid ${theme.color.blue5}`,
					borderRadius: '4px',
					opacity: 1,
				}}
			/>
		</Box>
	)
}
