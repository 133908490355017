/* @flow */
import React, { useContext, useState, useEffect } from 'react'
import { TextInput, Button, ThemeContext } from 'grommet'
import styled from 'styled-components'
import parsePhoneNumber from 'libphonenumber-js'
import { getIn } from 'timm'
import { Hide, View, FormSearch } from 'grommet-icons'
import { Box } from '../../utils/Box'
import { FieldText } from '../FieldText'
import { Label } from '../Label'
import { Spinner } from '../Spinner'
import { SelectSearch } from '../Select'
import { Spacer } from '../../utils/Spacer'
import { countryCode } from '../../utils/config'

type Props = {
	uniqueID: string,
	value: string,
	placeholder: string,
	label?: string,
	error?: string,
	name: string,
	onChange: (value: SyntheticInputEvent<> | { [string]: string }) => void,
	disabled?: boolean,
	required?: boolean,
	width?: number | string,
	helpText?: string,
	hideLabel?: boolean,
	type?: string,
	options: Array<string | { name: string, label: string }>,
	returnObject?: boolean,
	onBlur: (value: SyntheticInputEvent<> | { [string]: string }) => void,
	returnKeyValue?: boolean,
	hideError?: boolean,
	onSelection: any => void,
	extendStyles?: {},
	box: boolean,
	disableChooseOne: boolean,
}

const FocusLine = styled.div(({ theme, focussed, error }) => ({
	width: '100%',
	borderBottom: `1px solid ${theme.color.primary}`,
	...(error && { borderBottom: `1px solid ${theme.color.error}` }),
	...(!error && focussed
		? { borderBottom: `1px solid ${theme.color.accent}` }
		: {}),
}))

const BoxFocusLine = styled.div(({ theme, focussed, error, value }) => ({
	width: '100%',
	borderBottom: `2px solid ${theme.color.grey7}`,
	...(error && { borderBottom: `2px solid ${theme.color.error}` }),
	...(!error && focussed
		? { borderBottom: `2px solid ${theme.color.primary}` }
		: {}),
	...(value &&
		!error && { borderBottom: `2px solid ${theme.color.primary}` }),
}))

const PhoneWrapper = styled(Box)(p => ({
	backgroundColor: p.theme.color.white,
	border: `1px solid ${p.theme.color.grey3}`,
	padding: 0,
	height: '43px',
	color: p.theme.color.grey8,
}))

const PlaceHolderElement = ({ theme, placeholder }) => (
	<div style={{ marginTop: -1 }}>
		<span style={{ color: theme.color.grey7 }}>{placeholder}</span>
		<span style={{ color: theme.color.error }}> * </span>
	</div>
)

const Input = ({
	value,
	placeholder,
	onChange = () => {},
	name,
	disabled,
	error,
	label,
	helpText,
	hideLabel,
	required = false,
	type = 'text',
	returnKeyValue,
	onBlur: onBlurProp = () => {},
	hideError,
	width,
	box,
	regex,
	extendStyles,
	countNegative = false,
}: Props) => {
	const uniqueRef = name || label || ''
	const ID = `input-${uniqueRef || '---'}`
	const theme = useContext(ThemeContext)
	const [hasFocus, setFocus] = useState(false)
	const [_value, setValue] = useState(
		typeof value !== 'undefined' ? value : ''
	)

	const onFocus = () => setFocus(true)
	useEffect(() => {
		if (_value || _value === 0) setValue(_value)
	}, [_value])

	const onBlur = e => {
		onBlurProp(returnKeyValue ? { [uniqueRef]: e.target.value } : e)
		setFocus(false)
	}

	let PlaceHolder = placeholder

	if (!label && required)
		PlaceHolder = (
			<PlaceHolderElement theme={theme} placeholder={placeholder} />
		)

	if (hasFocus || _value || _value === 0) PlaceHolder = ''

	return (
		<Box overflow width={width || 'inherit'}>
			{!hideLabel && label && (
				<Label for={ID} required={required}>
					{label}
				</Label>
			)}
			<TextInput
				plain={!!label}
				name={uniqueRef}
				id={ID}
				{...(!countNegative ? { min: 0 } : {})}
				step="any"
				type={type}
				value={_value}
				onChange={e => {
					if (regex) {
						e.target.value = e.target.value.replace(regex, '')
					}
					setValue(e.target.value)
					onChange(
						returnKeyValue ? { [uniqueRef]: e.target.value } : e
					)
				}}
				disabled={disabled}
				placeholder={PlaceHolder}
				onBlur={onBlur}
				onFocus={onFocus}
				style={{
					backgroundColor: 'white',
					padding: '10px',
					borderRadius: label ? 'unset' : '',
					boxShadow: 'none',
					border: box ? `1px solid ${theme.color.primary}` : '',
					...(error
						? {
								color: theme.color.error,
								borderColor: theme.color.error,
						  }
						: {}),
					...extendStyles,
				}}
			/>
			{label && !box && <FocusLine focussed={hasFocus} error={error} />}
			{helpText && <FieldText show>{helpText}</FieldText>}
			{!hideError && (
				<>
					<Spacer size={8} />
					<FieldText error show={!!error}>
						{error}
					</FieldText>
				</>
			)}
		</Box>
	)
}

const Wrapper = styled(Box)(p => ({
	borderWidth: '1px',
	borderStyle: 'solid',
	backgroundColor: p.theme.color.white,
	borderColor: p.error ? p.theme.color.error : p.theme.color.primary,
	borderImage: 'initial',
	padding: 0,
	...(p.label && {
		border: 'none',
		borderRadius: 'initial',
		borderBottom: `1px solid ${
			p.error ? p.theme.color.error : p.theme.color.primary
		}`,
	}),
	...(p.focus && {
		outlineColor: p.theme.color.accent,
		boxShadow: `0 0 2px 2px ${p.theme.color.accent}`,
		borderColor: p.error ? p.theme.color.error : p.theme.color.accent,
	}),
}))

const PasswordInput = ({
	name,
	value,
	uniqueID,
	onChange = () => {},
	placeholder,
	error,
	returnKeyValue,
	helpText,
	onBlur = () => {},
	hideError,
	required = false,
	extendStyles,
	width,
	label,
}: Props) => {
	const ID = uniqueID || `input-${name}-${placeholder}`
	const theme = useContext(ThemeContext)
	const [focussed, setFocus] = useState(false)
	const [_value, setValue] = useState(value || '')
	const [reveal, setReveal] = useState(false)

	return (
		<Box overflow width={width || 'inherit'}>
			{label && (
				<Label
					for={ID}
					required={required}
					style={{ marginBottom: '12px' }}
					black
				>
					{label}
				</Label>
			)}
			<Wrapper
				row
				center
				error={!!error}
				focus={focussed}
				style={{ ...extendStyles }}
			>
				<TextInput
					name={name || ID}
					plain
					placeholder={placeholder}
					type={reveal ? 'text' : 'password'}
					value={_value}
					style={{
						boxShadow: 'none',
						...(error
							? {
									color: theme.color.error,
							  }
							: {}),
					}}
					// onFocus={() => {
					// 	setFocus(true)
					// }}
					onChange={e => {
						const inputValue = e.target.value
						setValue(inputValue)
						onChange(
							returnKeyValue ? { [name]: e.target.value } : e
						)
					}}
					onBlur={e => {
						setFocus(false)
						onBlur(returnKeyValue ? { [name]: e.target.value } : e)
					}}
				/>
				<Button
					icon={
						reveal ? <View size="medium" /> : <Hide size="medium" />
					}
					style={{ padding: '0px 6px' }}
					onClick={() => setReveal(!reveal)}
				/>
			</Wrapper>
			{helpText && <FieldText show>{helpText}</FieldText>}
			{!hideError && (
				<>
					<Spacer size={8} />
					<FieldText error show={!!error}>
						{error}
					</FieldText>
				</>
			)}
		</Box>
	)
}
const customTheme = {
	textInput: {
		suggestions: {
			extend: {
				padding: '10px',
				li: {
					paddingTop: '10px',
				},
			},
		},
	},
}

type SuggestionProps = {
	...Props,
	onSelect: string => void,
	suggestions: Array<string>,
	isLoading?: boolean,
}

const SuggestionsInput = ({
	name,
	value,
	uniqueID,
	onChange,
	placeholder,
	error,
	returnKeyValue,
	helpText,
	suggestions,
	hideError,
	type,
	label,
	hideLabel,
	required,
	isLoading,
	onSelect,
	extendStyles,
	key,
}: SuggestionProps) => {
	const ID = uniqueID || `input-${name}-${placeholder}`
	const theme = useContext(ThemeContext)
	const [_value, setValue] = useState(value || '')

	const handelSelect = e => {
		setValue(e.suggestion)
		onSelect(e.suggestion)
	}
	const handleChange = e => {
		setValue(e.target.value)
		onChange(returnKeyValue ? { [name]: e.target.value } : e)
	}
	const onHighlight = event => {
		if (event.target.selectionStart !== event.target.selectionEnd) {
			// eslint-disable-next-line no-console
			console.log(
				event.target.value.substring(
					event.target.selectionStart,
					event.target.selectionEnd
				)
			)
		}
	}

	return (
		<Box overflow>
			{!hideLabel && label && (
				<Label for={ID} required={required} htmlFor={ID}>
					{label}
				</Label>
			)}
			<Wrapper
				row
				center
				error={!!error}
				label={!!label}
				style={{ ...extendStyles }}
			>
				<ThemeContext.Extend value={customTheme}>
					<TextInput
						key={key}
						dropProps={{ height: 'small' }}
						name={ID}
						plain
						placeholder={placeholder}
						type={type}
						value={_value}
						suggestions={suggestions}
						style={{
							...(error
								? {
										color: theme.color.error,
								  }
								: {}),
						}}
						onSelect={onHighlight}
						onSuggestionSelect={handelSelect}
						onChange={handleChange}
					/>
				</ThemeContext.Extend>

				<Button
					icon={
						isLoading ? (
							<Spinner relative size={20} />
						) : (
							<FormSearch />
						)
					}
					style={{ padding: '0px 6px' }}
				/>
			</Wrapper>
			{helpText && <FieldText show>{helpText}</FieldText>}
			{!hideError && (
				<FieldText error show={!!error}>
					{error}
				</FieldText>
			)}
		</Box>
	)
}
SuggestionsInput.defaultProps = {
	suggestions: [],
	onChange: () => {},
	onBlur: () => {},
	onSelect: () => {},
	type: 'text',
}

const BoxInput = ({
	value,
	placeholder,
	onChange = () => {},
	name,
	disabled,
	error,
	label,
	helpText,
	hideLabel,
	required = false,
	type = 'text',
	returnKeyValue,
	onBlur: onBlurProp = () => {},
	hideError,
	width,
	extendStyles,
}: Props) => {
	const uniqueRef = name || label || ''
	const ID = `input-${uniqueRef || '---'}`
	const theme = useContext(ThemeContext)
	const [hasFocus, setFocus] = useState(false)
	const [_value, setValue] = useState(value || '')

	const onFocus = () => setFocus(true)
	useEffect(() => {
		if (value) setValue(value)
	}, [value])

	const onBlur = e => {
		onBlurProp(returnKeyValue ? { [uniqueRef]: e.target.value } : e)
		setFocus(false)
	}

	let PlaceHolder = placeholder

	if (!label && required)
		PlaceHolder = (
			<PlaceHolderElement theme={theme} placeholder={placeholder} />
		)

	if (hasFocus || _value || _value === 0) PlaceHolder = ''

	return (
		<Box overflow width={width}>
			{!hideLabel && label && (
				<Label
					for={ID}
					required={required}
					style={{ marginBottom: '8px' }}
					black
				>
					{label}
				</Label>
			)}
			<TextInput
				plain={!!label}
				name={uniqueRef}
				id={ID}
				type={type}
				value={_value}
				onChange={e => {
					setValue(e.target.value)
					onChange(
						returnKeyValue ? { [uniqueRef]: e.target.value } : e
					)
				}}
				disabled={disabled}
				placeholder={PlaceHolder}
				onBlur={onBlur}
				onFocus={onFocus}
				style={{
					backgroundColor: theme.color.grey0,
					borderRadius: label ? 'unset' : '',
					border: `1px solid ${theme.color.grey3}`,
					...extendStyles,
				}}
			/>
			{label && (
				<BoxFocusLine
					value={_value}
					focussed={hasFocus}
					error={error && _value}
				/>
			)}
			{helpText && <FieldText show>{helpText}</FieldText>}
			{!hideError && (
				<FieldText error show={!!error && (hasFocus || _value)}>
					{error}
				</FieldText>
			)}
		</Box>
	)
}

const PhoneNumber = ({
	value,
	placeholder,
	onChange = () => {},
	name,
	error,
	label,
	helpText,
	hideLabel,
	disabled = false,
	required = false,
	returnKeyValue,
	options,
	onBlur: onBlurProp = () => {},
	hideError,
	extendStyles,
	width,
}: Props) => {
	const uniqueRef = name || label || ''
	const ID = `input-${uniqueRef || '---'}`
	const theme = useContext(ThemeContext)
	const [hasFocus, setFocus] = useState(false)
	const [_value, setValue] = useState(value || '')
	const [_dialCode, setDialCode] = useState('')
	const [_phoneNumber, setPhoneNumber] = useState('')

	const onFocus = () => setFocus(true)
	useEffect(() => {
		if (_value) {
			setPhoneNumber(parsePhoneNumber(_value).nationalNumber)
			setDialCode(`+${parsePhoneNumber(_value).countryCallingCode}`)
		}
	}, [setValue, _value, value])

	const onBlur = e => {
		onBlurProp(returnKeyValue ? { [uniqueRef]: e.target.value } : e)
		setFocus(false)
	}
	let PlaceHolder = placeholder

	if (!label && required)
		PlaceHolder = (
			<PlaceHolderElement theme={theme} placeholder={placeholder} />
		)

	if (hasFocus || _value || _value === 0) PlaceHolder = ''

	return (
		<Box overflow width={width}>
			{!hideLabel && label && (
				<Label
					for={ID}
					required={required}
					style={{ marginBottom: '8px' }}
					black
				>
					{label}
				</Label>
			)}
			<PhoneWrapper
				row
				style={{
					border:
						_dialCode === ''
							? `1px solid ${theme.color.grey4}`
							: `1px solid ${theme.color.blue5}`,
					...extendStyles,
				}}
			>
				<Box width="33%">
					<SelectSearch
						name="dialCode"
						value={
							_dialCode === ''
								? _dialCode
								: countryCode.filter(
										_country => _country.label === _dialCode
								  )[0].name
						}
						options={options}
						onChange={__value => {
							const _label =
								getIn(__value, ['dialCode', 'label']) || ''
							setDialCode(_label)
							if (_phoneNumber) {
								setValue(`${_label}${_phoneNumber}`)
								onChange(`${_label}${_phoneNumber}`)
							}
						}}
						disabled={disabled}
						disableChooseOne
						labelKey="label"
						valueKey="name"
						noBorder
						style={{
							background:
								_dialCode === ''
									? `${theme.color.grey11} 0% 0% no-repeat padding-box`
									: `${theme.color.white} 0% 0% no-repeat padding-box`,
							borderRight: `1px solid ${theme.color.grey4}`,
							borderRadius: '4px',
						}}
					/>
				</Box>
				<Spacer size="2%" horizontal />
				<Box width="65%" row center>
					<TextInput
						plain={!!label}
						name={uniqueRef}
						disabled={!_dialCode}
						id={ID}
						type="number"
						value={_phoneNumber}
						onChange={e => {
							if (e.target.value.length === 10) {
								setValue(`${_dialCode}${e.target.value}`)
								onChange(`${_dialCode}${e.target.value}`)
							} else if (e.target.value.length < 10) {
								setPhoneNumber(e.target.value)
							}
						}}
						placeholder={PlaceHolder}
						onBlur={onBlur}
						onFocus={onFocus}
						style={{
							background:
								_phoneNumber.length === 0
									? `${theme.color.grey11} 0% 0% no-repeat padding-box`
									: `${theme.color.white} 0% 0% no-repeat padding-box`,
							boxShadow:
								_phoneNumber.length === 0
									? '0px 6px 16px #05050707'
									: `0px 2px 8px ${theme.color.blue6}`,

							borderRadius: label ? 'unset' : '',
							border:
								_phoneNumber.length === 0
									? `1px solid ${theme.color.grey4}`
									: `1px solid ${theme.color.blue5}`,
							opacity: 1,
							padding: '10px',
						}}
					/>
				</Box>
			</PhoneWrapper>

			{label && (
				<BoxFocusLine
					value={_value}
					focussed={hasFocus}
					error={error}
				/>
			)}
			{helpText && <FieldText show>{helpText}</FieldText>}
			{!hideError && (
				<FieldText error show={!!error}>
					{error}
				</FieldText>
			)}
		</Box>
	)
}

PhoneNumber.defaultProps = {
	onSelection: () => {},
}
export { Input, PasswordInput, SuggestionsInput, BoxInput, PhoneNumber }
