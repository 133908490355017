import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'sales-app/utils/sharedComponents/Error'
import { SettingsDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'
import { ChangePassword } from './containers/ChangePassword'
import { Notifications } from './containers/Notifications'

const modulesMap = {
	dashboard: MainDashboard,
	'change-password': ChangePassword,
	notifications: Notifications,
}

const Admin = () => {
	const activeModule = useSelector(SettingsDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock

	return <Component />
}

export default Admin
