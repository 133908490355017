import theme from 'ui-lib/utils/base-theme'
import debounce from 'lodash/debounce'
import { DeviceDetectionDuc } from './deviceDetectionDuc'

const baseState = DeviceDetectionDuc.initialState
const baseStateAliases = {
	mobile: 'isMobile',
	desktop: 'isDesktop',
	tablet: 'isTablet',
	landscape: 'isLandscape',
	portrait: 'isPortrait',
}
const basePlatforms = ['mobile', 'tablet', 'desktop']
const baseOrientationQueries = theme.orientationQueries
const baseMediaQueries = basePlatforms.reduce((agg, platform) => {
	const aggregator = agg
	const query = theme.mediaQueries[platform]
	if (query) {
		aggregator[platform] = query
	}

	return aggregator
}, {})

// https://support.cloudflare.com/hc/en-us/articles/229373388-Cache-Content-by-Device-Type-Mobile-Tablet-Desktop-
// const basePlatformsRegex = {
// 	mobile: new RegExp(
// 		'phone|windowss+phone|ipod|blackberry|(?:android|bbd+|meego|silk|googlebot) .+?mobile|palm|windowss+ce|opera mini|avantgo|docomo',
// 		'i'
// 	),
// 	tablet: new RegExp(
// 		'ipad|playbook|(?:android|bbd+|meego|silk)(?! .+? mobile)',
// 		'i'
// 	),
// }

const fetchOrientationMatches = () =>
	Object.keys(baseOrientationQueries).reduce((agg, key) => {
		const aggregator = agg
		aggregator[baseStateAliases[key]] = window.matchMedia(
			baseOrientationQueries[key]
		)

		return aggregator
	}, {})

const fetchMediaMatches = () =>
	Object.keys(baseMediaQueries).reduce((agg, key) => {
		const aggregator = agg
		aggregator[baseStateAliases[key]] = window.matchMedia(
			baseMediaQueries[key]
		)

		return aggregator
	}, {})

// use this a reference to attach and detach listener references
const listenerReferences = {}
const initiateClientSync = (stateSyncer, matchersMap = {}) => {
	Object.keys(matchersMap).forEach(stateKey => {
		const mqList = matchersMap[stateKey]
		if (!mqList || !mqList.addListener) return

		// initial sync
		stateSyncer({ [stateKey]: mqList.matches })

		if (!listenerReferences[stateKey]) {
			listenerReferences[stateKey] = debounce(
				e => stateSyncer({ [stateKey]: e.matches }),
				300
			)
		}
		// attach a listener to retrigger on change
		mqList.removeListener(listenerReferences[stateKey])
		mqList.addListener(listenerReferences[stateKey])
	})
}

const syncStateHelper = store => currentState => {
	// server state initialize to the what was detected from server
	store.dispatch(
		DeviceDetectionDuc.creators.syncDeviceDetection(currentState)
	)
}

const handleStateChange = (store, initialDeviceState) => {
	const stateSyncer = syncStateHelper(store)
	const isClient =
		__CLIENT__ &&
		typeof window !== 'undefined' &&
		typeof window.matchMedia !== 'undefined' &&
		typeof document !== 'undefined'

	// if the client re-activeness isn't set, then skip the detection.
	if (isClient) {
		// initialize the handlers
		const deviceMatchMap = fetchMediaMatches()

		document.addEventListener('DOMContentLoaded', () => {
			initiateClientSync(stateSyncer, deviceMatchMap)
		})

		const orientationMatchMap = fetchOrientationMatches()
		// make sure we update the responsive state when the browser changes orientation
		window.addEventListener('orientationchange', () => [
			initiateClientSync(stateSyncer, orientationMatchMap),
		])
	} else {
		stateSyncer(initialDeviceState)
	}
}

export default () => {
	const initialDeviceState = {
		...baseState,
	}

	// return the store enhancer (an enhanced version of `createStore`)
	return createStore => (...args) => {
		// create the store
		const store = createStore(...args)
		// if there is a `window`

		// add the handlers that only fire when the responsive state changes
		handleStateChange(store, initialDeviceState)

		// return the store so that the call is transparent
		return store
	}
}
