import React from 'react'
import { useDispatch } from 'react-redux'
import { DibizOrgsDuc } from 'sales-app/modules/DibizOrgs/duc'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { isEmptyObject } from 'sales-app/utils/helpers'
import { Box } from 'ui-lib/utils/Box'
import { Input } from 'ui-lib/components/Input'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { Label } from 'ui-lib/components/Label'
import { useFormik } from 'formik'
import * as yup from 'yup'

const UpdateFFBPrices = () => {
	const dispatch = useDispatch()

	const validationSchema = yup.object().shape({
		date: yup.string().required('Date is required'),
		'North/Utara': yup
			.number()
			.required('Please enter the price for this region'),
		'South/Selatan': yup
			.number()
			.required('Please enter the price for this region'),
		'Central/Tengah': yup
			.number()
			.required('Please enter the price for this region'),
		'East Coast/Pantai Timur': yup
			.number()
			.required('Please enter the price for this region'),
		Sabah: yup.number().required('Please enter the price for this region'),
		Sarawak: yup
			.number()
			.required('Please enter the price for this region'),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		isSubmitting,
		touched,
		errors,
	} = useFormik({
		initialValues: {
			'North/Utara': 0,
			'South/Selatan': 0,
			'Central/Tengah': 0,
			'East Coast/Pantai Timur': 0,
			Sabah: 0,
			Sarawak: 0,
			date: '',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			const regionPrices = { ..._values }
			delete regionPrices.date

			dispatch(
				DibizOrgsDuc.creators.updateFFBPrices(
					regionPrices,
					_values.date,
					{
						setSubmitting,
					}
				)
			)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.Sarawak || !values.date

	return (
		<Box padding={8} width="100%" height="100%">
			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title="Update Today's FFB Price for all regions" />
			</Box>
			<form onSubmit={handleSubmit}>
				<Box>
					<Box width="48%">
						<Label
							required
							style={{
								fontWeight: '300px',
								fontSize: '16px',
								color: theme.color.accent2,
								width: '100%',
							}}
						>
							Choose Date
						</Label>
						<Spacer size={8} />
						<DatePicker
							required
							toDate
							name="date"
							maxDate={new Date()}
							returnKeyValue
							value={values.date}
							error={touched.date && errors.date}
							onChange={keyValue => {
								setFieldValue('date', keyValue.date)
							}}
						/>
					</Box>
					<Box
						row
						style={{ width: '100%' }}
						justifyContent="space-between"
					>
						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								North/Utara
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values['North/Utara']}
									name="North/Utara"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched['North/Utara'] &&
										errors['North/Utara']
									}
								/>
							</Box>
						</Box>

						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								South/Selatan
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values['South/Selatan']}
									name="South/Selatan"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched['South/Selatan'] &&
										errors['South/Selatan']
									}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						row
						style={{ width: '100%' }}
						justifyContent="space-between"
					>
						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '400px',
									fontSize: '16px',
									color: theme.color.accent2,
								}}
							>
								East Coast/Pantai Timur
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values['East Coast/Pantai Timur']}
									name="East Coast/Pantai Timur"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched['East Coast/Pantai Timur'] &&
										errors['East Coast/Pantai Timur']
									}
								/>
							</Box>
						</Box>

						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Central/Tengah
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values['Central/Tengah']}
									name="Central/Tengah"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched['Central/Tengah'] &&
										errors['Central/Tengah']
									}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						row
						style={{ width: '100%' }}
						justifyContent="space-between"
					>
						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '400px',
									fontSize: '16px',
									color: theme.color.accent2,
								}}
							>
								Sabah
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values.Sabah}
									name="Sabah"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.Sabah && errors.Sabah}
								/>
							</Box>
						</Box>

						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Sarawak
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values.Sarawak}
									name="Sarawak"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.Sarawak && errors.Sarawak}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box alignItems="flex-end">
					<Box width={150}>
						<Button
							isLoading={isSubmitting}
							disabled={disableCTA}
							type="submit"
							primary
							label="Update"
							style={{
								paddingBottom: '50px',
								width: '100%',
							}}
						/>
					</Box>
				</Box>
			</form>
		</Box>
	)
}

export { UpdateFFBPrices }
