import { AdminDuc } from 'sales-app/modules/Admin/duc'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { getIn } from 'timm'
import saga from 'sales-app/modules/Admin/saga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState
	extra.getSagaInjectors().injectSaga('ADMIN', { saga })

	const isMainDashboard = MainRouteDuc.types.ADMIN === type
	const isSubDashboard = MainRouteDuc.types.ADMIN$WDOCREFERENCE === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
	} else if (isSubDashboard) {
		activeModule = 'dashboard'

		if (payload.rootModule === 'company-information') {
			if (payload.action === 'view-companyInfo') {
				dispatch(
					AdminDuc.creators.viewCompanyDetails(
						payload.documentReference
					)
				)
			}
		}
	}

	// load the dashboard of the core module
	dispatch(AdminDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
