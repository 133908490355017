import React from 'react'
import styled from 'styled-components'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import NotificationIcon from 'ui-lib/icons/notification.svg'
import { SmallText } from 'ui-lib/components/Typography'
import { useDateByFormat } from 'sales-app/utils/date'
import theme from 'ui-lib/utils/base-theme'

export const UserProfile = styled.div(p => ({
	backgroundColor: p.theme.color.white,
	borderRadius: '4px',
	display: 'inline-block',
	verticalAlign: 'middle',
	fontSize: '36px',
	lineHeight: '74px',
	textAlign: 'center',
	backgroundPosition: 'unset',
	backgroundSize: 'cover',
	marginRight: '12px',
	height: '74px',
	width: '74px',
	overflow: 'hidden',
	...(p.bgUrl && { backgroundImage: `url(${p.bgUrl})` }),
}))

export const OfflineToast = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	fontSize: theme.fontSize.s,
	lineHeight: '16px',
	background: '#ccc',
	alignItems: 'center',
	position: 'relative',
	paddingLeft: '1rem',
	paddingRight: '1rem',
	paddingTop: '0.25rem',
	paddingBottom: '0.25rem',
	backgroundColor: 'rgb(254, 215, 215)',
	overflow: 'hidden',
})

export const HeaderRightBlock = styled.div({
	height: 'inherit',
	display: 'flex',
	cursor: 'pointer',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	paddingRight: 20,
})

export const LogoSearch = styled.div({
	height: 'inherit',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
})

export const HeaderProfileText = styled.span(p => ({
	fontWeight: p.fontWeight ? p.fontWeight : 400,
	fontSize: p.fontSize ? p.fontSize : p.theme.fontSize.s,
	color: p.theme.color.white,
	title: p.children,
	display: 'block',
	maxWidth: p.fullMaxWidth ? '218px' : '128px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}))

export const UnOrderedList = styled.ul(p => ({
	transition: 'height 0.3s',
	listStyle: 'none',
	position: 'absolute',
	width: p.width ? p.width : '320px',
	height: p.height,
	top: p.top ? p.top : '70px',
	right: p.right ? p.right : 0,
	background: '#fefefe',
	margin: 'none',
	padding: 'none',
	borderRadius: '0 0 3px 3px',
	overflow: 'hidden',
	boxShadow: 'rgba(0, 0, 0, 0.1) -1px 2px 3px',
}))

export const ListItem = styled.li(p => ({
	margin: 'none',
	padding: p.padding ? p.padding : '16px',
	borderBottom: p.borderBottom ? p.borderBottom : '1px solid rgba(0,0,0,0.1)',
	':hover': {
		cursor: 'pointer',
		background: '#F0F0F1',
	},
	':last-child': {
		border: 'none',
	},
}))

const Counter = styled.span(p => ({
	position: 'absolute',
	right: '15px',
	top: '5px',
	width: '10px',
	height: '10px',
	background: p.theme.color.error,
	fontSize: p.theme.fontSize.xs,
	borderRadius: '50%',
	color: 'white',
	zIndex: '2',
}))

export const NotificationBadge = ({ count, size }) => {
	return (
		<>
			{count > 0 && <Counter />}
			<IconWrapper
				size={size}
				style={{
					position: 'relative',
					top: '-4px',
					right: '-10px',
				}}
			>
				<Icon glyph={NotificationIcon} color="#172B4D" />
			</IconWrapper>
		</>
	)
}

export const NotificationItem = ({ onClick, data, padding }) => {
	return (
		<ListItem onClick={() => onClick(data)} padding={padding}>
			<SmallText
				fontSize={theme.fontSize.m}
				style={{
					lineHeight: '16px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					width: '100%',
					display: 'inline-block',
					paddingBottom: '8px',
				}}
				link
				bold
				color="#172B4D"
			>
				{data.message}
			</SmallText>
			<SmallText
				fontSize={theme.fontSize.xs}
				style={{
					display: 'block',
					lineHeight: '16px',
					color: 'rgba(0, 0, 0, 0.5)',
				}}
			>
				{useDateByFormat(data.createdAt, 'd LLL yyyy, hh:mm a')}
			</SmallText>
		</ListItem>
	)
}
