import React, { Suspense, useEffect } from 'react'
import { MainRouteDuc } from 'sales-app/routes/duc'
// $FlowFixMe
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Auth from 'sales-app/modules/Auth'
import {
	NotFoundBlock,
	ErrorSegment,
} from 'sales-app/utils/sharedComponents/Error'
import DibizOrgs from 'sales-app/modules/DibizOrgs'
import { ToastHandler } from 'sales-app/utils/sharedComponents/ToastHandler'
import Settings from 'sales-app/modules/Settings'
import Admin from 'sales-app/modules/Admin'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { AppDuc } from 'sales-app/modules/App/duc'
import ErrorBoundary from 'sales-app/utils/sharedComponents/ErrorBoundary'
import { LoaderFixedWrapper } from 'ui-lib/components/Spinner'
import { InfiniteSpinner } from 'ui-lib/components/Spinner/InfiniteSpinner'
import { NewErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Modal } from 'ui-lib/components/Modal'
import { P } from 'ui-lib/components/Typography'
import { Header } from 'sales-app/modules/App/containers/Header'

const ComponentsMap = {
	Auth,
	Settings,
	Dibizorgs: DibizOrgs,
	Admin,
}

const Wrapper = styled.div(p => ({
	position: 'relative',
	width: 'auto',
	minHeight: '100vh',
	backgroundColor: p.theme.color.grey1,
	margin: '0 auto',
}))

const Container = styled.div(p => ({
	width: '100%',
	margin: '0 auto',
	...(!p.isOnline && {
		paddingTop: 8,
		'-webkit-filter': 'grayscale(1)' /* Older Webkit */,
		'-moz-filter': 'grayscale(100%)',
		'-ms-filter': 'grayscale(100%)',
		'-o-filter': 'grayscale(100%)',
		filter: 'grayscale(100%)',
	}),
}))
const Loader = () => (
	<LoaderFixedWrapper fixed>
		<InfiniteSpinner size={30} />
	</LoaderFixedWrapper>
)

const App = () => {
	const dispatch = useDispatch()
	const page = useSelector(MainRouteDuc.selectors.page)

	const {
		isFetching: isProfileInfoFetching,
		isError: isProfileFetchErrored,
	} = useSelector(AuthDuc.selectors.getProfileFetchStatus)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const isOnline = useSelector(AppDuc.selectors.isOnline)
	const lastOnline = useSelector(AppDuc.selectors.lastOnline)
	const {
		show: showConfirmationModal,
		heading,
		message,
		isCloseable,
		confirmationLabel,
		declineLabel,
	} = useSelector(AppDuc.selectors.confirmationModal)

	const globalLoadingStatus = useSelector(AppDuc.selectors.loading)

	const CoreComponent = ComponentsMap[page] || NotFoundBlock

	useEffect(() => {
		// which runs in each minute
		window.setInterval(() => {
			const currentPage = window.location.pathname.split('/')[1]
			if (currentPage !== 'auth') {
				dispatch(AuthDuc.creators.initiateRefreshToken())
			}
		}, 60000)
	}, [dispatch])

	// if auth route, return back the full exclusive component
	if (page === 'Auth')
		return (
			<>
				<Suspense fallback={<Loader />}>
					<ToastHandler />
					<CoreComponent />
				</Suspense>
			</>
		)

	if (isProfileFetchErrored) {
		// unable to get the user profile info, so show error page.

		return <ErrorSegment type={401} />
	}

	const isLoading = globalLoadingStatus || isProfileInfoFetching

	// all other components will have a wrapper.
	return (
		<Wrapper>
			<ErrorBoundary>
				<Suspense fallback={<Loader />}>
					<>
						<ToastHandler />
						<Header
							loading={isLoading}
							isOnline={isOnline}
							lastOnline={lastOnline}
						/>

						{isOnline ? (
							<Container
								isOnline={isOnline}
								style={{
									maxWidth: isMobile
										? '100vw'
										: 'calc(100vw - 338px)',
									margin: isMobile
										? '0 auto'
										: '16px 0 0 310px',
									overflow: 'overflow',
									paddingBottom: '90px',
								}}
							>
								{isLoading && <Loader />}
								<div id="DashboardHeader">
									<CoreComponent />
								</div>
							</Container>
						) : (
							<>
								<ToastHandler />
								<Header
									loading={isLoading}
									isOnline={isOnline}
									lastOnline={lastOnline}
								/>
								{isOnline ? (
									<Container
										isOnline={isOnline}
										style={{
											maxWidth: isMobile
												? '100vw'
												: 'calc(100vw - 338px)',
											margin: isMobile
												? '0 auto'
												: '16px 0 0 310px',
											overflow: 'auto',
											paddingBottom: '90px',
										}}
									>
										{isLoading && <Loader />}
										<CoreComponent />
									</Container>
								) : (
									<div
										style={{
											width: '100%',
											maxWidth: isMobile
												? '100vw'
												: 'calc(100vw - 338px)',
											margin: isMobile
												? '0 auto'
												: '16px 0 0 310px',
											overflow: 'auto',
											paddingBottom: '90px',
										}}
									>
										<NewErrorBlock
											status="offline"
											title="DIBIZ seems to be offline."
											subtitle="Make sure that you're connected to the internet and try again"
										/>
									</div>
								)}
							</>
						)}
					</>
				</Suspense>
			</ErrorBoundary>
			<Modal
				closeable={isCloseable}
				show={showConfirmationModal}
				heading={heading}
				body={
					<P large bold>
						{message}
					</P>
				}
				closelabel={declineLabel}
				confirmlabel={confirmationLabel}
				onClose={() =>
					dispatch(AppDuc.creators.declinedOnConfirmationModal())
				}
				onConfirm={() =>
					dispatch(AppDuc.cretors.acceptedOnConfirmationModal())
				}
				isMobile={isMobile}
			/>
		</Wrapper>
	)
}
export default App
