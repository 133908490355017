import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { initialState } from './config'

export const SettingsDuc = new Duck({
	namespace: 'settings',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'CHANGE_PASSWORD',
		'FETCH_NOTIFICATIONS',
		'SET_NOTIFICATIONS',
		'NOTIFICATIONS_FLUSH_STATE',
		'APPEND_NOTIFICATIONS',
		'SET_NOTIFICATION_PAGINATION_ENTRIES',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}
			case duck.types.SET_PASSWORD_IN_STORE: {
				return setIn(state, ['password'], action.password)
			}
			case duck.types.CLEAR_PASSWORD_FROM_STORE: {
				return setIn(state, ['password'], initialState.password)
			}
			case duck.types.SET_NOTIFICATIONS: {
				const { notifications } = action
				const currentNotifications = getIn(state, ['notifications'])
				const dups = {}
				const finalList = currentNotifications
					.concat(notifications || [])
					.filter(notification => {
						if (!dups[notification.id]) {
							dups[notification.id] = true

							return notification
						}

						return null
					})

				return setIn(state, ['notifications'], finalList)
			}
			case duck.types.NOTIFICATIONS_FLUSH_STATE: {
				return setIn(
					state,
					['notifications'],
					initialState.notifications
				)
			}
			case duck.types.SET_NOTIFICATION_PAGINATION_ENTRIES: {
				const { limit, nextCursor, total } = action
				const root = ['pagination']
				let nextState = state

				if (total)
					nextState = setIn(nextState, [...root, 'total'], total)

				if (limit)
					nextState = setIn(nextState, [...root, 'limit'], limit)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[...root, 'nextCursor'],
						nextCursor
					)

				return nextState
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['settings', 'activeModule']) || 'Error',
		getPasswordFromStore: state =>
			getIn(state, ['settings', 'password']) || {},
		getNotifications: state =>
			getIn(state, ['settings', 'notifications']) || {},
		getPaginationConfig: state =>
			getIn(state, ['settings', 'pagination']) || {},
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),

		changePassword: (values, toastMessage) => ({
			type: duck.types.CHANGE_PASSWORD,
			values,
			toastMessage,
		}),
		fetchNotifications: locationState => ({
			type: duck.types.FETCH_NOTIFICATIONS,
			locationState,
		}),
		flushNotifications: () => ({
			type: duck.types.NOTIFICATIONS_FLUSH_STATE,
		}),
		setNotifications: notifications => ({
			type: duck.types.SET_NOTIFICATIONS,
			notifications,
		}),
		appendNotifications: locationState => ({
			type: duck.types.APPEND_NOTIFICATIONS,
			locationState,
		}),
		setNotificationPaginationEntries: (total, limit, nextCursor) => ({
			type: duck.types.SET_NOTIFICATION_PAGINATION_ENTRIES,
			total,
			limit,
			nextCursor,
		}),
	}),
})
