/* @flow */
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Flatpickr from 'react-flatpickr'
import { FieldText } from '../FieldText'
// $FlowFixMe
import CalendarIcon from '../../icons/calendar.svg'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import { Label } from '../Label'
import { Box } from '../../utils/Box'
import 'flatpickr/dist/themes/airbnb.css'

const DatePickerInput = styled(Flatpickr)(p => ({
	width: 'inherit',
	boxSizing: 'border-box',
	padding: 10,
	background: 'transparent',
	fontWeight: 400,
	margin: 0,
	border: 'none',
	color: p.theme.color.grey8,
}))

const Wrapper = styled.div(p => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	background: p.theme.color.white,
	border: `1px solid ${
		p.error ? p.theme.color.error : p.theme.color.primary
	}`,
	borderRadius: 4,
	...(p.noBorder && {
		border: 'none',
		borderRadius: 'initial',
		borderBottom: `1px solid ${
			p.error ? p.theme.color.error : p.theme.color.primary
		}`,
	}),
	width: '100%',
}))

type Props = {
	name: string,
	label?: string,
	range?: boolean,
	hideError?: boolean,
	error?: string,
	value: Date,
	placeholder: string,
	noBorder?: boolean,
	onChange: ({ [string]: Date } | Date | Array<Date>) => void,
	required?: boolean,
	hideLabel?: boolean,
	minDate?: Date | null,
	maxDate?: Date | null,
	enableTime: boolean,
	returnKeyValue?: boolean,
	toDate?: boolean,
}

const DatePicker = ({
	name = '',
	label = '',
	hideError,
	error,
	placeholder,
	noBorder,
	onChange,
	required,
	hideLabel,
	minDate,
	maxDate,
	enableTime,
	value,
	returnKeyValue,
	toDate,
	enableCurrentTime = false,
	disabled = false,
}: Props) => {
	const [_date, setDate] = useState(value)

	useEffect(() => {
		setDate(value)
	}, [value])

	const handleChange = useCallback(
		__date => {
			const date = Array.isArray(__date) ? __date[0] : __date
			if (toDate) {
				const now = new Date()
				date.setHours(23, 59, 59, 999)

				if (new Date(date) >= now) {
					date.setHours(now.getHours(), now.getMinutes(), 0)
				}
			}
			if (!date) return null
			setDate(date)
			const ref = name || label || '---'
			onChange(
				returnKeyValue
					? { [ref]: date.toISOString() }
					: date.toISOString()
			)
		},
		[onChange, label, name, returnKeyValue, toDate]
	)

	const options = useMemo(() => {
		const _options = {
			dateFormat: enableTime ? 'd/m/Y h:i K' : 'd/m/Y',
			minDate: null,
			maxDate: null,
			enableTime,
		}
		if (minDate) {
			// $FlowFixMe
			_options.minDate = minDate
		}

		if (maxDate) {
			// $FlowFixMe
			_options.maxDate = maxDate
		}

		return _options
	}, [minDate, maxDate, enableTime])

	const _id = `date-picker-${name}-id`

	return (
		<>
			{!hideLabel && label && (
				<Label
					for={_id}
					required={required}
					htmlFor={_id}
					style={{ paddingBottom: 5 }}
				>
					{label}
				</Label>
			)}
			<Wrapper error={error} noBorder={noBorder}>
				<DatePickerInput
					data-enable-time={enableCurrentTime}
					id={_id}
					placeholder={placeholder}
					options={options}
					value={_date}
					onChange={handleChange}
					disabled={disabled}
					style={{ cursor: disabled ? 'default' : 'pointer' }}
				/>
				<Box center padding="0 5px">
					<IconWrapper
						size={20}
						as="label"
						htmlFor={_id}
						style={{ cursor: disabled ? 'default' : 'pointer' }}
					>
						<Icon glyph={CalendarIcon} />
					</IconWrapper>
				</Box>
			</Wrapper>
			{!hideError && (
				<FieldText error show={!!error}>
					{error}
				</FieldText>
			)}
		</>
	)
}

DatePicker.defaultProps = {
	onChange: () => {},
}

const DateRangepicker = ({
	name,
	label = '',
	hideError,
	error,
	placeholder,
	noBorder,
	onChange,
	required,
	hideLabel,
	minDate,
	maxDate,
	enableTime,
	returnKeyValue,
}: Props) => {
	const [_date, setDate] = useState(null)

	const handleChange = __date => {
		setDate(__date)
		const ref = name || label || '---'
		onChange(
			returnKeyValue
				? { [ref]: __date.toISOString() }
				: __date.toISOString()
		)
	}

	const options = useMemo(() => {
		const _options = {
			dateFormat: 'd/m/Y',
			mode: 'range',
			minDate: null,
			maxDate: null,
			enableTime,
		}
		if (minDate) {
			// $FlowFixMe
			_options.minDate = minDate
		}

		if (maxDate) {
			// $FlowFixMe
			_options.maxDate = maxDate
		}

		return _options
	}, [minDate, maxDate, enableTime])

	const _id = `date-picker-${name}-id`

	return (
		<>
			{!hideLabel && label && (
				<Label
					for={_id}
					required={required}
					htmlFor={_id}
					style={{ paddingBottom: 5 }}
				>
					{label}
				</Label>
			)}
			<Wrapper error={error} noBorder={noBorder}>
				<DatePickerInput
					id={_id}
					placeholder={placeholder}
					options={options}
					value={_date}
					onChange={handleChange}
				/>
				<Box center padding="0 5px">
					<IconWrapper as="label" htmlFor={_id}>
						<Icon glyph={CalendarIcon} />
					</IconWrapper>
				</Box>
			</Wrapper>
			{!hideError && (
				<FieldText error show={!!error}>
					{error}
				</FieldText>
			)}
		</>
	)
}

DateRangepicker.defaultProps = {
	onChange: () => {},
}

export { DatePicker, DateRangepicker }
