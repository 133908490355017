import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { CoreMenu, MenuItem } from 'ui-lib/components/CoreMenu'
import { useTranslation } from 'react-i18next'
import { addLast, getIn } from 'timm'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { Box } from 'ui-lib/utils/Box'
import { Modal } from 'ui-lib/components/Modal'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { P } from 'ui-lib/components/Typography'
import { useSelector, useDispatch } from 'react-redux'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { AppDuc } from 'sales-app/modules/App/duc'
import { useDateByFormat } from 'sales-app/utils/date'
import DibizIcon from 'ui-lib/icons/dibiz-full-logo-transparent.svg'
import DibizIconMobile from 'ui-lib/icons/dibiz-logo.svg'
import SettingsIcon from 'ui-lib/icons/settings.svg'
import {
	navigationBaseReference,
	featureAccessBasedOnType,
} from 'sales-app/routes/base'
import {
	getAllowed,
	isAtleastOneRuleActive,
} from 'sales-app/modules/Auth/helpers'
import { getPathBasedOnAction } from 'sales-app/routes/options'
import { Button } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import {
	NotificationBadge,
	NotificationItem,
	ListItem,
	UnOrderedList,
	UserProfile,
	OfflineToast,
	HeaderRightBlock,
	LogoSearch,
	HeaderProfileText,
} from 'sales-app/utils/sharedComponents/TopNavbarFragments'

export const HEADER_HEIGHT = 64

export const Wrapper = styled.div(p => ({
	height: HEADER_HEIGHT,
	zIndex: p.theme.zIndex.level5,
	backgroundColor: p.theme.color.white1,
	borderBottom: `1px solid ${p.theme.color.grey4}`,
	position: 'fixed',
	top: 0,
	width: '100vw',
}))
const handleRouteChange = (dispatch, type, payload) => {
	dispatch(MainRouteDuc.creators.switchPage(type, payload))
}
let lastSelectedNode = {
	path: '',
	status: false,
}
export const getMenuList = (
	navigationReference,
	pathName,
	dispatch,
	index = 0,
	parent = '---',
	_handleRouteChange,
	{ allowed, denied },
	isMobile,
	t
) => {
	// if you have some rules to filter out the pass the rules
	return navigationReference.reduce((agg, conf, _index) => {
		let aggregator = agg
		// check if the conf can make it to the list
		const features =
			conf.featureAccess || featureAccessBasedOnType[conf.action]

		const isModuleAllowed = isAtleastOneRuleActive(
			getAllowed(features, allowed, denied)
		)
		if (isModuleAllowed) {
			const path = getPathBasedOnAction(
				conf.action,
				conf.payload,
				conf.query
			)
			const arr = path.split('/')
			const _arr = pathName.split('/')

			const __path = `/${getIn(arr, [1])}`
			const ___path = `/${getIn(_arr, [1])}`

			const isActive = pathName === path
			const isChildOpen = __path === ___path

			aggregator = addLast(
				aggregator,
				<MenuItem
					key={`${conf.title}-${index}-${parent}`}
					isFirst={_index === 0}
					index={index}
					icon={conf.icon}
					id={`${conf.title}`}
					title={t(conf.title)}
					onClick={({ toggleMenu }) => {
						if (isMobile) {
							toggleMenu(false)
						}
						if (conf.subRoutes) {
							lastSelectedNode = {
								path,
								status: !lastSelectedNode.status,
							}
						}

						_handleRouteChange(dispatch, conf.action, conf.payload)
					}}
					isActive={isActive && lastSelectedNode.status}
					path={path}
					hasChildren={conf.subRoutes}
				/>
			)

			// if there are children, recursively get them too
			if (conf.subRoutes && isChildOpen && lastSelectedNode.status) {
				aggregator = addLast(
					aggregator,
					getMenuList(
						conf.subRoutes,
						pathName,
						dispatch,
						index + 1,
						conf.title,
						_handleRouteChange,
						{ allowed, denied },
						isMobile,
						t
					)
				)
			}
		}

		return aggregator
	}, [])
}
const useOutsideClick = (ref, callback) => {
	const handleClick = e => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback()
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClick)

		return () => {
			document.removeEventListener('click', handleClick)
		}
	})
}

const useOutsideNotificationClick = (ref, callback) => {
	const handleClick = e => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback()
		}
	}

	useEffect(() => {
		document.addEventListener('click', handleClick)

		return () => {
			document.removeEventListener('click', handleClick)
		}
	})
}
const getInitials = (name, delimeter) => {
	if (name) {
		const arr = name.split(delimeter)

		switch (arr.length) {
			case 1:
				return `${arr[0].charAt(0).toUpperCase()}${arr[0]
					.charAt(1)
					.toUpperCase()} `
			default:
				return (
					arr[0].charAt(0).toUpperCase() +
					arr[arr.length - 1].charAt(0).toUpperCase()
				)
		}
	}

	return ''
}

const Header = ({ isLoading, isOnline, lastOnline }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const ref = useRef()
	const notifiyRef = useRef()
	const { pathname } = useSelector(AuthDuc.selectors.location)
	const { isMobile, isDesktop, isTablet } = useSelector(
		AppDuc.selectors.detection
	)
	const { allowed = [], denied = [] } = useSelector(
		AuthDuc.selectors.getCurrentUserRoles
	)
	const MenuItems = getMenuList(
		navigationBaseReference,
		pathname,
		dispatch,
		0,
		undefined,
		handleRouteChange,
		{ allowed, denied },
		isMobile,
		t
	)
	const [showModal, setShowModal] = useState(false)
	const lastSeenDate = useDateByFormat(lastOnline, 'd LLL yyyy hh:mm a')
	const [notificationFetched, updateNotificationFetched] = useState(false)

	const notifications = useSelector(AuthDuc.selectors.getNotifications) || {}

	const totalNotifications = notifications.total || 0
	const notificationList = notifications.list || []
	const [background, setbackground] = useState('')

	const [notificationsHeight, setNotificationsHeight] = useState('0px')

	const [profileMenuHeight, setProfileMenuHeight] = useState('0px')

	useOutsideClick(ref, () => {
		if (profileMenuHeight) setProfileMenuHeight('0px')
	})

	useOutsideNotificationClick(notifiyRef, () => {
		if (notificationsHeight) {
			setNotificationsHeight('0px')
			setbackground('')
		}
	})

	useEffect(() => {
		if (notificationFetched) {
			return
		}
		updateNotificationFetched(true)

		dispatch(AuthDuc.creators.fetchNotifications())
		window.setInterval(() => {
			dispatch(AuthDuc.creators.fetchNotifications())
		}, 180000)
	}, [notificationFetched, dispatch])
	const handleNotificationClick = notification => {
		const { meta, id } = notification
		const { component, componentRefID } = meta
		const entity = component.split('/')[1]

		if (component.includes('organizations/partners')) {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.PARTNER$WREQREFERENCE,
					{
						action: 'view',
						partnerRef: componentRefID,
					},
					{
						docType: 'notifications',
					}
				)
			)
		} else {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
					{
						rootModule: entity,
						documentReference: componentRefID,
					}
				)
			)
		}
		setNotificationsHeight('0px')
		dispatch(AuthDuc.creators.singleNotificationUpdateStatus(id, 'seen'))
	}

	const notificationComponent = (
		<>
			<Box
				style={{
					position: 'relative',
					marginRight: '16px',
					display: 'inline-block',
					verticalAlign: 'middle',
					paddingTop: 10,
					background,
					borderRadius: '4px',
					cursor: 'pointer',
				}}
				width={50}
				onClick={() => {
					if (notificationsHeight === '0px') {
						if (totalNotifications <= 6) {
							setNotificationsHeight(
								(totalNotifications + 1) * 66
							)
							setbackground('#F0F0F1')
						} else {
							setNotificationsHeight('390px')
							setbackground('#F0F0F1')
						}
					} else {
						setNotificationsHeight('0px')
						setbackground('')
					}
				}}
				ref={notifiyRef}
				title={
					totalNotifications === 0 ? t('topBar.noNotifications') : ''
				}
			>
				<NotificationBadge size={30} count={totalNotifications} />
			</Box>

			<UnOrderedList
				height={notificationsHeight}
				top="64px"
				right="13px"
				width={isMobile ? '95vw' : '558px'}
				style={{ overflowY: 'auto' }}
			>
				{notificationList.map(data => {
					return (
						<NotificationItem
							padding="16px 32px 16px 16px"
							key={data.id}
							onClick={handleNotificationClick}
							data={data}
						/>
					)
				})}
				<ListItem
					key="notifications-showall"
					style={{
						fontSize: theme.fontSize.s,
						lineHeight: '8px',
						color: '#172B4D',
						textAlign: 'center',
						background: '#fff',
						textTransform: 'uppercase',
					}}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.SETTINGS$SUBROOT,
								{
									rootModule: 'notifications',
								}
							)
						)
						setNotificationsHeight('0px')
					}}
				>
					{totalNotifications === 0
						? t('topBar.noNotifications')
						: t('topBar.showAll')}
				</ListItem>
			</UnOrderedList>
		</>
	)

	const { fullName, orgRole } = useSelector(AuthDuc.selectors.getUserProfile)
	const currentOrg = useSelector(AuthDuc.selectors.getCurrentOrganization)
	const orgImage = getIn(currentOrg, ['meta', 'logo'])
	const orgName = getIn(currentOrg, ['name'])

	return (
		<>
			<Modal
				closeable
				show={showModal}
				heading={t('topBar.logout')}
				body={
					<P large bold>
						{t('topBar.logoutMsg')}
					</P>
				}
				closelabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onClose={() => {
					setShowModal(false)
				}}
				onConfirm={() => {
					dispatch(AuthDuc.creators.signOutUser())
				}}
				isMobile={isMobile}
			/>
			<Spacer size={HEADER_HEIGHT} />
			<Wrapper>
				<CoreMenu
					topPosition={0}
					open={!isMobile}
					MenuItems={MenuItems}
					isLoading={isLoading}
					customMenuHeadingComponent={
						<Box
							style={{
								display: 'inline-block',
								width: '266px',
							}}
							padding={16}
							onClick={() => {
								if (profileMenuHeight === '0px') {
									setProfileMenuHeight('174px')
								} else {
									setProfileMenuHeight('0px')
								}
							}}
							ref={ref}
						>
							<Box
								row
								alignItems="flex-end"
								style={{
									padding: '0 8px',
								}}
							>
								<UserProfile bgUrl={orgImage}>
									{!orgImage && getInitials(fullName)}
								</UserProfile>
								<div
									style={{
										display: 'inline-block',
										verticalAlign: 'middle',
									}}
								>
									<HeaderProfileText
										fontSize={theme.fontSize.s}
										title={fullName}
									>
										{fullName}
									</HeaderProfileText>
									<Spacer size={4} />

									<HeaderProfileText
										title={orgRole}
										fontSize={theme.fontSize.s}
									>
										{orgRole}
									</HeaderProfileText>
								</div>
							</Box>
							<Box
								style={{
									padding: '0 8px ',
								}}
							>
								<Spacer size={20} />
								<HeaderProfileText
									title={orgName}
									fontSize={theme.fontSize.s}
									fullMaxWidth
								>
									{orgName}
								</HeaderProfileText>
							</Box>

							<Spacer size={20} />
							<Box
								style={{
									border: '1px solid #9398B1',
									borderRadius: '4px',
								}}
							>
								<Button
									extendStyles={{
										width: '100%',
										borderRadius: '4px',
										fontSize: '16px',
										background: '#2A2364',
										padding: '6px',
										textAlign: 'center',
										textDecoration: 'solid',
									}}
									onClick={() => setShowModal(true)}
									label={t('topBar.logout')}
									primary
									plain
								/>
							</Box>
						</Box>
						// )
					}
				/>

				<Box
					row
					center
					style={{
						height: 'inherit',
						paddingLeft: isMobile ? 50 : 309,
					}}
					justifyContent="space-between"
				>
					{(isDesktop || isTablet) && (
						<LogoSearch>
							<IconWrapper size={100}>
								<Icon glyph={DibizIcon} />
							</IconWrapper>
						</LogoSearch>
					)}
					{isMobile && (
						<LogoSearch>
							<IconWrapper
								size={50}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.DIBIZORGS,
											{
												action: 'newRequest',
											}
										)
									)
								}
							>
								<Icon glyph={DibizIconMobile} />
							</IconWrapper>
						</LogoSearch>
					)}
					<HeaderRightBlock>
						<div id="SettingsHeader">
							<IconWrapper
								size={30}
								onClick={() =>
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types.SETTINGS
										)
									)
								}
								title={t('topBar.settings')}
							>
								<Icon glyph={SettingsIcon} color="#172B4D" />
							</IconWrapper>
						</div>

						<Spacer size={45} horizontal />
						<div id="NotificationsHeader">
							{notificationComponent}
						</div>
					</HeaderRightBlock>
				</Box>
				{!isOnline && (
					<OfflineToast>
						<div>{t('offline.notification')}</div>
						<div>
							{t('offline.lastOnline')}: {lastSeenDate}
						</div>
					</OfflineToast>
				)}
				<div height={45}>
					<Button label="logout" onClick={() => setShowModal(true)} />
				</div>
			</Wrapper>
		</>
	)
}

export { Header }
