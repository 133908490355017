import React from 'react'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { useSelector } from 'react-redux'
import { AdminDuc } from 'sales-app/modules/Admin/duc'
import { SmallText, P } from 'ui-lib/components/Typography'
import { Card } from 'ui-lib/components/Card'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import theme from 'ui-lib/utils/base-theme'
import { FileLists } from 'ui-lib/components/FileUpload'
import { KeyValueSegment } from 'sales-app/modules/Admin/components/KeyValueSegment'
import { AppDuc } from 'sales-app/modules/App/duc'
import { colorCode, textColorCode } from 'sales-app/modules/Admin/config'

const BusinessLabel = {
	license: 'viewCompanyInfo.licenseNum',
	tax: 'viewCompanyInfo.companyRegNum',
	other: 'viewCompanyInfo.other',
}

const BusinessBlock = ({ isMobile, t, certDetails }) => {
	return (
		<Box row={!isMobile} padding="20px">
			<Card
				style={{
					padding: '20px',
					position: 'relative',
					overflow: 'visible',
					boxShadow: ' 0px 6px 16px #00000014',
				}}
				textAlign="left"
			>
				<SmallText
					style={{
						position: 'absolute',
						fontSize: theme.fontSize.m,
						color: theme.color.accent2,
						top: '-10px',
						left: '20px',
						display: 'inline-block',
						backgroundColor: theme.color.white,
						padding: '0 8px',
					}}
				>
					{t(BusinessLabel[getIn(certDetails, ['certType'])])}
				</SmallText>
				<Box row style={{ width: '100%' }}>
					<Box style={{ width: '50%' }}>
						<KeyValueSegment
							labelWidth="50%"
							label={t('viewCompanyInfo.registrtationNumber')}
							value={getIn(certDetails, ['certNum'])}
						/>
					</Box>
					<Box style={{ width: '50%' }}>
						<FileLists
							documents={certDetails.files || []}
							isReadOnly
						/>
					</Box>
				</Box>
			</Card>
		</Box>
	)
}

const Business = ({ business, t }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const orgStatus = useSelector(AdminDuc.selectors.getActiveStatus)

	return (
		<>
			<Box row justifyContent="flex-end" padding="20px 20px 0px 0px">
				<Box
					style={{
						width: '118px',
						height: '36px',
						background: `${colorCode[orgStatus]} 0% 0% no-repeat padding-box`,
						borderRadius: '5px',
						opacity: '1',
						justifyContent: 'center',
					}}
				>
					<P
						style={{
							height: '19px',
							textAlign: 'center',
							font: 'normal normal medium 19px Roboto',
							letterSpacing: '0px',
							color: textColorCode[orgStatus],
							opacity: '1',
						}}
					>
						{orgStatus === 'verified'
							? 'APPROVED'
							: orgStatus.toUpperCase()}
					</P>
				</Box>
			</Box>

			{business.length > 0 ? (
				business.map(certificates => {
					const _certificates = {
						id: getIn(certificates, ['id']) || '',
						certNum: getIn(certificates, ['number']) || '',
						certType: getIn(certificates, ['type']) || '',
						files: getIn(certificates, ['files']),
					}

					return (
						<BusinessBlock
							isMobile={isMobile}
							t={t}
							key={certificates.id}
							certDetails={_certificates}
						/>
					)
				})
			) : (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</>
	)
}

export default Business
