import React, { useState } from 'react'
import styled from 'styled-components'
import { CustomInput } from 'sales-app/utils/sharedComponents/CustomInput'
import {
	ButtonIconWrapper,
	Button,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { Text } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { useTranslation } from 'react-i18next'
import checkboxCircle from 'ui-lib/icons/circle-checkmark.svg'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { string as yupString } from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { AppDuc } from 'sales-app/modules/App/duc'
import { Spacer } from 'ui-lib/utils/Spacer'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import theme from 'ui-lib/utils/base-theme'
import { useFormik } from 'formik'

const Wrapper = styled.div(p => ({
	width: p.isMobile ? '100%' : '80%',
	height: '540px',
	maxWidth: '500px',
	background: p.theme.color.white,
	position: 'relative',
	overflow: 'initial',
	display: 'flex',
	justifyContent: 'center',
	alignSelf: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	zIndex: p.theme.zIndex.level1,
	padding: p.isMobile ? '4%' : '20px 32px',
}))

const Note = styled.div(p => ({
	...p.theme.fonts.medium,
	paddingBottom: '24px',
}))

const ForgotPassword = () => {
	const [email, setEmail] = useState('')
	const [error, setError] = useState('')
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const { loading, status } = useSelector(
		AuthDuc.selectors.getForgotPasswordState
	)

	const { t } = useTranslation()

	const dispatch = useDispatch()
	const isSuccess = status === 'success'

	const emailSchema = yupString()
		.required(t('validation.emailRequired'))
		.email(t('validation.emailFormat'))

	const onSubmit = () => {
		emailSchema
			.validate(email)
			.then(_email => {
				// success. Make the api call.
				dispatch(AuthDuc.creators.initiateForgotPassword(_email))
			})
			.catch(e => setError(e.message))
	}

	const onChange = value => {
		setError('')
		setEmail(value)
	}
	function emailIsValid(_email) {
		return /\S+@\S+\.\S+/.test(_email)
	}

	const { handleBlur, touched } = useFormik({
		initialValues: {
			email: '',
		},
		emailSchema,
		onSubmit: () => {
			emailSchema
				.validate(email)
				.then(_email => {
					// success. Make the api call.
					dispatch(AuthDuc.creators.initiateForgotPassword(_email))
				})
				.catch(e => setError(e.message))
		},
	})

	return (
		<Box
			style={{ width: '100%', overflowY: 'auto', height: '100%' }}
			alignItems={!isMobile && 'flex-end'}
		>
			<Wrapper isMobile={isMobile}>
				<Box width="100%">
					{!isSuccess && (
						<Box>
							<Spacer size={30} />
							{!isMobile && (
								<Box
									row
									justifyContent="flex-start"
									style={{
										paddingBottom: '30px',
										width: '100%',
									}}
								>
									<ButtonWithNoBorder
										extendStyles={{
											fontWeight: 'bold',
											fontSize: theme.fontSize.xl,
											textDecoration: 'none',
											color: theme.color.blue5,
											padding: '0px',
										}}
										label={t('common.back')}
										customIcon={
											<ButtonIconWrapper lightBG>
												<Icon glyph={LeftArrowIcon} />
											</ButtonIconWrapper>
										}
										onClick={() => {
											dispatch(
												MainRouteDuc.creators.redirect(
													MainRouteDuc.types.AUTH,
													{
														action: 'sign-in',
													}
												)
											)
										}}
									/>
								</Box>
							)}
							<Spacer size={30} />
							<Text
								style={{
									padding: '20px 0px',
									color: theme.color.accent2,
									fontSize: '16px',
									lineHeight: '1.5em',
								}}
							>
								{t('login.resetYourPassword')}
							</Text>
							<Spacer size={20} />
							<Box
								style={{
									width: '100%',
									maxHeight: '540px',
									maxWidth: '400px',
								}}
							>
								<CustomInput
									value={email}
									handleChange={e => onChange(e.target.value)}
									handleBlur={handleBlur}
									touched={touched.email}
									errors={error}
									label={t('login.email')}
									name="email"
									type="text"
									placeholder={t('login.enterYourEmail')}
								/>

								<Spacer size={0} />
								<Button
									isLoading={loading}
									primary
									disabled={!emailIsValid(email)}
									type="submit"
									label={t('common.submit')}
									onClick={onSubmit}
									style={{
										paddingBottom: '50px',
										width: '100%',
									}}
								/>
							</Box>
						</Box>
					)}
					<Spacer size={10} />
					{isSuccess && (
						<Box center>
							<IconWrapper size={50} style={{ marginBottom: 30 }}>
								<Icon glyph={checkboxCircle} />
							</IconWrapper>
							<Note>{t('login.sentMail')}</Note>
							<Note>{t('login.spamFolder')}</Note>

							<Box width={300}>
								<Button
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.redirect(
												MainRouteDuc.types.AUTH,
												{ action: 'sign-in' }
											)
										)
									}
									primary
									label={t('login.done')}
								/>
							</Box>
						</Box>
					)}
				</Box>
			</Wrapper>
		</Box>
	)
}

export default ForgotPassword
