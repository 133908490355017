import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'sales-app/modules/App/duc'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { Card } from 'ui-lib/components/Card'
import { H3, Text, P } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import theme from 'ui-lib/utils/base-theme'
import { getIn } from 'timm'
import { colorCode, textColorCode } from 'sales-app/modules/Admin/config'
import { DibizOrgsDuc } from '../duc'

export const certKeys1 = ['certName', 'certBody', 'certNum', 'scModel']
export const certKeys2 = ['issueDate', 'expiryDate', 'certStartDate', 'address']

export const ORG_ALIASES_KEYS = {
	certName: 'Certification Name',
	certNum: 'Certification Number',
	issueDate: 'Issue Date',
	expiryDate: 'Expiry Date',
	certStartDate: 'Certification Start Date',
	certBody: 'Certification Body',
	scModel: 'Supply Chain Model',
	address: 'Address',
}

const KeyValueSegment = ({
	label,
	value,
	labelWidth,
	valueWidth = '340px',
	noMaxWidth = false,
	pointer = false,
}) => {
	return (
		<Box
			row
			padding="4px 12px"
			justifyContent="flex-start"
			alignItems="baseline"
			style={{
				overflow: 'hidden', // Hide content that overflows
				flexWrap: 'nowrap', // Set initial behavior to not wrap
			}}
		>
			<Box
				style={{
					width: labelWidth || '25%',
					overflow: 'hidden',
					flex: '1 1 0', // Allow flexibility, but don't shrink
					minWidth: 0, // Allow text overflow within flex container
				}}
			>
				<H3
					style={{
						lineHeight: '1.5',
						fontSize: theme.fontSize.m,
						width: '100%',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						color: theme.color.accent2,
					}}
					title={label}
				>
					{label}
				</H3>
			</Box>
			:
			<Box
				padding={4}
				style={{
					maxWidth: noMaxWidth ? 'none' : valueWidth,
					wordWrap: 'break-word', // Allow line breaks
					whiteSpace: 'normal', // Allow content to wrap
					flex: '1 1 0', // Allow flexibility, but don't shrink
					minWidth: 0, // Allow text overflow within flex container
				}}
			>
				<Text
					title={value}
					style={{
						lineHeight: '1.5',
						width: '100%',
						color: theme.color.accent2,
						cursor: pointer && 'pointer',
						fontSize: theme.fontSize.m,
					}}
				>
					{value}
				</Text>
			</Box>
		</Box>
	)
}

const CertificationBlock = ({
	isMobile,
	t,
	certDetails,
	dispatch,
	userName,
	orgID,
	orgList,
}) => {
	const organisation = orgList.filter(org => org.id === orgID)
	const orgName = getIn(organisation, [0, 'name'])

	const orgStatus = getIn(organisation, [0, 'status', 'state'])
	const line1 = getIn(organisation, [0, 'primaryAddress', 'line1'])
	const city = getIn(organisation, [0, 'primaryAddress', 'city'])
	const state = getIn(organisation, [0, 'primaryAddress', 'state'])
	const country = getIn(organisation, [0, 'primaryAddress', 'country'])
	const postalCode = getIn(organisation, [0, 'primaryAddress', 'postalCode'])

	const addressParts = [line1, postalCode, city, state, country].filter(
		Boolean
	)
	const address = addressParts.join(', ')

	return (
		<Box row={!isMobile} margin="12px 0px">
			<Card
				style={{
					padding: '10px',
					position: 'relative',
					overflow: 'visible',
					boxShadow: ' 0px 6px 16px #00000014',
				}}
				textAlign="left"
			>
				<Box row alignItems="baseline" justifyContent="space-between">
					<Box row alignItems="baseline">
						<span
							style={{
								fontWeight: 'bold',
								paddingLeft: 12,
								marginRight: 12,
							}}
						>
							{orgName}
						</span>

						<Box
							style={{
								width: '118px',
								height: '36px',
								background: `${colorCode[orgStatus]} 0% 0% no-repeat padding-box`,
								borderRadius: '5px',
								opacity: '1',
								justifyContent: 'center',
							}}
						>
							<P
								style={{
									height: '19px',
									textAlign: 'center',
									font: 'normal normal medium 19px Roboto',
									letterSpacing: '0px',
									color: textColorCode[orgStatus],
									opacity: '1',
								}}
							>
								{orgStatus === 'verified'
									? 'APPROVED'
									: orgStatus.toUpperCase()}
							</P>
						</Box>
					</Box>
					<Box row justifyContent="flex-end">
						<ButtonWithNoBorder
							label="View Certificate"
							action
							onClick={() => {
								window.open(certDetails.file, '_blank')
							}}
						/>
						<ButtonWithNoBorder
							label={t('common.approve')}
							action
							onClick={() => {
								dispatch(
									DibizOrgsDuc.creators.approveCertificate({
										docID: certDetails?.id,
										orgID: certDetails?.orgID,
										userName,
									})
								)
							}}
						/>
					</Box>
				</Box>
				<Box row style={{ width: '100%' }}>
					<Box style={{ width: '55%' }}>
						{certKeys1.map(key => {
							return (
								<KeyValueSegment
									key={key}
									labelWidth="50%"
									label={t(ORG_ALIASES_KEYS[key])}
									value={certDetails[key]}
								/>
							)
						})}
					</Box>
					<Box style={{ width: '45%' }}>
						{certKeys2.map(key => {
							return (
								<KeyValueSegment
									key={key}
									labelWidth="50%"
									label={t(ORG_ALIASES_KEYS[key])}
									value={
										key === 'address'
											? `${address}`
											: certDetails[key]
									}
								/>
							)
						})}
					</Box>
				</Box>
			</Card>
		</Box>
	)
}

const CertificationManagement = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { fullName } = useSelector(AuthDuc.selectors.getUserProfile)

	const {
		list: certificates = [],
		orgList,
		total,
		nextStartID,
	} = useSelector(DibizOrgsDuc.selectors.getCertificates)

	const handleShowMore = useCallback(() => {
		dispatch(DibizOrgsDuc.creators.fetchCertificates(nextStartID))
	}, [dispatch, nextStartID])

	return (
		<>
			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title="Certification Verification" />
			</Box>
			{certificates.length > 0 ? (
				certificates.map(certificate => (
					<CertificationBlock
						isMobile={isMobile}
						key={certificate.certNum}
						t={t}
						certDetails={certificate}
						dispatch={dispatch}
						userName={fullName}
						orgID={certificate.orgID}
						orgList={orgList}
					/>
				))
			) : (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
			{certificates?.length > 0 && (
				<Box
					style={{
						width: '100%',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Button
						plain
						disabled={certificates.length === total}
						extendStyles={{
							background: '#F2F4FF',
							padding: '8px 24px',
							borderRadius: '24px',
							width: '170px',
							textAlign: 'center',
						}}
						label={t('common.showMore')}
						onClick={handleShowMore}
					/>
				</Box>
			)}
		</>
	)
}

export { CertificationManagement }
