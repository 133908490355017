import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'sales-app/utils/sharedComponents/Error'
import { AdminDuc } from './duc'
import { MainDashboard } from './containers/MainDashboard'

const modulesMap = {
	dashboard: MainDashboard,
}

const Admin = () => {
	const activeModule = useSelector(AdminDuc.selectors.activeModule)
	const Component = modulesMap[activeModule] || NotFoundBlock

	return <Component />
}

export default Admin
