import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'

import { initialState } from './config'

export const AdminDuc = new Duck({
	namespace: 'admin',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'SET_ACTIVE_STATUS',
		'SET_CERTIFICATE_DETAILS',
		'SET_BUSINESS_DETAILS',
		'SET_COMPANY_INFO',
		'UPDATE_ORGANISATION_DETAILS',
		'UPDATE_ORGANISATION_STATUS',
		'UPDATE_ORGANISATION_LOGO',
		'DELETE_SINGLE_CERTIFICATION',
		'UPDATE_SINGLE_CERTIFICATION',
		'ADD_NEW_CERTIFICATION',
		'UPDATE_FARM_DETAILS',
		'VIEW_COMPANY_DETAILS',
		'GET_COMPANY_INFO',
		'GET_CERTIFICATE_DETAILS',
		'GET_BUSINESS_DETAILS',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}
			case duck.types.SET_ACTIVE_STATUS: {
				return setIn(state, ['company', 'status'], action.status)
			}
			case duck.types.SET_COMPANY_INFO: {
				return setIn(state, ['companyInformation'], action.companyInfo)
			}

			case duck.types.SET_CERTIFICATE_DETAILS: {
				return setIn(state, ['certificateDetails'], action.details)
			}
			case duck.types.SET_BUSINESS_DETAILS: {
				return setIn(state, ['businessDetails'], action.details)
			}

			case duck.types.SET_COMPANY_DETAILS: {
				return setIn(
					state,
					['modules', 'company', 'companyDetails'],
					action.details
				)
			}
			case duck.types.UPDATE_ORGANISATION_STATUS: {
				return setIn(
					state,
					['modules', 'company', 'status'],
					action.status
				)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['admin', 'activeModule']) || 'Error',
		getActiveStatus: state =>
			getIn(state, ['admin', 'company', 'status']) || 'Error',
		getCompanyInfo: state =>
			getIn(state, ['admin', 'companyInformation']) || {},
		getCompanyName: state =>
			getIn(state, ['admin', 'companyInformation', 'name']) || '',
		getCertificateDetails: state =>
			getIn(state, ['admin', 'certificateDetails']) || [],
		getBusinessDetails: state =>
			getIn(state, ['admin', 'businessDetails']) || [],
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		setActiveStatus: status => ({
			type: duck.types.SET_ACTIVE_STATUS,
			status,
		}),
		setCompanyInfo: companyInfo => ({
			type: duck.types.SET_COMPANY_INFO,
			companyInfo,
		}),
		setCertificateDetails: details => ({
			type: duck.types.SET_CERTIFICATE_DETAILS,
			details,
		}),
		setBusinessDetails: details => ({
			type: duck.types.SET_BUSINESS_DETAILS,
			details,
		}),
		viewCompanyDetails: companyID => ({
			type: duck.types.VIEW_COMPANY_DETAILS,
			companyID,
		}),
		updateOrganisation: (values, orgID) => ({
			type: duck.types.UPDATE_ORGANISATION_DETAILS,
			values,
			orgID,
		}),
		updateOrganisationStatus: (status, orgID) => ({
			type: duck.types.UPDATE_ORGANISATION_STATUS,
			status,
			orgID,
		}),
		updateOrganisationLogo: (logoURL, orgID) => ({
			type: duck.types.UPDATE_ORGANISATION_LOGO,
			logoURL,
			orgID,
		}),
		deleteSingleCertificate: (certID, orgID) => ({
			type: duck.types.DELETE_SINGLE_CERTIFICATION,
			certID,
			orgID,
		}),
		updateSingleCertificate: (values, id, orgID) => ({
			type: duck.types.UPDATE_SINGLE_CERTIFICATION,
			values,
			id,
			orgID,
		}),
		addNewCertificate: (values, orgID) => ({
			type: duck.types.ADD_NEW_CERTIFICATION,
			values,
			orgID,
		}),
		updateFarmDetails: (values, orgID) => ({
			type: duck.types.UPDATE_FARM_DETAILS,
			values,
			orgID,
		}),
	}),
})
