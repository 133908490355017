import { AuthDuc } from 'core-app/modules/Auth/duc'

// eslint-disable-next-line no-unused-vars
export default async (dispatch, getState, { action }) => {
	const payload = action.payload || {}
	if (payload.action === 'forgot-password') {
		dispatch(AuthDuc.creators.flushForgotPasswordState())
	}
	if (payload.action === 'reset-password') {
		dispatch(AuthDuc.creators.flushResetPasswordState())
	}
}
