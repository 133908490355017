import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ErrorBlock, NewErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'

const ErrorSegment = ({ type = 401, message, buttonProps }) => {
	return (
		<ErrorBlock status={type} message={message} buttonProps={buttonProps} />
	)
}

const NotFoundBlock = () => {
	const { t } = useTranslation()

	return (
		<NewErrorBlock
			status={404}
			title={t('common.error404Title')}
			subtitle={t('common.error404Subtitle')}
		/>
	)
}

const NotVerifiedBlock = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	return (
		<>
			<NewErrorBlock
				status="not-verified"
				title={t('common.congratulation')}
				subtitle={t('common.congratulationNote')}
			/>
			<Box
				style={{
					backgroundColor: '#F8F8F8',
					paddingBottom: '20px',
				}}
				alignItems="center"
			>
				<Button
					label={t('topBar.logout')}
					primary
					extendStyles={{
						width: '10%',
						borderRadius: '4px',
						fontSize: '16px',
						background: '#2A2364',
						padding: '6px',
						textAlign: 'center',
						textDecoration: 'solid',
					}}
					plain
					onClick={() => dispatch(AuthDuc.creators.signOutUser())}
				/>
			</Box>
		</>
	)
}

const ComingSoonFeature = () => {
	const { t } = useTranslation()

	return (
		<>
			<NewErrorBlock
				status="coming-soon"
				subtitle={t('common.comingSoonFeature')}
			/>
		</>
	)
}

const UnexpectedErrorBlock = ({
	message,
	status = 500,
	onClick,
	shouldLogOut,
}) => {
	const dispatch = useDispatch()

	return (
		<ErrorBlock
			status={status}
			messageType="error"
			message={message}
			buttonProps={{
				label: shouldLogOut ? 'Retry' : 'Go Back',
				onClick: () => {
					if (onClick) onClick()
					else if (shouldLogOut)
						dispatch(
							AuthDuc.creators.signOutUser({
								redirectTo: window.location.href,
							})
						)
					else window.location.reload()
				},
			}}
		/>
	)
}

export {
	ErrorSegment,
	NotFoundBlock,
	UnexpectedErrorBlock,
	NotVerifiedBlock,
	ComingSoonFeature,
}
