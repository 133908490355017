export const initialState = {
	activeModule: '',
	loading: false,
	error: false,
	modules: {
		businessDetails: [],
		certificateDetails: [],
		company: { companyDetails: {}, status: '' },
		companyInformation: {},
	},
}
export const supplyChainModels = {
	'identity-preserved': 'viewCompanyInfo.identityPreserved',
	segregated: 'viewCompanyInfo.segregated',
	'mass-balance': 'viewCompanyInfo.massBalance',
	'book-and-claim': 'viewCompanyInfo.booknClaim',
}

export const orgKeys = [
	'name',
	'type',
	'address',
	'location',
	'mobile',
	'email',
]

export const farmDetailsKeys = [
	'area',
	'plantationArea',
	'plantationTime',
	'farmAddress',
]

export const FARMDETAILS_ALIASES_KEYS = {
	area: 'viewCompanyInfo.fieldSize',
	plantationArea: 'viewCompanyInfo.plantationArea',
	plantationTime: 'viewCompanyInfo.plantationTime',
	farmAddress: 'viewCompanyInfo.farmAddress',
}

export const ORG_ALIASES_KEYS = {
	name: 'viewCompanyInfo.name',
	type: 'viewCompanyInfo.type',
	address: 'viewCompanyInfo.address',
	location: 'viewCompanyInfo.location',
	mobile: 'viewCompanyInfo.mobile',
	email: 'viewCompanyInfo.email',
	certOrg: 'viewCompanyInfo.certOrg',
	certNum: 'viewCompanyInfo.certNum',
	issueDate: 'viewCompanyInfo.issueDate',
	expiryDate: 'viewCompanyInfo.expiryDate',
	certStartDate: 'viewCompanyInfo.certStartDate',
	certBody: 'viewCompanyInfo.certBody',
	license: 'viewCompanyInfo.license',
	scmodel: 'viewCompanyInfo.scModel',
}

export const certKeys1 = [
	'certOrg',
	'certNum',
	'issueDate',
	'expiryDate',
	'certStartDate',
	'certBody',
]

export const certKeys2 = ['scmodel']

export const colorCode = {
	pending: '#FFECC3',
	'in-review': '#C7E6E1',
	rejected: '#FBE8ED',
	unverified: '#D8C7E6',
	verified: '#E6F2EA',
}

export const textColorCode = {
	pending: '#DB8F43',
	'in-review': '#579589',
	rejected: '#E25458',
	unverified: '#9C5FD1',
	verified: '#0D8338',
}
