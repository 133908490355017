import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'sales-app/utils/sharedComponents/Error'
import MainDashboard from './containers/MainDashboard'
import { UpdateFFBPrices } from './containers/UpdateFFBPrices'
import { TilesetManagement } from './containers/TilesetManagement'
import { DibizOrgsDuc } from './duc'
import { CertificationManagement } from './containers/CertificationManagement'
import { SalesDashboard } from './containers/SalesDashboard'
import { GlobalProducts } from './containers/GlobalProducts'
import { NewsAndUpdates } from './containers/NewsAndUpdates'
import OrganisationGroups from './containers/OrganisationGroups'

const modulesMap = {
	newRequest: MainDashboard,
	approvedOrgs: MainDashboard,
	rejectedOrgs: MainDashboard,
	'update-ffb-price': UpdateFFBPrices,
	'certification-management': CertificationManagement,
	'global-products': GlobalProducts,
	dashboard: SalesDashboard,
	'tileset-management': TilesetManagement,
	'news-updates': NewsAndUpdates,
	'org-groups': OrganisationGroups,
}

const DibizOrgs = () => {
	const location = useSelector(DibizOrgsDuc.selectors.location)

	const { payload = {} } = location
	const { action = '' } = payload
	const Component = modulesMap[action] || NotFoundBlock

	return <Component />
}

export default DibizOrgs
