import Duck from 'extensible-duck'
import {
	creators,
	selectors,
	reducer,
	namespace,
	store,
	initialState,
} from '@dibiz/core-app/src/routes/duc'

// Feeds the page state of the global redux state.
// This is used to conditionally render your components in router.
export const MainRouteDuc = new Duck({
	namespace,
	store,
	types: [
		'DASHBOARD',
		'404',
		'500',
		'AUTH',
		'DIBIZORGS',
		'SETTINGS',
		'SETTINGS$SUBROOT',
		'ADMIN',
		'ADMIN$SUBROOT',
		'ADMIN$WACTION',
		'ADMIN$WDOCREFERENCE',
	],
	initialState,
	reducer,
	selectors,
	creators,
})
