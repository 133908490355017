import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { AdminDuc } from 'sales-app/modules/Admin/duc'
import { getIn } from 'timm'
import { DibizOrgsDuc } from 'sales-app/modules/DibizOrgs/duc'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { Menu } from 'grommet'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Box } from 'ui-lib/utils/Box'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { Spacer } from 'ui-lib/utils/Spacer'
import { stateConfig } from 'sales-app/modules/DibizOrgs/config'
import theme from 'ui-lib/utils/base-theme'
import Organisation from '../CompanyInformation/Organisation'
import Certification from '../CompanyInformation/Certification'
import Business from '../CompanyInformation/Business'

const getTabComponents = ({ certificates, details, business = [], t }) => ({
	name,
}) => () => {
	return (
		<Box
			style={{
				backgroundColor: '#fff',
			}}
		>
			{name === 'organisationInfo' && (
				<Organisation orgDetails={details} t={t} />
			)}
			{name === 'businessInfo' && <Business business={business} t={t} />}
			{name === 'certInfo' && (
				<Certification certificates={certificates} t={t} />
			)}
		</Box>
	)
}

const CompanyInformation = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const location = useSelector(AdminDuc.selectors.location)
	const { payload } = location
	const orgID = payload.documentReference
	const orgStatus = useSelector(DibizOrgsDuc.selectors.activeModule)
	const certificates = useSelector(AdminDuc.selectors.getCertificateDetails)
	const business = useSelector(AdminDuc.selectors.getBusinessDetails)
	const companyInfo = useSelector(AdminDuc.selectors.getCompanyInfo)
	const orgName = useSelector(AdminDuc.selectors.getCompanyName)
	const [activeTab, setActiveTab] = useState('organisationInfo')
	const activeStatus = useSelector(AdminDuc.selectors.getActiveStatus)
	const statusState = getIn(stateConfig, [activeStatus]) || []

	const statusStateList = statusState.map(({ label, name }) => {
		return { label: t(label), name }
	})

	const activeTypes = [
		{
			code: 'organisationInfo',
			title: 'viewCompanyInfo.organisationInfo',
		},
		{
			code: 'businessInfo',
			title: 'viewCompanyInfo.businessInfo',
		},
		{
			code: 'certInfo',
			title: 'viewCompanyInfo.certInfo',
		},
	]

	const getTabs = useCallback(() => {
		return activeTypes.map(({ code, title }, key) => ({
			name: code,
			title: t(title),
			key,
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [activeTypes, t])

	const hideMenu = activeStatus === 'verified' || activeStatus === 'rejected'

	return (
		<Box id="input-companyInfo" padding={8} width="100%" height="100%">
			<Title title={t('viewCompanyInfo.title')} />

			<Box row justifyContent="space-between">
				<Title title={orgName} />
				{!hideMenu && (
					<Box width={200}>
						<Menu
							plain
							justifyContent="center"
							alignSelf="center"
							size="small"
							dropAlign={{ top: 'top', right: 'right' }}
							label={t('viewCompanyInfo.updateStatus')}
							items={statusStateList.map(item => ({
								...item,
								onClick: () => {
									dispatch(
										AdminDuc.creators.updateOrganisationStatus(
											{ status: item.name },
											orgID
										)
									)
								},
							}))}
							dropBackground="white"
							style={{
								fontSize: 14,
								width: '100%',
								background: theme.color.primary,
								color: theme.color.white,
								borderRadius: 4,
							}}
						/>
					</Box>
				)}
			</Box>

			<Tabs
				tabs={getTabs()}
				tabComponentsMap={getTabComponents({
					type: activeTab,
					details: companyInfo,
					certificates,
					business,
					t,
				})}
				activeTabIndex={activeTypes.findIndex(
					type => type.code === activeTab
				)}
				onChange={({ name }) => {
					setActiveTab(name)
				}}
			/>
			<Spacer size={40} />

			<Box padding="25px 0" style={{ maxWidth: 100 }}>
				<Button
					label={t('common.back')}
					action
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() =>
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DIBIZORGS,
								{
									action: orgStatus,
								}
							)
						)
					}
				/>
			</Box>
		</Box>
	)
}

export { CompanyInformation }
