import React from 'react'
import styled from 'styled-components'
import { Box } from 'ui-lib/utils/Box'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card } from 'ui-lib/components/Card'
import { Icon } from 'ui-lib/icons/components/Icon'
import {
	ButtonWithNoBorder,
	ButtonIconWrapper,
	Button,
} from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { H3, SmallText } from 'ui-lib/components/Typography'
import SettingsIcon from 'ui-lib/icons/settings.svg'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { AppDuc } from 'sales-app/modules/App/duc'
import theme from 'ui-lib/utils/base-theme'

export const Line = styled.div(p => ({
	borderBottom: '1px solid #c9c9c9',
	margin: p.margin,
}))

const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.settings'),
			name: 'settings',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DIBIZORGS, {
					action: 'newRequest',
				})
			)
		}
	}

	return (
		<>
			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Box>
					<Title
						title={t('settingsHome.title')}
						icon={SettingsIcon}
						note={t('settingsHome.note')}
					/>
				</Box>
				<Box id="settingsInfo">
					<Card
						width={isMobile ? '320px' : '100%'}
						height="180px"
						textAlign="left"
					>
						<Box
							padding={8}
							style={{
								backgroundColor: theme.color.accent3,
							}}
						>
							<H3
								bold
								color={theme.color.accent2}
								style={{
									position: 'relative',
									right: '-25px',
								}}
							>
								{t('settingsHome.title')}
							</H3>
						</Box>
						<Box
							row
							id="settingsPassword"
							justifyContent="space-between"
							alignItems="center"
							height={100}
						>
							<SmallText
								fontSize={theme.fontSize.xl}
								style={{
									position: 'relative',
									right: '-25px',
								}}
							>
								{t('settingsHome.password')}
							</SmallText>
							<Box
								justifyContent="flex-end"
								width={isMobile ? 120 : 180}
							>
								<ButtonWithNoBorder
									label={t('common.viewBtn').toUpperCase()}
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.SETTINGS$SUBROOT,
												{
													rootModule:
														'change-password',
												}
											)
										)
									}
								/>
							</Box>
						</Box>
					</Card>
				</Box>
				<Box padding="25px 0" style={{ maxWidth: 100 }}>
					<Button
						label={t('common.back')}
						action
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.DIBIZORGS,
									{
										action: 'newRequest',
									}
								)
							)
						}
					/>
				</Box>
			</Box>
		</>
	)
}

export { MainDashboard }
