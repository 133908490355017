import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { DibizOrgsDuc } from 'sales-app/modules/DibizOrgs/duc'
import { BoxShadowTable } from 'sales-app/utils/sharedComponents/BoxShadowTable'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { Button } from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { CTAWrapper } from 'sales-app/utils/shared'
import { Label } from 'ui-lib/components/Typography'
import { AppDuc } from 'sales-app/modules/App/duc'
import { getTilesetColumnConfig } from '../components/columns'

const TilesetManagement = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(DibizOrgsDuc.selectors.location)
	const { payload } = location
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const isLoading = useSelector(DibizOrgsDuc.selectors.getLoadingStatus)
	const rawData = useSelector(DibizOrgsDuc.selectors.getTilesetData) || []
	const tilesetData = rawData?.list || []
	const totalDocument = getIn(rawData, ['total']) || 0
	const orgDetailsList =
		useSelector(DibizOrgsDuc.selectors.getOrgDetailsList) || []

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'update': {
					if (meta.row) {
						dispatch(
							DibizOrgsDuc.creators.UpdateTilesetData(
								meta?.row?.id,
								meta?.row?.organizationID
							)
						)
					}

					break
				}
				case 'paginate': {
					const { PrevData } = meta
					const startID = getIn(PrevData, ['nextStartID'])

					dispatch(
						DibizOrgsDuc.creators.fetchTilesetData(false, startID)
					)
					break
				}

				default:
					break
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, location.query, payload]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title={t('tileset.title')} />
			</Box>
			{tilesetData && tilesetData.length > 0 ? (
				<Box>
					<BoxShadowTable
						isLoading={isLoading}
						size="large"
						scroll={!isMobile}
						tableSize={isMobile && 'small'}
						columnConfig={getTilesetColumnConfig({
							onChange: handleOnChange,
							t,
							orgDetailsList,
						})}
						rowData={tilesetData}
					/>

					<CTAWrapper>
						<Label
							color="inherit"
							bold
							small
							style={{
								padding: '10px 4px',
							}}
						>
							{(tilesetData || []).length > 0
								? `${t('common.ShowingListFor')}
							${(tilesetData || []).length}
							  ${t('common.of')}
							  ${totalDocument}`
								: t('common.errorBlockMessage')}
						</Label>
						<Box
							style={{
								width: '75%',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Button
								plain
								disabled={
									(tilesetData || []).length === 0 ||
									tilesetData.length === totalDocument
								}
								extendStyles={{
									background: '#F2F4FF',
									padding: '8px 24px',
									borderRadius: '24px',
									width: '170px',
									textAlign: 'center',
								}}
								label={t('common.showMore')}
								onClick={() =>
									handleOnChange('paginate', {
										PrevData: rawData,
									})
								}
							/>
						</Box>
					</CTAWrapper>
				</Box>
			) : (
				<Box
					style={{
						height: '500px',
						fontSize: '18px',
						alignItems: 'center',
						justifyContent: 'center',
						border: `1px solid ${theme.color.grey4}`,
					}}
				>
					{t('tileset.noDataAvailable')}
				</Box>
			)}
		</Box>
	)
}

export { TilesetManagement }
