import { SettingsDuc } from 'sales-app/modules/Settings/duc'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { getIn } from 'timm'
import saga from 'sales-app/modules/Settings/saga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState
	extra.getSagaInjectors().injectSaga('SETTINGS', { saga })

	const isMainDashboard = MainRouteDuc.types.SETTINGS === type
	const isSubDashboard = MainRouteDuc.types.SETTINGS$SUBROOT === type

	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
	} else if (isSubDashboard) {
		activeModule = payload.rootModule

		if (payload.rootModule === 'notifications') {
			dispatch(SettingsDuc.creators.fetchNotifications(locationState))
		}
	}

	// load the dashboard of the core module
	dispatch(SettingsDuc.creators.setActiveModule(activeModule))

	// based on the payload or action, initiate saga's
}
