import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Turnstile from 'react-turnstile'
import { AppDuc } from 'sales-app/modules/App/duc'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { getRandomNumber } from 'core-app/utils/helpers'
import { isEmptyObject } from 'sales-app/utils/helpers'
import { featureFlags, getRecaptchaKey } from 'sales-app/utils/sharedConfig'
import { PasswordInput, Input } from 'ui-lib/components/Input'
import { Button } from 'ui-lib/components/Button'
import { Label } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { applyMediaQueries } from 'ui-lib/utils/helpers'

const LinkBlock = styled.span(p => ({
	textAlign: 'right',
	color: p.theme.color.primary,
	cursor: 'pointer',
	width: '160px',
	alignSelf: 'center',
	':hover': {
		textDecoration: 'underline',
	},
}))

const LoginWrapper = styled.div(p => ({
	width: p.isMobile ? '100%' : '80%',
	maxHeight: '540px',
	maxWidth: '500px',
	position: 'relative',
	background: p.theme.color.white,
	top: p.isMobile ? '15%' : '20%',
	overflow: 'initial',
	display: 'flex',
	justifyContent: 'center',
	alignSelf: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	padding: p.isMobile ? '4%' : '20px 32px',
	zIndex: p.theme.zIndex.level2,
	...applyMediaQueries(['mobile'], {
		justifyContent: 'center',
		flexDirection: 'column',
	}),
}))

const Login = () => {
	const { t } = useTranslation()
	const email = useSelector(AuthDuc.selectors.userSignInEmail)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const dispatch = useDispatch()
	const validationSchema = yup.object().shape({
		email: yup.string().required(t('login.loginUsername')),
		password: yup.string().required(t('validation.passwordRequired')),
		recaptcha: yup.string().required(t('validation.recaptchaReq')),
	})

	const [resetCaptcha, setResetCaptcha] = useState(getRandomNumber())

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		setFieldValue,
		isSubmitting,
	} = useFormik({
		initialValues: {
			email,
			password: '',
			recaptcha: featureFlags.enableLoginCaptcha ? '' : 'disabled',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			setResetCaptcha(getRandomNumber())
			dispatch(
				AuthDuc.creators.loginUser(_values, {
					setSubmitting,
				})
			)
		},
	})

	const refreshAfterAInterval = () => {
		setTimeout(() => {
			window.location.reload()
		}, 2000)
	}

	const disableCTA = !isEmptyObject(errors) || !values.email

	return (
		<Box
			style={{
				width: '100%',
				height: '100%',
				overflowY: 'auto',
			}}
			alignItems={!isMobile && 'flex-end'}
		>
			<LoginWrapper isMobile={isMobile}>
				<Box
					style={{
						width: '100%',
						maxHeight: '540px',
						maxWidth: '400px',
						backgroundColor: theme.color.white,
					}}
				>
					<form onSubmit={handleSubmit}>
						<Box
							style={{
								width: '100%',
							}}
						>
							<Box style={{ width: '100%' }}>
								<Label
									style={{
										fontWeight: 'bold',
										fontSize: theme.fontSize.xl,
										color: theme.color.accent2,
									}}
								>
									{t('login.username')}
								</Label>
								<Spacer size={12} />
								<Input
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.email && errors.email}
									name="email"
									type="text"
									placeholder={t('login.loginUsernameData')}
									extendStyles={{
										background: `${theme.color.white} 0% 0% no-repeat padding-box`,
										boxShadow: `0px 2px 8px ${theme.color.blue6}`,
										border: `1px solid ${theme.color.blue5}`,
										borderRadius: '4px',
										opacity: 1,
									}}
								/>
							</Box>
							<Box
								row
								alignItems="baseline"
								justifyContent="space-between"
								style={{ paddingBottom: '12px' }}
							>
								<Label
									style={{
										fontWeight: 'bold',
										fontSize: theme.fontSize.xl,
										color: theme.color.accent2,
									}}
								>
									{t('login.password')}
								</Label>
								<LinkBlock
									style={{
										color: theme.color.blue4,
										fontSize: theme.fontSize.xl,
									}}
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types.AUTH,
												{
													action: 'forgot-password',
												}
											)
										)
									}
								>
									{t('login.forgotPassword')}
								</LinkBlock>
							</Box>
							<PasswordInput
								name="password"
								value={values.password}
								type="password"
								onChange={handleChange}
								onBlur={handleBlur}
								placeholder={t('login.enterPassword')}
								error={touched.password && errors.password}
								extendStyles={{
									background:
										values.password.length === 0
											? `${theme.color.grey11} 0% 0% no-repeat padding-box`
											: `${theme.color.white} 0% 0% no-repeat padding-box`,
									boxShadow:
										values.password.length === 0
											? '0px 6px 16px #05050707'
											: `0px 2px 8px ${theme.color.blue6}`,
									border:
										values.password.length === 0
											? `1px solid ${theme.color.grey4}`
											: `1px solid ${theme.color.blue5}`,
									borderRadius: '4px',
									opacity: 1,
								}}
							/>
							<Spacer size={20} />
							{featureFlags.enableLoginCaptcha && (
								<div key={resetCaptcha}>
									<Turnstile
										id="dibiz-captcha"
										refreshExpired="manual"
										sitekey={getRecaptchaKey()}
										onVerify={response => {
											setFieldValue(
												'recaptcha',
												response || ''
											)
										}}
										onExpire={() => {
											if (navigator.onLine) {
												setResetCaptcha(
													getRandomNumber()
												)
											} else {
												refreshAfterAInterval()
											}
										}}
										onError={response => {
											refreshAfterAInterval()
										}}
									/>
								</div>
							)}
							<Spacer size={20} />
							<Button
								isLoading={isSubmitting}
								disabled={disableCTA}
								type="submit"
								primary
								label={t('login.signIn')}
								style={{
									paddingBottom: '50px',
									width: '100%',
								}}
							/>
						</Box>
					</form>
				</Box>
			</LoginWrapper>
		</Box>
	)
}

export default Login
