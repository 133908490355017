/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'
import { AppDuc } from 'sales-app/modules/App/duc'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { AdminDuc } from 'sales-app/modules/Admin/duc'
import { CookieDuc } from 'sales-app/modules/App/cookieDuc'
import { SettingsDuc } from 'sales-app/modules/Settings/duc'
import { DibizOrgsDuc } from 'sales-app/modules/DibizOrgs/duc'
import { MainRouteDuc as PageDuc } from 'sales-app/routes/duc'
import { DeviceDetectionDuc } from 'sales-app/store/deviceDetectionDuc'
import { i18nextReducer } from 'i18next-redux-languagedetector'
/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
	return combineReducers({
		i18next: i18nextReducer,
		app: reduceReducers(
			AppDuc.reducer,
			CookieDuc.reducer,
			DeviceDetectionDuc.reducer
		),
		auth: AuthDuc.reducer,
		page: PageDuc.reducer,
		dibizOrgs: DibizOrgsDuc.reducer,
		settings: SettingsDuc.reducer,
		admin: AdminDuc.reducer,
		...injectedReducers,
	})
}
