import React from 'react'
import {
	ORG_ALIASES_KEYS,
	certKeys1,
	certKeys2,
	supplyChainModels,
	colorCode,
	textColorCode,
} from 'sales-app/modules/Admin/config'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Card } from 'ui-lib/components/Card'
import { FileLists } from 'ui-lib/components/FileUpload'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { AdminDuc } from 'sales-app/modules/Admin/duc'
import { useSelector } from 'react-redux'
import { AppDuc } from 'sales-app/modules/App/duc'
import { KeyValueSegment } from 'sales-app/modules/Admin/components/KeyValueSegment'
import { SmallText, P } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'

const CertificationBlock = ({ isMobile, t, certDetails }) => {
	const scModel = getIn(certDetails, ['scmodel'])

	return (
		<>
			<Box row={!isMobile} padding="20px">
				<Card
					style={{
						padding: '20px',
						position: 'relative',
						overflow: 'visible',
						boxShadow: ' 0px 6px 16px #00000014',
					}}
					textAlign="left"
				>
					<SmallText
						style={{
							position: 'absolute',
							fontSize: theme.fontSize.m,
							color: theme.color.accent2,
							top: '-10px',
							left: '20px',
							display: 'inline-block',
							backgroundColor: theme.color.white,
							padding: '0 8px',
						}}
					>
						{getIn(certDetails, ['certOrg'])}
					</SmallText>
					<Box row style={{ width: '100%' }}>
						<Box style={{ width: '50%' }}>
							{certKeys1.map(key => {
								return (
									<KeyValueSegment
										key={key}
										labelWidth="50%"
										label={t(ORG_ALIASES_KEYS[key])}
										value={certDetails[key]}
									/>
								)
							})}
						</Box>
						<Box style={{ width: '50%' }}>
							{certKeys2.map(key => {
								return (
									<KeyValueSegment
										labelWidth="50%"
										key={key}
										label={t(ORG_ALIASES_KEYS[key])}
										value={
											key === 'scmodel'
												? t(supplyChainModels[scModel])
												: certDetails[key]
										}
									/>
								)
							})}
							<FileLists
								documents={certDetails.files || []}
								isReadOnly
							/>
						</Box>
					</Box>
				</Card>
			</Box>
		</>
	)
}

const Certification = ({ certificates, t }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(AdminDuc.selectors.location)
	const { payload } = location
	const orgID = payload.documentReference
	const orgStatus = useSelector(AdminDuc.selectors.getActiveStatus)

	return (
		<>
			<Box row justifyContent="flex-end" padding="20px 20px 0px 0px">
				<Box
					style={{
						width: '118px',
						height: '36px',
						background: `${colorCode[orgStatus]} 0% 0% no-repeat padding-box`,
						borderRadius: '5px',
						opacity: '1',
						justifyContent: 'center',
					}}
				>
					<P
						style={{
							height: '19px',
							textAlign: 'center',
							font: 'normal normal medium 19px Roboto',
							letterSpacing: '0px',
							color: textColorCode[orgStatus],
							opacity: '1',
						}}
					>
						{orgStatus === 'verified'
							? 'APPROVED'
							: orgStatus.toUpperCase()}
					</P>
				</Box>
			</Box>

			{certificates.length > 0 ? (
				(certificates || []).map(certificate => (
					<CertificationBlock
						isMobile={isMobile}
						key={certificate.id}
						t={t}
						certDetails={certificate}
						orgID={orgID}
					/>
				))
			) : (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</>
	)
}

export default Certification
