import LogHelper from 'sales-app/utils/logger'
import { SettingsDuc } from 'sales-app/modules/Settings/duc'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { Toast } from 'ui-lib/components/Toast'
import { AppDuc } from 'sales-app/modules/App/duc'
import {
	getIAMEndPoint,
	getNotifierEndPoint,
} from 'sales-app/utils/sharedConfig'
import { CallWithRefreshCheck } from 'sales-app/modules/Auth/saga'
import request from 'sales-app/utils/request'
import { getIn, merge, omit } from 'timm'
import querySerializer from 'query-string'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import { transformFilterStringsToBEQueries } from 'sales-app/utils/shared'

const logger = LogHelper('client:SettingsSaga')

const PAGINATION_LIMIT = 10

function* changePassword(action) {
	try {
		const { values, toastMessage } = action

		yield put(AppDuc.creators.showGlobalLoader('change-password'))

		const requestUrl = `${getIAMEndPoint()}clients/users/-/password`

		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}

		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		yield put(MainRouteDuc.creators.switchPage(MainRouteDuc.types.SETTINGS))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('change-password'))
	}
}

function* fetchNotifications(action) {
	try {
		yield put(SettingsDuc.creators.flushNotifications())
		const { locationState = {} } = action

		yield put(AppDuc.creators.showGlobalLoader('show-all-notifications'))

		const { query } = locationState
		const existingQueryFromUrl = query || {}
		let backendTargetQuery = {} // this would go to backend api call
		backendTargetQuery = merge(
			backendTargetQuery,
			transformFilterStringsToBEQueries(existingQueryFromUrl)
		)
		const __query = {
			...backendTargetQuery,
		}

		const requestUrl = `${getNotifierEndPoint()}clients/-/notifications?limit=${PAGINATION_LIMIT}&sort=desc(createdAt)&${querySerializer.stringify(
			{
				...__query,
			}
		)}`

		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const notifications = getIn(origResponse, ['data']) || []
		yield put(SettingsDuc.creators.setNotifications(notifications.list))
		yield put(
			SettingsDuc.creators.setNotificationPaginationEntries(
				getIn(notifications, ['total']),
				getIn(notifications, ['pageSize']),
				getIn(notifications, ['nextStartID'])
			)
		)
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('show-all-notifications'))
	}
}

function* appendNotifications(action) {
	try {
		const { locationState = {} } = action
		yield put(AppDuc.creators.showGlobalLoader('show-more-notifications'))

		const { query } = locationState
		const existingQueryFromUrl = query || {}
		// eslint-disable-next-line prefer-const
		let backendTargetQuery = {} // this would go to backend api call
		const paginationQuery = {
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}
		// prepare backend query based on the pagination factors
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const filterQueries =
			omit(existingQueryFromUrl, ['limit', 'nextIndex']) || {}
		// form the backend queries form the object
		backendTargetQuery = merge(
			backendTargetQuery,
			transformFilterStringsToBEQueries(filterQueries)
		)

		const __query = {
			...backendTargetQuery,
		}

		const requestUrl = `${getNotifierEndPoint()}clients/-/notifications?sort=desc(createdAt)&${querySerializer.stringify(
			{
				...__query,
			}
		)}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const notifications = getIn(origResponse, ['data']) || []
		yield put(SettingsDuc.creators.setNotifications(notifications.list))
		yield put(
			SettingsDuc.creators.setNotificationPaginationEntries(
				getIn(notifications, ['total']),
				getIn(notifications, ['pageSize']),
				getIn(notifications, ['nextStartID'])
			)
		)
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('show-more-notifications'))
	}
}

export default function* SettingsSaga() {
	try {
		yield all([
			takeLatest(
				SettingsDuc.creators.changePassword().type,
				changePassword
			),
			takeLatest(
				SettingsDuc.creators.fetchNotifications().type,
				fetchNotifications
			),
			takeLatest(
				SettingsDuc.creators.appendNotifications().type,
				appendNotifications
			),
		])
	} catch (err) {
		logger.log(err)
	}
}
