import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { KeyValueSegment } from 'sales-app/modules/Admin/components/KeyValueSegment'
import { Box } from 'ui-lib/utils/Box'
import {
	orgKeys,
	ORG_ALIASES_KEYS,
	farmDetailsKeys,
	FARMDETAILS_ALIASES_KEYS,
	colorCode,
	textColorCode,
} from 'sales-app/modules/Admin/config'
import EditIcon from 'ui-lib/icons/edit.svg'
import { getIn } from 'timm'
import { ButtonWithNoBorder, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Modal } from 'ui-lib/components/Modal'
import { FileUploadWithPreview } from 'ui-lib/components/FileUpload'
import { AdminDuc } from 'sales-app/modules/Admin/duc'
import { AppDuc } from 'sales-app/modules/App/duc'
import { Card } from 'ui-lib/components/Card'
import { SmallText, P } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Spacer } from 'ui-lib/utils/Spacer'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import FarmDetails from './FarmDetails'

const Organisation = ({ orgDetails, t }) => {
	const [showFarmModal, setShowFarmModal] = useState(false)
	const location = useSelector(AdminDuc.selectors.location)
	const { payload } = location
	const orgID = payload.documentReference
	const orgStatus = useSelector(AdminDuc.selectors.getActiveStatus)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []
	const dispatch = useDispatch()
	const farmDetails = getIn(orgDetails, ['farmDetails']) || {}
	const [farmValues, setFarmValues] = useState(farmDetails)

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showFarmModal}
				heading={t('viewCompanyInfo.updateFarmDetails')}
				body={
					<FarmDetails
						details={farmValues}
						onChange={_values => setFarmValues(_values)}
					/>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				isDisabled={
					!farmValues.area ||
					!farmValues.plantationArea ||
					!farmValues.plantationTime ||
					!farmValues.city ||
					!farmValues.line1 ||
					!farmValues.district ||
					!farmValues.state ||
					!farmValues.country
				}
				onClose={() => setShowFarmModal(false)}
				onConfirm={() => {
					setShowFarmModal(false)
					dispatch(
						AdminDuc.creators.updateFarmDetails(farmValues, orgID)
					)
				}}
			/>
			<Box row style={{ width: '100%' }} padding="20px">
				<Box style={{ width: '60%' }}>
					{orgKeys.map(key => {
						return (
							<KeyValueSegment
								labelWidth="35%"
								key={key}
								label={t(ORG_ALIASES_KEYS[key])}
								value={orgDetails[key]}
							/>
						)
					})}
				</Box>
				<Box style={{ width: '40%' }}>
					<Box alignItems="flex-end">
						<Box
							style={{
								width: '118px',
								height: '36px',
								background: `${colorCode[orgStatus]} 0% 0% no-repeat padding-box`,
								borderRadius: '5px',
								opacity: '1',
								justifyContent: 'center',
							}}
						>
							<P
								style={{
									height: '19px',
									textAlign: 'center',
									font: 'normal normal medium 19px Roboto',
									letterSpacing: '0px',
									color: textColorCode[orgStatus],
									opacity: '1',
								}}
							>
								{orgStatus === 'verified'
									? 'APPROVED'
									: orgStatus.toUpperCase()}
							</P>
						</Box>
					</Box>
					<Spacer size={16} />

					<Box center>
						<FileUploadWithPreview
							type="public"
							name="license"
							size="5242880"
							onDocumentChange={document => {
								const logoURL =
									getIn(document, [0, 'meta', 'fullURL']) ||
									''
								if (logoURL) {
									dispatch(
										AdminDuc.creators.updateOrganisationLogo(
											logoURL,
											orgID
										)
									)
								}
							}}
							onUploadFailure={({ message }) => {
								dispatch(
									AppDuc.creators.showToast({
										messageType: 'error',
										message,
									})
								)
							}}
							imageSrc={getIn(orgDetails, ['logoURL']) || ''}
						/>
					</Box>
				</Box>
			</Box>
			{(actor === 'palmoil_plantation' ||
				actor === 'palmoil_plantation_administrator') && (
				<Box padding="20px">
					<Card
						style={{
							padding: '20px',
							position: 'relative',
							overflow: 'visible',
							boxShadow: ' 0px 6px 16px #00000014',
						}}
						textAlign="left"
					>
						<SmallText
							style={{
								position: 'absolute',
								fontSize: theme.fontSize.m,
								color: theme.color.accent2,
								top: '-10px',
								left: '20px',
								display: 'inline-block',
								backgroundColor: theme.color.white,
								padding: '0 8px',
							}}
						>
							{t('viewCompanyInfo.farmDetails')}
						</SmallText>
						<Box row justifyContent="flex-end">
							<Spacer size={16} />

							<ButtonWithNoBorder
								label={t('common.edit')}
								action
								rounded
								customIcon={
									<ButtonIconWrapper lightBG>
										<Icon glyph={EditIcon} />
									</ButtonIconWrapper>
								}
								onClick={() => {
									setShowFarmModal(true)
								}}
							/>
						</Box>
						<Box row style={{ width: '100%' }}>
							<Box style={{ width: '100%' }}>
								{farmDetailsKeys.map(key => {
									return (
										<KeyValueSegment
											labelWidth="20%"
											noMaxWidth
											key={key}
											label={t(
												FARMDETAILS_ALIASES_KEYS[key]
											)}
											value={farmDetails[key]}
										/>
									)
								})}
							</Box>
						</Box>
					</Card>
				</Box>
			)}
		</>
	)
}

export default Organisation
