import { MainRouteDuc } from 'sales-app/routes/duc'
import { intersectFlatArrays } from 'sales-app/utils/helpers'
import AuthThunk from './thunks/authThunk'
import settingsThunk from './thunks/settingsThunk'
import dibizOrgsThunk from './thunks/dibizOrgsThunk'
import adminThunk from './thunks/adminThunk'

const authActionRegex = 'forgot-password|sign-in|two-step-verification'
// Settings regex
const settingsModuleRegex = 'change-password|notifications'
// DibizOrgs regex
const dibizOrgsActionRegex =
	'newRequest|rejectedOrgs|approvedOrgs|update-ffb-price|certification-management|tileset-management|dashboard|global-products|news-updates|org-groups'

// Admin regex
const AdminModuleRegex = 'company-information'
const AdminActionRegex = 'view-companyInfo'

export default {
	[MainRouteDuc.types.AUTH]: {
		path: `/auth/:action(${authActionRegex})`,
		thunk: AuthThunk,
	},
	[MainRouteDuc.types.DASHBOARD]: '/',
	[MainRouteDuc.types.SETTINGS]: {
		path: '/settings',
		thunk: settingsThunk,
	},
	[MainRouteDuc.types.SETTINGS$SUBROOT]: {
		path: `/settings/:rootModule(${settingsModuleRegex})`,
		thunk: settingsThunk,
	},
	[MainRouteDuc.types.DIBIZORGS]: {
		path: `/dibizOrgs/:action(${dibizOrgsActionRegex})`,
		thunk: dibizOrgsThunk,
	},
	[MainRouteDuc.types.ADMIN]: {
		path: '/admin',
		thunk: adminThunk,
	},
	[MainRouteDuc.types.ADMIN$SUBROOT]: {
		path: `/admin/:rootModule(${AdminModuleRegex})`,
		thunk: adminThunk,
	},
	[MainRouteDuc.types.ADMIN$WACTION]: {
		path: `/admin/:rootModule(${AdminModuleRegex})/:action(${AdminActionRegex})`,
		thunk: adminThunk,
	},

	[MainRouteDuc.types.ADMIN$WDOCREFERENCE]: {
		path: `/admin/:rootModule(${AdminModuleRegex})/:action(${AdminActionRegex})/:documentReference`,
		thunk: adminThunk,
	},
}

export const defaultAccessGroup = ['fe.core.minimal.r']

//
export const featureAccessBasedOnType = {
	// make also a function come here, that receives payload. User can write rules based on payload, at the end should return array
	[MainRouteDuc.types.SETTINGS]: ['fe.settings.dashboard.r'],
	[MainRouteDuc.types.SETTINGS$SUBROOT]: [
		'fe.settings.change-password.r',
		'fe.settings.change-language.r',
		'fe.settings.notifications.r',
	],
	[MainRouteDuc.types.DIBIZORGS]: defaultAccessGroup,
	[MainRouteDuc.types.ADMIN]: ['fe.admin.dashboard.r'],
	[MainRouteDuc.types.ADMIN$SUBROOT]: [
		'fe.admin.assets.r',
		'fe.admin.employee-management.r',
		'fe.admin.company-information.r',
	],
	[MainRouteDuc.types.ADMIN$WACTION]: [
		'fe.admin.assets.r',
		'fe.admin.employee-management.r',
		'fe.admin.company-information.r',
	],
	[MainRouteDuc.types.ADMIN$WDOCREFERENCE]: [
		'fe.admin.assets.r',
		'fe.admin.employee-management.r',
		'fe.admin.company-information.r',
	],
}

export const navigationBaseReference = [
	{
		title: 'Dashboard',
		action: MainRouteDuc.types.DIBIZORGS,
		payload: { action: 'dashboard' },
	},
	{
		title: 'Organisation Groups',
		action: MainRouteDuc.types.DIBIZORGS,
		payload: { action: 'org-groups' },
	},
	{
		title: 'common.dibizOrgs',
		action: MainRouteDuc.types.DIBIZORGS,
		payload: { action: 'newRequest' },
		subRoutes: [
			{
				title: 'admin.newReq',
				action: MainRouteDuc.types.DIBIZORGS,
				payload: { action: 'newRequest' },
			},
			{
				title: 'admin.rejected',
				action: MainRouteDuc.types.DIBIZORGS,
				payload: { action: 'rejectedOrgs' },
			},
			{
				title: 'admin.approved',
				action: MainRouteDuc.types.DIBIZORGS,
				payload: { action: 'approvedOrgs' },
			},
		],
	},
	{
		title: 'FFB Prices',
		action: MainRouteDuc.types.DIBIZORGS,
		payload: { action: 'update-ffb-price' },
	},
	{
		title: 'Tileset Management',
		action: MainRouteDuc.types.DIBIZORGS,
		payload: { action: 'tileset-management' },
	},
	{
		title: 'Certification Management',
		action: MainRouteDuc.types.DIBIZORGS,
		payload: { action: 'certification-management' },
	},
	{
		title: 'Global Products',
		action: MainRouteDuc.types.DIBIZORGS,
		payload: { action: 'global-products' },
	},
	{
		title: 'News & Updates',
		action: MainRouteDuc.types.DIBIZORGS,
		payload: { action: 'news-updates' },
	},
]

export const DASHBOARD_ACCESS_HIERARCHY = [MainRouteDuc.types.DIBIZORGS]

export const getActiveUserDashboardAction = (
	roles = [],
	payload = {},
	redirect = true
) => {
	const activeCreator = redirect
		? MainRouteDuc.creators.redirect
		: MainRouteDuc.creators.switchPage

	// if user has access to main dashboard don't work going further
	if (
		intersectFlatArrays(
			featureAccessBasedOnType[MainRouteDuc.types.DASHBOARD],
			roles
		).length
	) {
		return activeCreator(MainRouteDuc.types.DASHBOARD, payload)
	}

	const targetAction = DASHBOARD_ACCESS_HIERARCHY.find(type => {
		const actionRoles = featureAccessBasedOnType[type] || []
		const matchingRoles = intersectFlatArrays(actionRoles, roles)

		return !!matchingRoles.length
	})

	return activeCreator(targetAction, payload)
}
