import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { initialState } from './config'

export const MODULES_LIST = [
	'newRequests',
	'rejectedOrgs',
	'unverfiedOrgs',
	'approvedOrgs',
]

export const DibizOrgsDuc = new Duck({
	namespace: 'dibizOrgs',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'SET_ORGANISATION_LIST',
		'RESET_ORGANISATION_LIST',
		'RESET_TILESET_DATA',
		'FETCH_ORGANISATION_LIST',
		'FETCH_LOADING_STATUS',
		'SET_PAGINATION_QUERIES',
		'FETCH_FFB_PRICE_LIST',
		'FETCH_TILESET_DATA',
		'UPDATE_TILESET_DATA',
		'SET_FFB_PRICES',
		'SET_TILESET_DATA',
		'SET_ORG_DETAILS_LIST',
		'UPDATE_FFB_PRICES',
		'FETCH_GLOBAL_PRODUCTS',
		'SET_GLOBAL_PRODUCTS',
		'FLUSH_GLOBAL_PRODUCTS',
		'ADD_GLOBAL_PRODUCT',
		'FETCH_CERTIFICATES',
		'SET_CERTIFICATES',
		'FLUSH_CERTIFICATES',
		'ADD_NEWS_AND_UPDATES',
		'APPROVE_CERTIFICATE',
		'FETCH_ORGANISATION_GROUPS',
		'SET_ORGANISATION_GROUPS',
		'FETCH_DASHBOARD_INSIGHTS',
		'SET_DASHBOARD_INSIGHTS',
		'FETCH_ORGS_LIST',
		'SET_ORGS_LIST',
		'DELETE_ORG_GROUP',
		'ADD_ORG_GROUP',
		'READ_ORG_GROUP',
		'SET_ORG_GROUP_DETAILS',
		'UPDATE_ORG_GROUP',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}

			case duck.types.SET_ORGANISATION_LIST: {
				const { allOrgsList } = action
				const currentDocs = getIn(state, ['allOrgsList'])

				return setIn(
					state,
					['allOrgsList'],
					currentDocs.concat(allOrgsList)
				)
			}

			case duck.types.RESET_ORGANISATION_LIST: {
				const initialValue = initialState.allOrgsList

				return setIn(state, ['allOrgsList'], initialValue)
			}

			case duck.types.RESET_TILESET_DATA: {
				const initialValue = initialState.tilesetData

				return setIn(state, ['tilesetData'], initialValue)
			}

			case duck.types.FETCH_LOADING_STATUS: {
				return setIn(state, ['loading'], action.status || false)
			}

			case duck.types.SET_PAGINATION_QUERIES: {
				const { paginate } = action

				return setIn(state, ['pagination'], paginate)
			}

			case duck.types.SET_FFB_PRICES: {
				const { list } = action

				return setIn(state, ['ffbPrices'], list)
			}

			case duck.types.SET_GLOBAL_PRODUCTS: {
				const { data } = action

				const currentDataList =
					getIn(state, ['globalProducts', 'list']) || []

				const finalData = {
					list: currentDataList?.concat(getIn(data, ['list'])),
					total: getIn(data, ['total']),
					nextStartID: getIn(data, ['nextStartID']),
				}

				return setIn(state, ['globalProducts'], finalData)
			}

			case duck.types.SET_CERTIFICATES: {
				const { data } = action

				const currentDataList =
					getIn(state, ['certificates', 'list']) || []

				const finalData = {
					list: currentDataList?.concat(getIn(data, ['list'])),
					orgList: getIn(data, ['orgList']),
					total: getIn(data, ['total']),
					nextStartID: getIn(data, ['nextStartID']),
				}

				return setIn(state, ['certificates'], finalData)
			}

			case duck.types.SET_TILESET_DATA: {
				const { data, fromUpdate } = action
				const currentDataList = fromUpdate
					? []
					: getIn(state, ['tilesetData', 'list']) || []
				const finalData = {
					list: currentDataList.concat(getIn(data, ['list'])),
					total: getIn(data, ['total']),
					nextStartID: getIn(data, ['nextStartID']),
				}

				return setIn(state, ['tilesetData'], finalData)
			}

			case duck.types.SET_ORG_DETAILS_LIST: {
				const { data } = action
				const currentDataList = getIn(state, ['orgDetailsList']) || []
				const finalList = [...currentDataList]
				if (data?.length > 0) {
					data.forEach(item => {
						if (!currentDataList.find(ele => ele.id === item.id)) {
							finalList.push(item)
						}
					})
				}

				return setIn(state, ['orgDetailsList'], finalList)
			}

			case duck.types.FLUSH_GLOBAL_PRODUCTS: {
				return setIn(state, ['globalProducts'], {})
			}

			case duck.types.FLUSH_CERTIFICATES: {
				return setIn(state, ['certificates'], {})
			}

			case duck.types.SET_ORGANISATION_GROUPS: {
				const { data } = action

				return setIn(state, ['organisationGroups'], data)
			}

			case duck.types.SET_DASHBOARD_INSIGHTS: {
				const { data } = action

				return setIn(state, ['dashboardInsights'], data)
			}

			case duck.types.SET_ORGS_LIST: {
				const { data } = action

				return setIn(state, ['orgsList'], data)
			}

			case duck.types.SET_ORG_GROUP_DETAILS: {
				const { data } = action

				return setIn(state, ['orgGroupDetails'], data)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		activeModule: state =>
			getIn(state, ['dibizOrgs', 'activeModule']) || 'Error',
		getOrgList: state => getIn(state, ['dibizOrgs', 'allOrgsList']) || [],
		getLoadingStatus: state =>
			getIn(state, ['dibizOrgs', 'loading']) || false,
		getPaginationEntries: state =>
			getIn(state, ['dibizOrgs', 'pagination']) || {},
		getFFBPrices: state => getIn(state, ['dibizOrgs', 'ffbPrices']) || [],
		getGlobalProducts: state =>
			getIn(state, ['dibizOrgs', 'globalProducts']) || {},
		getCertificates: state =>
			getIn(state, ['dibizOrgs', 'certificates']) || {},
		getTilesetData: state =>
			getIn(state, ['dibizOrgs', 'tilesetData']) || [],
		getOrgDetailsList: state =>
			getIn(state, ['dibizOrgs', 'orgDetailsList']) || [],
		getOrgGroups: state =>
			getIn(state, ['dibizOrgs', 'organisationGroups']) || [],
		getDashboardInsights: state =>
			getIn(state, ['dibizOrgs', 'dashboardInsights']) || [],
		getOrgsList: state => getIn(state, ['dibizOrgs', 'orgsList']) || [],
		getOrgGroupDetails: state =>
			getIn(state, ['dibizOrgs', 'orgGroupDetails']) || [],
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		setOrgList: allOrgsList => ({
			type: duck.types.SET_ORGANISATION_LIST,
			allOrgsList,
		}),
		resetOrgList: () => ({
			type: duck.types.RESET_ORGANISATION_LIST,
		}),
		reseTilesetData: () => ({
			type: duck.types.RESET_TILESET_DATA,
		}),
		fetchOrgList: (isSkip, locationState, skipGlobalLoader = false) => ({
			type: duck.types.FETCH_ORGANISATION_LIST,
			isSkip,
			locationState,
			skipGlobalLoader,
		}),
		fetchLoadingStatus: status => ({
			type: duck.types.FETCH_LOADING_STATUS,
			status,
		}),
		setPaginationQueries: paginate => ({
			type: duck.types.SET_PAGINATION_QUERIES,
			paginate,
		}),
		fetchFFBPriceList: () => ({
			type: duck.types.FETCH_FFB_PRICE_LIST,
		}),
		fetchTilesetData: (fromUpdate = false, startID = '') => ({
			type: duck.types.FETCH_TILESET_DATA,
			fromUpdate,
			startID,
		}),
		UpdateTilesetData: (geoCoordinate = '', orgID = '') => ({
			type: duck.types.UPDATE_TILESET_DATA,
			geoCoordinate,
			orgID,
		}),
		setFFBPrices: list => ({
			type: duck.types.SET_FFB_PRICES,
			list,
		}),
		setTilesetData: (data, fromUpdate = false) => ({
			type: duck.types.SET_TILESET_DATA,
			data,
			fromUpdate,
		}),
		setOrgDetailsList: data => ({
			type: duck.types.SET_ORG_DETAILS_LIST,
			data,
		}),
		updateFFBPrices: (regionPrices, date, helpers) => ({
			type: duck.types.UPDATE_FFB_PRICES,
			regionPrices,
			date,
			helpers,
		}),
		fetchGlobalProductsList: (startID = '') => ({
			type: duck.types.FETCH_GLOBAL_PRODUCTS,
			startID,
		}),
		setGlobalProductsList: data => ({
			type: duck.types.SET_GLOBAL_PRODUCTS,
			data,
		}),
		flushGlobalProductsList: () => ({
			type: duck.types.FLUSH_GLOBAL_PRODUCTS,
		}),
		addGlobalProduct: payload => ({
			type: duck.types.ADD_GLOBAL_PRODUCT,
			payload,
		}),
		fetchCertificates: (startID = '') => ({
			type: duck.types.FETCH_CERTIFICATES,
			startID,
		}),
		setCertificates: data => ({
			type: duck.types.SET_CERTIFICATES,
			data,
		}),
		addNewsAndUpdates: (payload, helpers) => ({
			type: duck.types.ADD_NEWS_AND_UPDATES,
			payload,
			helpers,
		}),
		approveCertificate: payload => ({
			type: duck.types.APPROVE_CERTIFICATE,
			payload,
		}),
		flushCertificates: () => ({
			type: duck.types.FLUSH_CERTIFICATES,
		}),
		fetchOrganisationGroups: () => ({
			type: duck.types.FETCH_ORGANISATION_GROUPS,
		}),
		setOrganisationGroups: data => ({
			type: duck.types.SET_ORGANISATION_GROUPS,
			data,
		}),
		fetchDashboardInsights: (
			orgGroupID,
			startDate,
			endDate,
			orgGroups
		) => ({
			type: duck.types.FETCH_DASHBOARD_INSIGHTS,
			orgGroupID,
			startDate,
			endDate,
			orgGroups,
		}),
		setDashboardInsights: data => ({
			type: duck.types.SET_DASHBOARD_INSIGHTS,
			data,
		}),
		fetchOrgsList: () => ({
			type: duck.types.FETCH_ORGS_LIST,
		}),
		setOrgsList: data => ({
			type: duck.types.SET_ORGS_LIST,
			data,
		}),
		deleteOrgGroup: id => ({
			type: duck.types.DELETE_ORG_GROUP,
			id,
		}),
		addOrgGroup: data => ({
			type: duck.types.ADD_ORG_GROUP,
			data,
		}),
		readOrgGroup: id => ({
			type: duck.types.READ_ORG_GROUP,
			id,
		}),
		setOrgGroupDetails: data => ({
			type: duck.types.SET_ORG_GROUP_DETAILS,
			data,
		}),
		updateOrgGroup: (organisations, orgGroupID) => ({
			type: duck.types.UPDATE_ORG_GROUP,
			organisations,
			orgGroupID,
		}),
	}),
})
