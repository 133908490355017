import React from 'react'
import { useDispatch } from 'react-redux'
import { getCountries } from 'country-state-picker'
import { DibizOrgsDuc } from 'sales-app/modules/DibizOrgs/duc'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { isEmptyObject } from 'sales-app/utils/helpers'
import { Box } from 'ui-lib/utils/Box'
import { Input } from 'ui-lib/components/Input'
import { Select, SelectSearch } from 'ui-lib/components/Select'
import { Button } from 'ui-lib/components/Button'
import { Spacer } from 'ui-lib/utils/Spacer'
import { TextArea } from 'ui-lib/components/TextArea'
import theme from 'ui-lib/utils/base-theme'
import { Label } from 'ui-lib/components/Label'
import { useFormik } from 'formik'
import * as yup from 'yup'

const NewsAndUpdates = () => {
	const dispatch = useDispatch()

	const validationSchema = yup.object().shape({
		title: yup.string().required('Title is required'),
		description: yup.string().required('Please enter the description'),
		url: yup.string().required('Please enter the url'),
		category: yup.string().required('Please enter the category'),
		thumbnailImageURL: yup
			.string()
			.required('Please enter the url for the thumbnail image'),
		domain: yup.string().required('Domain is required'),
		languageCode: yup.string().required('Language is required'),
		country: yup.string().required('Country is required'),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		isSubmitting,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues: {
			title: '',
			description: '',
			category: '',
			url: '',
			thumbnailImageURL: '',
			domain: 'palm-oil',
			languageCode: '',
			country: '',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			const payload = {
				..._values,
				country: values.country.toLowerCase(),
			}
			dispatch(
				DibizOrgsDuc.creators.addNewsAndUpdates(payload, {
					setSubmitting,
				})
			)
		},
	})

	const countries = getCountries()

	const countryList = []

	countries.forEach(value => {
		if (value.code !== 'gg') {
			countryList.push({
				name: value.code,
				label: value.name,
			})
		}
	})

	const disableCTA = !isEmptyObject(errors) || !values.title

	return (
		<Box padding={8} width="100%" height="100%">
			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title="Add News and Updates" />
			</Box>
			<form onSubmit={handleSubmit}>
				<Box>
					<Box
						row
						style={{ width: '100%' }}
						justifyContent="space-between"
					>
						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Title
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values.title}
									name="title"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.title && errors.title}
								/>
							</Box>
						</Box>
						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Category (News/Event/Video)
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Select
									options={[
										{
											name: 'News',
											id: 'news',
										},
										{
											name: 'Event',
											id: 'event',
										},
										{
											name: 'Video',
											id: 'video',
										},
									]}
									labelKey="name"
									valueKey="id"
									returnOnlyValue
									value={values.category}
									onChange={({ id }) => {
										setFieldValue('category', id)
									}}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						row
						style={{ width: '100%' }}
						justifyContent="space-between"
					>
						<Box style={{ width: '30%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Language
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Select
									options={[
										{
											name: 'English',
											id: 'en',
										},
										{
											name: 'Bahasa Melayu',
											id: 'ms',
										},
										{
											name: 'Bahasa Indonesia',
											id: 'id',
										},
										{
											name: '普通话',
											id: 'zh',
										},
										{
											name: 'தமிழ்',
											id: 'ta',
										},
										{
											name: 'Español',
											id: 'es',
										},
									]}
									labelKey="name"
									valueKey="id"
									returnOnlyValue
									value={values.languageCode}
									onChange={({ id }) => {
										setFieldValue('languageCode', id)
									}}
								/>
							</Box>
						</Box>
						<Box style={{ width: '30%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Domain
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Select
									options={[
										{
											name: 'Palm Oil',
											id: 'palm-oil',
										},
									]}
									labelKey="name"
									valueKey="id"
									returnOnlyValue
									value={values.domain}
									onChange={({ id }) => {
										setFieldValue('domain', id)
									}}
								/>
							</Box>
						</Box>
						<Box style={{ width: '30%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Country
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<SelectSearch
									required
									value={values.country}
									options={countryList}
									onChange={value => {
										if (!isEmptyObject(value)) {
											setFieldValue(
												'country',
												value.label
											)
										}
									}}
									labelKey="label"
									valueKey="name"
									returnOnlyValue
									onBlur={handleBlur}
									touched={touched.country}
									errors={errors.country}
									style={{
										border: `1px solid ${theme.color.blue5}`,
										borderRadius: '4px',
										width: '100%',
										opacity: 1,
									}}
								/>
							</Box>
						</Box>
					</Box>
					<Box>
						<Label
							required
							style={{
								fontWeight: '300px',
								fontSize: '16px',
								color: theme.color.accent2,
								width: '100%',
							}}
						>
							Description
						</Label>
						<Spacer size={8} />
						<Box>
							<TextArea
								type="text"
								name="description"
								onChange={e => {
									setFieldValue('description', e.target.value)
								}}
								value={values.description}
								required
								customStyles={{
									backgroundColor: '#FFFFFF',
								}}
							/>
						</Box>
					</Box>
					<Box
						row
						style={{ width: '100%' }}
						justifyContent="space-between"
					>
						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '400px',
									fontSize: '16px',
									color: theme.color.accent2,
								}}
							>
								URL
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values.url}
									name="url"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={touched.url && errors.url}
								/>
							</Box>
							<p>The youtube link should be in this format:</p>
							<p
								style={{
									fontSize: 12,
									color: theme.color.primary,
								}}
							>
								https://www.youtube.com/embed/
								<span style={{ fontWeight: 'bold' }}>
									&lt;video id&gt;
								</span>
								?rel=0&autoplay=0&showinfo=0&controls=0
							</p>
						</Box>

						<Box style={{ width: '48%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Thumbnail Image URL
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={values.thumbnailImageURL}
									name="thumbnailImageURL"
									type="text"
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched.thumbnailImageURL &&
										errors.thumbnailImageURL
									}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box alignItems="flex-end">
					<Box width={150}>
						<Button
							isLoading={isSubmitting}
							disabled={disableCTA}
							type="submit"
							primary
							label="Update"
							style={{
								paddingBottom: '50px',
								width: '100%',
							}}
						/>
					</Box>
				</Box>
			</form>
		</Box>
	)
}

export { NewsAndUpdates }
