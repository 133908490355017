import { getIn } from 'timm'

const lookUpConfig = key =>
	getIn(window._env_, [key]) || getIn(process.env, [key]) || ''

// All the config env variables should starts with REACT_APP_

const hostInReverseArray = window.location.host.split('.').reverse()

export const getCookieForDomain = () => {
	let domain = `${hostInReverseArray[2]}.${hostInReverseArray[1]}.${hostInReverseArray[0]}`
	if (hostInReverseArray[3]) {
		domain = `${hostInReverseArray[3]}.${domain}`
	}

	return domain
}

export const getIAMEndPoint = () =>
	`${lookUpConfig('REACT_APP_IAM_URL') || ''}/`
export const getCoreEndPoint = () =>
	`${lookUpConfig('REACT_APP_CORE_URL') || ''}/`
export const getPlantationEndPoint = () =>
	`${lookUpConfig('REACT_APP_PLANTATION_URL') || ''}/`

export const getGMAPAddressFetchUrl = pincode =>
	`https://maps.googleapis.com/maps/api/geocode/json?address=${pincode}&key=${lookUpConfig(
		'REACT_APP_GMAP_AUTH_TOKEN'
	) || '---'}`
export const getNotifierEndPoint = () =>
	`${lookUpConfig('REACT_APP_NOTIFIER_URL') || ''}/`
export const featureFlags = {
	enableLoginCaptcha:
		lookUpConfig('REACT_APP_FTRS_LOGIN_CAPTCHA') !== 'false',
}

export const getRecaptchaKey = () =>
	lookUpConfig('REACT_APP_RECAPTCHA_KEY') || ''

export const getAppVersion = () => lookUpConfig('REACT_APP_VERSION') || ''
export const getMapBoxAccessToken = () =>
	lookUpConfig('REACT_APP_MAPBOX_ACCESS_TOKEN') || ''

export const getNewsFeedEndPoint = () =>
	`${lookUpConfig('REACT_APP_NEWSFEED_URL') || ''}/`

export const getInsightsEndPoint = () =>
	`${lookUpConfig('REACT_APP_INSIGHTS_URL') || ''}/`

export const apiConfigAvailable =
	getIAMEndPoint().length > 1 &&
	getCoreEndPoint().length > 1 &&
	getPlantationEndPoint().length > 1 &&
	getNotifierEndPoint().length > 1
