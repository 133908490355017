import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { AdminDuc } from 'sales-app/modules/Admin/duc'
import { DibizOrgsDuc } from 'sales-app/modules/DibizOrgs/duc'
import { BoxShadowTable } from 'sales-app/utils/sharedComponents/BoxShadowTable'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { CTAWrapper } from 'sales-app/utils/shared'
import { Label } from 'ui-lib/components/Typography'
import { AppDuc } from 'sales-app/modules/App/duc'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { getColumnConfig } from '../components/columns'
import { titleData } from '../config'

const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const location = useSelector(DibizOrgsDuc.selectors.location)
	const { payload } = location
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const isLoading = useSelector(DibizOrgsDuc.selectors.getLoadingStatus)
	const listing = useSelector(DibizOrgsDuc.selectors.getOrgList)
	const pagination = useSelector(DibizOrgsDuc.selectors.getPaginationEntries)
	const totalDocument = getIn(pagination, ['total']) || 0
	const orgStatus = useSelector(DibizOrgsDuc.selectors.activeModule)

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'open_document': {
					if (meta.row) {
						dispatch(
							AdminDuc.creators.setActiveStatus(
								meta.row.status.state
							)
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.ADMIN$WDOCREFERENCE,
								{
									rootModule: 'company-information',
									action: 'view-companyInfo',
									documentReference: meta.row.id,
								}
							)
						)
					}

					break
				}
				case 'paginate': {
					const { filterParams } = meta
					const isSkip = false

					const { nextIndex } = filterParams

					const locationState = {
						payload,
						query: {
							...location.query,
							nextIndex,
							limit: 20,
						},
					}
					dispatch(
						DibizOrgsDuc.creators.fetchOrgList(
							isSkip,
							locationState
						)
					)
					break
				}

				default:
					break
			}
		},
		[dispatch, location.query, payload]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title={t(titleData[orgStatus])} />
			</Box>
			<Box>
				<BoxShadowTable
					isLoading={isLoading}
					size="large"
					scroll={!isMobile}
					tableSize={isMobile && 'small'}
					columnConfig={getColumnConfig({
						onChange: handleOnChange,
						t,
					})}
					rowData={listing}
				/>

				<CTAWrapper>
					<Label
						color="inherit"
						bold
						small
						style={{
							padding: '10px 4px',
						}}
					>
						{(listing || []).length > 0
							? `${t('common.ShowingListFor')}
							${(listing || []).length}
							  ${t('common.of')}
							  ${totalDocument}`
							: t('common.errorBlockMessage')}
					</Label>
					<Box
						style={{
							width: '75%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Button
							plain
							disabled={
								(listing || []).length === 0 ||
								listing.length === totalDocument
							}
							extendStyles={{
								background: '#F2F4FF',
								padding: '8px 24px',
								borderRadius: '24px',
								width: '170px',
								textAlign: 'center',
							}}
							label={t('common.showMore')}
							onClick={() =>
								handleOnChange('paginate', {
									filterParams: pagination,
								})
							}
						/>
					</Box>
				</CTAWrapper>
			</Box>
			<Box padding="25px 2px" style={{ maxWidth: 100 }}>
				<Button
					label={t('common.back')}
					action
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={LeftArrowIcon} />
						</ButtonIconWrapper>
					}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.DIBIZORGS,
								{
									action: orgStatus,
								}
							)
						)
					}}
				/>
			</Box>
		</Box>
	)
}

export default MainDashboard
