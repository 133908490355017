import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { isEmptyObject } from 'sales-app/utils/helpers'
import { PasswordInput } from 'ui-lib/components/Input'
import {
	Button,
	ButtonWithNoBorder,
	ButtonIconWrapper,
} from 'ui-lib/components/Button'
import { Text, Label } from 'ui-lib/components/Typography'
import { Box } from 'ui-lib/utils/Box'
import checkboxCircle from 'ui-lib/icons/circle-checkmark.svg'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AppDuc } from 'sales-app/modules/App/duc'
import { Spacer } from 'ui-lib/utils/Spacer'
import theme from 'ui-lib/utils/base-theme'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'

const Wrapper = styled.div(p => ({
	width: p.isMobile ? '100%' : '80%',
	height: '540px',
	maxWidth: '500px',
	position: 'relative',
	top: '10%',
	overflow: 'initial',
	display: 'flex',
	justifyContent: 'center',
	alignSelf: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	background: p.theme.color.white,
	zIndex: p.theme.zIndex.level1,
	padding: p.isMobile ? '4%' : '20px 32px',
}))

const Note = styled.div(p => ({
	...p.theme.fonts.medium,
	paddingBottom: '16px',
}))

const ResetPassword = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const { query = {} } = useSelector(AuthDuc.selectors.location)

	const { email, secret } = query

	const { status } = useSelector(AuthDuc.selectors.getResetPasswordState)
	const isSuccess = status === 'success'

	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.min(8, t('validation.passwordLength'))
			.required(t('validation.passwordRequired')),
		repeatPassword: yup
			.string()
			.oneOf([yup.ref('password'), null], t('validation.passwordMatch')),
	})

	const {
		handleSubmit,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues: {
			password: '',
			repeatPassword: '',
		},
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			const formValues = {
				..._values,
				email,
				secret,
			}
			dispatch(
				AuthDuc.creators.resetPassword(formValues, { setSubmitting })
			)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.repeatPassword

	return (
		<Box
			style={{ width: '100%', overflowY: 'auto', height: '100%' }}
			alignItems={!isMobile && 'flex-end'}
		>
			<Wrapper isMobile={isMobile}>
				<Box width="100%">
					{!isSuccess && (
						<Box>
							{!isMobile && (
								<Box
									row
									justifyContent="flex-start"
									style={{
										paddingBottom: '30px',
										paddingLeft: '0px',
									}}
								>
									<ButtonWithNoBorder
										customIcon={
											<ButtonIconWrapper
												lightBG
												style={{
													color: theme.color.blue5,
												}}
											>
												<Icon glyph={LeftArrowIcon} />
											</ButtonIconWrapper>
										}
										onClick={() => {
											dispatch(
												MainRouteDuc.creators.redirect(
													MainRouteDuc.types.AUTH,
													{
														action:
															'forgot-password',
													}
												)
											)
										}}
										extendStyles={{
											fontWeight: 'bold',
											fontSize: theme.fontSize.xl,
											textDecoration: 'none',
											color: theme.color.blue5,
											padding: '0px',
										}}
										label={t('common.back')}
									/>
								</Box>
							)}
							<Text
								style={{
									maxWidth: '500px',
									width: '100%',
									padding: '20px 0px',
									color: theme.color.accent2,
									lineHeight: '1.5em',
								}}
							>
								{t('login.resetPasswordNote')}
							</Text>
							<Spacer size={20} />

							<Box
								style={{
									width: '100%',
									maxHeight: '540px',
									maxWidth: '400px',
								}}
							>
								<form onSubmit={handleSubmit}>
									<Box
										style={{
											width: '100%',
										}}
									>
										<Label
											style={{
												fontWeight: 'bold',
												fontSize: theme.fontSize.xl,
												paddingBottom: '12px',
												color: theme.color.accent2,
											}}
										>
											{t('login.newPassword')}
										</Label>
										<PasswordInput
											name="password"
											value={values.password}
											type="password"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.password &&
												errors.password
											}
											extendStyles={{
												background:
													values.password.length === 0
														? `${theme.color.grey11} 0% 0% no-repeat padding-box`
														: `${theme.color.white} 0% 0% no-repeat padding-box`,
												boxShadow:
													values.password.length === 0
														? '0px 6px 16px #05050707'
														: `0px 2px 8px ${theme.color.blue6}`,
												border:
													values.password.length === 0
														? `1px solid ${theme.color.grey4}`
														: `1px solid ${theme.color.blue5}`,
												borderRadius: '4px',
												opacity: 1,
											}}
										/>
										<Label
											style={{
												fontWeight: 'bold',
												fontSize: theme.fontSize.xl,
												paddingBottom: '12px',
												color: theme.color.accent2,
											}}
										>
											{t('login.confirmPassword')}
										</Label>
										<PasswordInput
											name="repeatPassword"
											value={values.repeatPassword}
											type="password"
											onChange={handleChange}
											onBlur={handleBlur}
											error={
												touched.repeatPassword &&
												errors.repeatPassword
											}
											extendStyles={{
												background:
													values.repeatPassword
														.length === 0
														? `${theme.color.grey11} 0% 0% no-repeat padding-box`
														: `${theme.color.white} 0% 0% no-repeat padding-box`,
												boxShadow:
													values.repeatPassword
														.length === 0
														? '0px 6px 16px #05050707'
														: `0px 2px 8px ${theme.color.blue6}`,
												border:
													values.repeatPassword
														.length === 0
														? `1px solid ${theme.color.grey4}`
														: `1px solid ${theme.color.blue5}`,
												borderRadius: '4px',
												opacity: 1,
											}}
										/>
										<Spacer size={15} />

										<Button
											isLoading={isSubmitting}
											disabled={disableCTA}
											type="submit"
											primary
											label={t('login.resetPassword')}
										/>
									</Box>
								</form>
							</Box>
						</Box>
					)}
					<Spacer size={10} />
					{isSuccess && (
						<Box center>
							<IconWrapper size={50} style={{ marginBottom: 30 }}>
								<Icon glyph={checkboxCircle} />
							</IconWrapper>
							<Note>{t('login.awesome')}</Note>
							<Note>{t('login.passwordChangeMsg')}</Note>

							<Box width={300}>
								<Button
									onClick={() =>
										dispatch(
											MainRouteDuc.creators.redirect(
												MainRouteDuc.types.AUTH,
												{ action: 'sign-in' }
											)
										)
									}
									primary
									label={t('login.done')}
								/>
							</Box>
						</Box>
					)}
				</Box>
			</Wrapper>
		</Box>
	)
}

export default ResetPassword
