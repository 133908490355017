import React, { useState } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Modal } from 'ui-lib/components/Modal'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Card } from 'ui-lib/components/Card'
import { Input } from 'ui-lib/components/Input'
import { Icon } from 'ui-lib/icons/components/Icon'
import { ButtonIconWrapper, Button } from 'ui-lib/components/Button'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { H3, SmallText, P } from 'ui-lib/components/Typography'
import { Spacer } from 'ui-lib/utils/Spacer'
import SettingsIcon from 'ui-lib/icons/settings.svg'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { AppDuc } from 'sales-app/modules/App/duc'
import { SettingsDuc } from 'sales-app/modules/Settings/duc'
import { isEmptyObject } from 'sales-app/utils/helpers'
import { useFormik } from 'formik'
import * as yup from 'yup'
import theme from 'ui-lib/utils/base-theme'

const ChangePassword = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [confModal, showConfModal] = useState(false)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.settings'),
			name: 'settings',
			isActive: true,
		},
		{
			label: t('changePassword.pswdUpdate'),
			name: 'Update password',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DIBIZORGS, {
					action: 'newRequest',
				})
			)
		}
		if (target === 'settings') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.SETTINGS)
			)
		}
	}

	const validationSchema = yup.object().shape({
		currentPassword: yup.string().required(t('validation.currentPwdReq')),
		password: yup
			.string()
			.min(8, t('validation.passwordLength'))
			.required(t('validation.passwordRequired')),
		repeatPassword: yup
			.string()
			.oneOf([yup.ref('password'), null], t('validation.passwordMatch')),
	})

	const {
		handleSubmit,
		values,
		submitForm,
		handleChange,
		handleBlur,
		touched,
		errors,
	} = useFormik({
		initialValues: {
			currentPassword: '',
			password: '',
			repeatPassword: '',
		},
		validationSchema,
		onSubmit: _values => {
			dispatch(
				SettingsDuc.creators.changePassword(
					_values,
					t('changePassword.successToast')
				)
			)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.repeatPassword

	return (
		<>
			{confModal && (
				<Modal
					forceCloseviaButton
					heading={t('changePassword.passwordSettings')}
					show={confModal}
					body={
						<P small bold>
							{t('changePassword.passwordModalText')}
						</P>
					}
					closelabel={t('common.back')}
					confirmlabel={t('common.yes')}
					size="large"
					onClose={() => {
						showConfModal(false)
					}}
					onConfirm={() => {
						showConfModal(false)
						submitForm()
					}}
				/>
			)}

			<Box padding={8} width="100%" height="100%">
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Title
					title={t('changePassword.title')}
					icon={SettingsIcon}
					note={t('changePassword.note')}
				/>

				<Card
					width={isMobile ? '320px' : '100%'}
					height="440px"
					textAlign="left"
				>
					<Box
						padding={8}
						style={{
							backgroundColor: theme.color.accent3,
						}}
					>
						<H3
							bold
							color={theme.color.accent2}
							style={{
								position: 'relative',
								right: '-25px',
							}}
						>
							{t('changePassword.password')}
						</H3>
					</Box>
					<Box style={{ padding: '8px 35px 30px 35px' }}>
						<SmallText fontSize={theme.fontSize.s}>
							{t('changePassword.subHeading')}
						</SmallText>
						<Spacer size={8} />
						<form onSubmit={handleSubmit}>
							<Box width={500} id="inputField-currentPassword">
								<Input
									label={t('changePassword.currentPassword')}
									value={values.currentPassword}
									name="currentPassword"
									type="password"
									onChange={handleChange}
									onBlur={handleBlur}
									error={
										touched.currentPassword &&
										errors.currentPassword
									}
								/>
							</Box>
							<Spacer size={12} />
							<Box id="inputField-password">
								<Box width={500}>
									<Input
										label={t('changePassword.newPassword')}
										value={values.password}
										name="password"
										type="password"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.password && errors.password
										}
									/>
								</Box>
								<Box width={500}>
									<Input
										label={t(
											'changePassword.retypeNewPassword'
										)}
										value={values.repeatPassword}
										name="repeatPassword"
										type="password"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.repeatPassword &&
											errors.repeatPassword
										}
									/>
								</Box>
							</Box>
							<Box alignItems="flex-end">
								<Box width={150} id="update-password">
									<Button
										disabled={disableCTA}
										onClick={() => {
											showConfModal(true)
										}}
										primary
										label={t('common.update')}
									/>
								</Box>
							</Box>
						</form>
					</Box>
				</Card>
				<Box padding="25px 0" style={{ maxWidth: 100 }}>
					<Button
						label={t('common.back')}
						action
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.SETTINGS
								)
							)
						}
					/>
				</Box>
			</Box>
		</>
	)
}

export { ChangePassword }
