import {
	all,
	takeLatest,
	takeEvery,
	take,
	put,
	call,
	select,
	race,
} from 'redux-saga/effects'
import LogHelper from 'sales-app/utils/logger'
import request from 'sales-app/utils/request'
import {
	getIAMEndPoint,
	getNotifierEndPoint,
} from 'sales-app/utils/sharedConfig'
import { CookieDuc, AUTH_COOKIE_KEYS } from 'sales-app/modules/App/cookieDuc'
import { getIn } from 'timm'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { AppDuc } from 'sales-app/modules/App/duc'
import { Storage } from 'sales-app/utils/storage'
import { getRelativeUrlFromAbsoluteUrl } from 'sales-app/utils/helpers'
import {
	featureAccessBasedOnType,
	getActiveUserDashboardAction,
} from 'sales-app/routes/base'
import { checkIfAtleastOneFeatureAllowed } from 'sales-app/modules/Auth/helpers'
import { Toast } from 'ui-lib/components/Toast'
import i18n from 'i18next'
import { AuthDuc } from './duc'

const logger = LogHelper('client:authSaga')

export function* fetchClientID(action = {}) {
	const { withRetry = false } = action
	const requestUrl = `${getIAMEndPoint()}clients/token`

	try {
		const response = withRetry
			? yield CallWithRefreshCheck(requestUrl)
			: yield call(request, requestUrl)

		const clientID = getIn(response, ['data', 'clientID'])

		if (clientID) {
			yield put(
				CookieDuc.creators.setCookie({
					cookieName: AUTH_COOKIE_KEYS.CLIENT_ID,
					cookieValue: clientID,
					storage: 'C',
				})
			)

			return clientID
		}

		return null
	} catch (err) {
		logger.error(err)
		const { message } = err
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)

		return null
	}
}

const profileFetchStatus = {
	status: false,
	set writeStatus(status) {
		logger.log('Profile Status: ', status)
		this.status = status
	},
}

export function* GetUserProfile(action) {
	const { clientID, returnValue, skipLoading } = action || {}

	try {
		if (profileFetchStatus.status) return {}

		profileFetchStatus.writeStatus = true
		if (!skipLoading)
			yield put(AuthDuc.creators.setProfileLoadingStatus(true))
		let targetClientID =
			clientID || Storage.get({ name: AUTH_COOKIE_KEYS.CLIENT_ID })
		if (!targetClientID) {
			// try and fetch client id from api state
			const clientIDFromAPI = yield fetchClientID({ withRetry: true })
			if (!clientIDFromAPI) {
				throw new Error('Unable to fetch the user details')
			}

			targetClientID = clientIDFromAPI
		}

		const requestUrl = `${getIAMEndPoint()}clients/users/${targetClientID}`

		const { data = {} } = yield CallWithRefreshCheck(requestUrl)

		const language = getIn(data, ['meta', 'language'])

		i18n.changeLanguage(language || 'en')

		yield put(
			CookieDuc.creators.setCookie({
				cookieName: 'I18N_LANGUAGE',
				cookieValue: language,
				storage: 'L',
			})
		)

		// fetch the user roles
		yield fetchUserRoles({ skipLoading: true })

		// fetch the org details for this fetch
		const orgIDFromProfile = getIn(data, ['organization', 'id'])
		if (orgIDFromProfile) {
			const orgDetails = yield fetchCurrentOrgsDetail({
				orgID: orgIDFromProfile,
				returnResponse: true,
			})
			yield put(AuthDuc.creators.setCurrentOrg(orgDetails))
		}

		profileFetchStatus.writeStatus = false
		yield put(AuthDuc.creators.setProfileLoadingStatus(false))
		if (returnValue) return data

		yield put(AuthDuc.creators.setUserProfile(data))

		return data
	} catch (e) {
		logger.log(e)

		const {
			message = 'Unable to fetch user profile. Please login again.',
		} = e

		yield put(AuthDuc.creators.setProfileErrorStatus(true))

		yield SignOutUser({
			redirectTo: window.location.href,
		})

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(AuthDuc.creators.setProfileLoadingStatus(false))
		profileFetchStatus.writeStatus = false

		return {}
	}
}

function* fetchUserRoles(action) {
	const { skipLoading } = action || {}
	try {
		if (!skipLoading)
			yield put(AppDuc.creators.showGlobalLoader('fetch-roles'))

		const requestUrl = `${getIAMEndPoint()}clients/_/computed-access`
		// fetch and set the roles of the user
		const { data: roles } = yield CallWithRefreshCheck(requestUrl)
		yield put(AuthDuc.creators.setActiveUserRoles(roles))
	} catch (e) {
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					'Unable to get the user permissions. Please try again later.',
			})
		)
		logger.log(e)
		yield SignOutUser({
			redirectTo: window.location.href,
		})
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-roles'))
	}
}

function* SignInUser(action) {
	const {
		credentials: { email, password },
		helpers: { setSubmitting },
	} = action

	try {
		const requestUrl = `${getIAMEndPoint()}clients/authenticate`
		const creds = {
			loginID: email,
			password,
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(creds),
		}
		const { data, status } = yield call(request, requestUrl, options)

		if (status === 202) {
			yield put(
				MainRouteDuc.creators.redirect(
					MainRouteDuc.types.AUTH,
					{
						action: 'two-step-verification',
					},
					{
						email,
					}
				)
			)
		} else {
			yield put(AuthDuc.creators.onSuccessfulLogin(data))
		}
	} catch (e) {
		setSubmitting(false)

		logger.error(e)

		const { message } = e
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* SignOutUser(action = {}) {
	const { redirectTo } = action
	const targetRedirect = getRelativeUrlFromAbsoluteUrl(redirectTo)
	try {
		const requestUrl = `${getIAMEndPoint()}clients/logout`
		const options = {
			method: 'DELETE',
		}

		yield call(request, requestUrl, options)

		yield put(AuthDuc.creators.flushState())
		yield all(CookieDuc.options.helpers.deleteAllTokens().map(c => put(c)))

		window.location = `/auth/sign-in${
			targetRedirect ? `?redirect_to=${targetRedirect}` : ''
		}`
	} catch (e) {
		logger.error(e)
	}
}

const refreshingToken = {
	status: false,
	set writeStatus(status) {
		logger.log('Refreshing Status: ', status)
		this.status = status
	},
}

/** Call this function when you need to invoke api call with refresh token check */
export function* CallWithRefreshCheck(
	url,
	options,
	type = 'json',
	timeout = 300000
) {
	try {
		if (refreshingToken.status) {
			// wait for the token to come and then retry

			const { success } = yield race({
				success: take(AuthDuc.creators.successRefreshToken().type),
				fail: take(AuthDuc.creators.errorRefreshToken().type),
			})

			if (success) {
				const response = yield call(
					request,
					url,
					options,
					type,
					timeout
				)

				return response
			}

			return {}
		}

		let response = {}

		try {
			response = yield call(request, url, options, type, timeout)
		} catch (e) {
			if (e.message === 'Failed to Fetch') {
				yield put(
					AppDuc.creators.showToast({
						messageType: 'error',
						message:
							'Unable to authorize user. Please login again to proceed.',
					})
				)

				// unable to fetch the call, logout the user with toast.
				yield SignOutUser({
					redirectTo: window.location.href,
				})
			} else {
				logger.error(e)
				const { message } = e
				yield put(
					AppDuc.creators.showToast({
						messageType: 'error',
						message,
					})
				)
			}

			return response || {}
		}

		if (response.retryWithRefreshToken === true) {
			// refresh the tokens
			yield refreshTokenHandler()

			return yield CallWithRefreshCheck(url, options, type, timeout)
		}

		return response || {}
	} catch (e) {
		// proxy the error back
		throw e
	}
}

function* refreshTokenHandler() {
	try {
		// get tokens from the store
		const activeTokens = yield select(
			CookieDuc.selectors.getActiveAuthTokens
		)

		const accessTokenExpired = !activeTokens.includes(
			AUTH_COOKIE_KEYS.ACCESS_TOKEN
		)

		const canRetry =
			activeTokens.includes(AUTH_COOKIE_KEYS.REFRESH_TOKEN) &&
			activeTokens.includes(AUTH_COOKIE_KEYS.ID_TOKEN)

		if (canRetry && accessTokenExpired) {
			const requestUrl = `${getIAMEndPoint()}clients/tokens`
			const options = {
				method: 'PUT',
			}

			const response = yield call(request, requestUrl, options)

			if (response.retryWithRefreshToken) {
				throw new Error('Unable to refresh the token')
			}

			const { data = {} } = response
			// set the user expiry into the store and sync up.
			yield all(
				CookieDuc.options.helpers.setExpiryTokens(data).map(c => put(c))
			)

			// we have synced the new tokens, now sync state
			yield put(AuthDuc.creators.successRefreshToken())
		} else if (!canRetry) {
			throw new Error('User is not authorized. Please sign in again.')
		}
	} catch (e) {
		logger.log(e)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					'You have been logged out. Please log in again to continue.',
			})
		)

		// show alert and throw to login screen.
		yield put(AuthDuc.creators.errorRefreshToken())

		yield SignOutUser({
			redirectTo: window.location.href,
		})
	}
}

function* forgotPassword({ email }) {
	try {
		yield put(AuthDuc.creators.handleForgotPasswordLoading(true))
		const requestUrl = `${getIAMEndPoint()}clients/users/password/reset`

		const values = {
			email,
			path: 'auth/reset-password',
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}

		const { data } = yield call(request, requestUrl, options)

		if (data) {
			yield put(AuthDuc.creators.handleForgotPasswordLoading(false))
			yield put(AuthDuc.creators.switchToSuccessOnForgotPassword(data))
		} else {
			throw new Error('Unable to fetch details')
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(AuthDuc.creators.handleForgotPasswordLoading(false))

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export function* fetchCurrentOrgsDetail(action) {
	const { orgID, returnResponse, skipLoading } = action

	// if there were no orgId
	try {
		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const { data } = yield call(request, orgDetailsUrl)

		if (returnResponse) return data
	} catch (e) {
		if (returnResponse) return []
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message: message || 'Unable to fetch org lists',
			})
		)
		if (!skipLoading)
			yield put(AuthDuc.creators.handleOrgFetchStatuses(false, true))
	}
}

export function* getOrgIDFromLoggedUser() {
	try {
		let orgID = yield select(AuthDuc.selectors.getUserOrgId)

		if (!orgID) {
			let profile = {}
			if (profileFetchStatus.status) {
				// check if already fetching, if yes, wait for it to arrive,
				const { success } = yield race({
					success: take(AuthDuc.creators.setUserProfile().type),
					fail: take(AuthDuc.creators.setProfileErrorStatus().type),
				})

				if (success) {
					profile = yield select(AuthDuc.selectors.getUserProfile)
				}
			} else {
				profile = yield GetUserProfile()
			}

			orgID = getIn(profile, ['organization', 'id'])

			if (!orgID) {
				yield SignOutUser({
					redirectTo: window.location.href,
				})
				throw new Error(
					'You do not seem to be associated to an Organization. Please contact admin'
				)
			}
		}

		return orgID
	} catch (e) {
		logger.log(e)
		throw e
	}
}

/**
 * This handler is always active on route changes and ensures the user doesn't land up
 * some how
 */
function* fetchUserRolesAndCheckActiveRoute(action) {
	let { routeActionType } = action
	// if there were no orgId
	try {
		// if user profile is being fetch, wait up
		if (profileFetchStatus.status) {
			// check if already fetching, if yes, wait for it to arrive,
			const { fail } = yield race({
				success: take(AuthDuc.creators.setUserProfile().type),
				fail: take(AuthDuc.creators.setProfileErrorStatus().type),
			})

			if (fail) {
				throw new Error('Unable to fetch roles')
			}
		}

		if (!routeActionType) {
			const locationState = yield select(AuthDuc.creators.location)
			routeActionType = locationState.type
		}
		// fetch the last time stamp
		const { timeStamp } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		if (!timeStamp) {
			// fresh fetch

			yield fetchUserRoles({ skipLoading: true })
		}

		const rules = featureAccessBasedOnType[routeActionType]

		// check if user is allowed
		const isAllowed = yield checkIfAtleastOneFeatureAllowed(rules)

		// redirect to proper dashboard based on users allowed roles
		const { allowed, timeStamp: lastTimeStamp } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		if (!isAllowed) {
			// User does not have access to main dashboard, which means we need to find alternative
			// dashboard
			const targetAction = getActiveUserDashboardAction(allowed)
			// Ensure its not the main dashboard, else it would go in loop of no accesses
			// this assumes that atleast one dashboard is allowed.
			if (targetAction.type && targetAction.type !== routeActionType) {
				// send them to dashboard
				yield put(targetAction)

				return
			}
			logger.log(
				'Not Allowed - Route:',
				routeActionType,
				' Rules:',
				rules
			)
			if (routeActionType !== MainRouteDuc.types.Auth) {
				yield put(MainRouteDuc.creators.switch401())
			}

			return
		}

		const has10minPassed =
			(new Date().getTime() - new Date(lastTimeStamp).getTime()) / 1000 >
			600

		// check if we should flush new rules now
		if (!timeStamp || has10minPassed) {
			// fresh fetch

			yield fetchUserRoles({
				skipLoading: true,
			})
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message:
					message ||
					'Unable to update shipping address. Please try again later.',
			})
		)
	}
}

function* fetchNotifications(limit = 10, status = 'sent') {
	try {
		// Added this condition because limit is receiving some object value from somewhere
		let max = 10
		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(limit)) {
			max = limit
		}
		const requestUrl = `${getNotifierEndPoint()}clients/-/notifications?limit=${max}&sort=desc(createdAt)&status=${status}`
		const data = yield CallWithRefreshCheck(requestUrl)
		const notifications = getIn(data, ['data'])
		yield put(AuthDuc.creators.setNotifications(notifications))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* singleNotificationUpdateStatus(action) {
	try {
		const { id, status } = action
		const value = {
			status,
		}
		const requestUrl = `${getNotifierEndPoint()}clients/-/notifications/${id}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(value),
		}
		yield call(request, requestUrl, options)
		yield put(AuthDuc.creators.fetchNotifications())
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* resetPassword(action) {
	const {
		values,
		helpers: { setSubmitting },
	} = action
	try {
		yield put(AuthDuc.creators.handleResetPasswordLoading(true))
		const requestUrl = `${getIAMEndPoint()}clients/users/password/reset`

		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}

		const { data } = yield call(request, requestUrl, options)
		if (data) {
			yield put(AuthDuc.creators.handleResetPasswordLoading(false))
			yield put(AuthDuc.creators.switchToSuccessOnResetPassword(data))
		}
		setSubmitting(false)
	} catch (err) {
		setSubmitting(false)

		const { message } = err

		logger.log(err)

		yield put(AuthDuc.creators.handleResetPasswordLoading(false))
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* verifyOtp(action) {
	const {
		currentUserEmail,
		secret,
		helpers: { setSubmitting },
	} = action
	try {
		const requestUrl = `${getIAMEndPoint()}clients/authenticate/otp`
		const creds = {
			loginID: currentUserEmail,
			secret,
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(creds),
		}
		const { data } = yield call(request, requestUrl, options)

		yield put(AuthDuc.creators.onSuccessfulLogin(data))
	} catch (e) {
		if (setSubmitting) {
			setSubmitting(false)
		}
		const { message } = e
		logger.error(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* onSuccessfulLogin(action) {
	const { data } = action
	try {
		const clientID = yield fetchClientID()
		const [, orgID] = clientID.split('@')
		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const { data: orgDetails } = yield call(request, orgDetailsUrl)
		const orgCategory = getIn(orgDetails, ['categories', '0', 'name'])
		if (
			orgCategory === 'dibiz-backoffice' ||
			orgCategory === 'dibiz-backoffice-admin'
		) {
			// set the user expiry into the store and sync up.
			yield all(
				CookieDuc.options.helpers.setExpiryTokens(data).map(c => put(c))
			)
			yield put(AuthDuc.creators.fetchUserProfile(clientID))
			yield put(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DIBIZORGS, {
					action: 'newRequest',
				})
			)
		} else {
			yield put(AuthDuc.creators.signOutUser())
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(AuthDuc.creators.handleResetPasswordLoading(false))
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* initiateOTP(action) {
	try {
		const { currentUserEmail, successToast } = action
		const values = {
			loginID: currentUserEmail,
		}

		yield put(AppDuc.creators.showGlobalLoader('verify-email'))

		const requestOtpUrl = `${getIAMEndPoint()}clients/authenticate/otp`
		const otpOptions = {
			method: 'POST',
			body: JSON.stringify(values),
		}

		yield call(request, requestOtpUrl, otpOptions)

		yield Toast({
			type: 'success',
			message: successToast,
		})
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('verify-email'))
	}
}

export default function* AuthSaga() {
	try {
		yield all([
			takeEvery(AuthDuc.creators.fetchUserProfile().type, GetUserProfile),
			takeEvery(
				AuthDuc.creators.fetchNotifications().type,
				fetchNotifications
			),
			takeLatest(
				AuthDuc.creators.singleNotificationUpdateStatus().type,
				singleNotificationUpdateStatus
			),
			takeLatest(
				AuthDuc.creators.fetchLoggedInUserOrg().type,
				getOrgIDFromLoggedUser
			),
			takeLatest(AuthDuc.creators.loginUser().type, SignInUser),
			takeLatest(AuthDuc.creators.signOutUser().type, SignOutUser),
			takeLatest(
				AuthDuc.creators.initiateForgotPassword().type,
				forgotPassword
			),
			takeEvery(
				AuthDuc.creators.initiateRefreshToken().type,
				refreshTokenHandler
			),
			takeLatest(
				AuthDuc.creators.validateUserRouteChange().type,
				fetchUserRolesAndCheckActiveRoute
			),
			takeLatest(AuthDuc.creators.resetPassword().type, resetPassword),
			takeLatest(AuthDuc.creators.verifyOtp().type, verifyOtp),
			takeLatest(
				AuthDuc.creators.onSuccessfulLogin().type,
				onSuccessfulLogin
			),
			takeLatest(AuthDuc.creators.initiateOTP().type, initiateOTP),
		])
	} catch (e) {
		logger.error(e)
	}
}
