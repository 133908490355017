import Duck from 'extensible-duck'
import { getIn, setIn, merge } from 'timm'
import { createSelector } from 'reselect'
import { isEmptyObject, getKeysByRegex, pick } from 'sales-app/utils/helpers'

const initialState = {
	clientID: null,
	actor: [],
	profile: {},
	roles: {
		allowed: [],
		denied: [],
		additionalMeta: {},
		timeStamp: null,
	},
	profileStatus: {
		isFetching: false,
		isError: false,
	},
	currentOrganization: {},
	organizations: {},
	organizationsStatus: {
		isFetching: false,
		isError: false,
	},
	signIn: {
		email: '',
	},
	forgot: {
		loading: false,
		status: 'initiate', // can be initiate | success
		message: '',
	},
	resetPassword: {
		loading: false,
		status: 'initiate', // can be initiate or success
	},
	notifications: {},
}

export const AuthDuc = new Duck({
	namespace: 'auth',
	store: 'global',
	types: [
		'SET_CLIENT_ID',
		'SET_USER_PROFILE',
		'SET_CURRENT_USER_ORGANIZATION',
		'SIGN_IN',
		'SIGN_OUT',
		'FORGOT_PASSWORD',
		'GET_USER_PROFILE',
		'REFRESH_TOKEN',
		'REFRESH_TOKEN_SUCCESS',
		'REFRESH_TOKEN_FAILURE',
		'FORGOT_PASSWORD_LOADING',
		'FORGOT_PASSWORD_SUCCESS',
		'FLUSH_FORGOT_PASSWORD_STATE',
		'GET_USER_PROFILE_FAILURE',
		'GET_USER_PROFILE_LOADING',
		'UPDATE_ORG_DETAILS',
		'UPDATE_ORG_FETCH_STATUS',
		'FETCH_LOGGED_IN_USER_ORG',
		'SET_USER_ROLES',
		'CHECK_USER_ROUTE_CHANGE',
		'FLUSH_STATE',
		'FETCH_NOTIFICATIONS',
		'SET_NOTIFICATIONS',
		'UPDATE_STATUS_SINGLE_NOTIFICATION',
		'RESET_PASSWORD',
		'RESET_PASSWORD_LOADING',
		'RESET_PASSWORD_SUCCESS',
		'FLUSH_RESET_PASSWORD_STATE',
		'VERIFY_OTP',
		'ON_SUCCESSFUL_LOGIN',
		'INITIATE_OTP',
	],
	initialState,
	helpers: {
		/**
		 * A helper to get the flags per feature specified. The response will usually be variables tha
		 * @param {*} type feature name associated in the iam ex. delivery-order (from fe.tdm.delivery-order-c)
		 * @param {*} roleFlags key value pair that can be used as reference. Example: const ROLE_ACCESSES
		 *    {
		 *     canReadDeliveryOrder: 'fe.tdm.delivery-order.r',
		 *     canControlDeliveryOrder: 'fe.tdm.delivery-order.c'
		 *   }
		 */
		getAccessFlagsPerFeature(type = '*', roleFlags = {}) {
			return pick(
				roleFlags,
				getKeysByRegex(roleFlags, type.replace(/-/g, ''))
			)
		},
		getAllowedAccesses(
			allowedRoles = [],
			deniedRoles = [],
			referenceFlags = {}
		) {
			return Object.keys(referenceFlags).reduce((agg, key) => {
				const aggregator = agg

				const feature = referenceFlags[key]
				aggregator[key] =
					!deniedRoles.includes(feature) &&
					allowedRoles.includes(feature)

				return aggregator
			}, {})
		},
	},
	reducer: (state, action, duck) => {
		if (!action) return state // will be used for ssr initial state

		switch (action.type) {
			case duck.types.FLUSH_STATE: {
				return initialState
			}

			case duck.types.SIGN_IN: {
				let newState = setIn(state, ['profileStatus'], {
					isFetching: false,
					isError: false,
				})

				newState = setIn(newState, ['profile'], {})

				return setIn(newState, ['clientID'], null)
			}

			case duck.types.SET_CLIENT_ID: {
				return setIn(state, ['clientID'], action.clientID)
			}

			case duck.types.SET_USER_PROFILE: {
				return setIn(state, ['profile'], action.profile)
			}

			case duck.types.FORGOT_PASSWORD_SUCCESS: {
				const newState = setIn(state, ['forgot', 'status'], 'success')

				return setIn(
					newState,
					['forgot', 'message'],
					action.message || ''
				)
			}

			case duck.types.FLUSH_FORGOT_PASSWORD_STATE: {
				return setIn(state, ['forgot'], initialState.forgot)
			}

			case duck.types.FORGOT_PASSWORD_LOADING: {
				return setIn(
					state,
					['forgot', 'loading'],
					action.loading || false
				)
			}

			case duck.types.RESET_PASSWORD_LOADING: {
				return setIn(
					state,
					['resetPassword', 'loading'],
					action.loading || false
				)
			}

			case duck.types.RESET_PASSWORD_SUCCESS: {
				const newState = setIn(
					state,
					['resetPassword', 'status'],
					'success'
				)

				return setIn(
					newState,
					['resetPassword', 'message'],
					action.message || ''
				)
			}

			case duck.types.FLUSH_RESET_PASSWORD_STATE: {
				return setIn(
					state,
					['resetPassword'],
					initialState.resetPassword
				)
			}

			case duck.types.GET_USER_PROFILE_LOADING: {
				const nextStatus = action.status || false

				return setIn(state, ['profileStatus'], {
					isFetching: nextStatus,
					isError: false,
				})
			}

			case duck.types.GET_USER_PROFILE_FAILURE: {
				const nextStatus = action.status || false
				const currentLoadingStatus =
					getIn(state, ['profileStatus', 'isFetching']) || false

				return setIn(state, ['profileStatus'], {
					isFetching: nextStatus ? false : currentLoadingStatus,
					isError: action.status,
				})
			}

			case duck.types.UPDATE_ORG_DETAILS: {
				const { orgDetailMap } = action

				const currentOrgs = getIn(state, ['organizations'] || {})

				return setIn(
					state,
					['organizations'],
					merge(currentOrgs, orgDetailMap)
				)
			}

			case duck.types.UPDATE_ORG_FETCH_STATUS: {
				const { isFetching, hasError } = action

				return setIn(state, ['organizationsStatus'], {
					isFetching,
					isError: hasError,
				})
			}

			case duck.types.SET_CURRENT_USER_ORGANIZATION: {
				const { orgDetails = {} } = action

				return setIn(state, ['currentOrganization'], orgDetails)
			}

			case duck.types.SET_USER_ROLES: {
				const { roles = {} } = action

				const { allowedFeatures = {}, deniedFeatures = {} } = roles

				let newState = setIn(
					state,
					['roles', 'allowed'],
					Object.keys(allowedFeatures)
				)

				newState = setIn(
					newState,
					['roles', 'denied'],
					Object.keys(deniedFeatures)
				)

				// get all the features with their filters and apply them
				const allFeatures = merge(deniedFeatures, allowedFeatures)
				const filters = Object.keys(allFeatures).reduce((agg, key) => {
					const aggregator = agg

					let dataFilters = getIn(allFeatures, [key, 'dataFilter'])
					if (dataFilters) {
						dataFilters = Array.isArray(dataFilters)
							? dataFilters
							: [dataFilters]
						aggregator[key] = dataFilters.reduce((_agg, data) => {
							if (!isEmptyObject(data)) return merge(_agg, data)

							return _agg
						}, {})
					}

					return aggregator
				}, {})

				newState = setIn(
					newState,
					['roles', 'timeStamp'],
					new Date().toISOString()
				)

				return setIn(newState, ['roles', 'additionalMeta'], filters)
			}

			case duck.types.SET_NOTIFICATIONS: {
				return setIn(state, ['notifications'], action.notifications)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		userSignInEmail: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth =>
				getIn(auth, ['signIn', 'email'])
			)
		),
		getClientID: state => getIn(state, ['app', 'cookies', 'clientID']),
		getUserProfile: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['profile']) || {}
			)
		),
		getForgotPasswordState: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['forgot']) || {}
			)
		),
		getResetPasswordState: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['resetPassword']) || {}
			)
		),
		getProfileFetchStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['profileStatus']) || {}
			)
		),
		getUserOrgId: new Duck.Selector(
			selectors =>
				createSelector(selectors.getUserProfile, profile =>
					getIn(profile, ['organization', 'id'])
				) || null
		),
		getAvailableOrgs: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => auth.organizations || {})
		),
		getTargetOrgsOfUser: new Duck.Selector(selectors =>
			createSelector(
				selectors.getAvailableOrgs,
				selectors.getUserOrgId,
				(orgs = {}, currentOrgId) =>
					Object.values(orgs).filter(org => org.id !== currentOrgId)
			)
		),
		getOrganizationFetchStatus: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => getIn(auth, ['organizationsStatus']) || {}
			)
		),
		getCurrentOrganization: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth =>
				getIn(auth, ['currentOrganization'])
			)
		),
		checkVerified: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth =>
				getIn(auth, ['currentOrganization', 'status', 'state'])
			)
		),
		getOrgAssets: new Duck.Selector(selectors =>
			createSelector(selectors.auth, auth => getIn(auth, ['orgAssets']))
		),
		getOrganizationByID: new Duck.Selector(selectors =>
			createSelector(
				selectors.getAvailableOrgs,
				(_, targetOrgId) => targetOrgId,
				(allOrgs = {}, targetOrgId) => allOrgs[targetOrgId] || {}
			)
		),
		getCurrentUserRoles: new Duck.Selector(selectors =>
			createSelector(
				selectors.auth,
				auth => auth.roles || initialState.roles
			)
		),
		getActor: state => getIn(state, ['auth', 'actor']) || [],
		getNotifications: state => getIn(state, ['auth', 'notifications']),
	},
	creators: duck => ({
		flushState: () => ({
			type: duck.types.FLUSH_STATE,
		}),
		loginUser: (credentials, helpers) => ({
			type: duck.types.SIGN_IN,
			credentials,
			helpers,
		}),
		setClientID: clientID => ({
			type: duck.types.SET_CLIENT_ID,
			clientID,
		}),
		setUserProfile: profile => ({
			type: duck.types.SET_USER_PROFILE,
			profile,
		}),
		fetchUserProfile: clientID => ({
			type: duck.types.GET_USER_PROFILE,
			clientID,
		}),
		fetchLoggedInUserOrg: () => ({
			type: duck.types.FETCH_LOGGED_IN_USER_ORG,
		}),
		initiateRefreshToken: () => ({
			type: duck.types.REFRESH_TOKEN,
		}),
		errorRefreshToken: () => ({
			type: duck.types.REFRESH_TOKEN_FAILURE,
		}),
		successRefreshToken: () => ({
			type: duck.types.REFRESH_TOKEN_SUCCESS,
		}),
		switchToSuccessOnForgotPassword: message => ({
			type: duck.types.FORGOT_PASSWORD_SUCCESS,
			message,
		}),
		flushForgotPasswordState: () => ({
			type: duck.types.FLUSH_FORGOT_PASSWORD_STATE,
		}),
		handleForgotPasswordLoading: loading => ({
			type: duck.types.FORGOT_PASSWORD_LOADING,
			loading,
		}),
		initiateForgotPassword: email => ({
			type: duck.types.FORGOT_PASSWORD,
			email,
		}),
		setProfileErrorStatus: status => ({
			type: duck.types.GET_USER_PROFILE_FAILURE,
			status,
		}),
		setProfileLoadingStatus: status => ({
			type: duck.types.GET_USER_PROFILE_LOADING,
			status,
		}),
		signOutUser: () => ({
			type: duck.types.SIGN_OUT,
		}),
		updateOrgDetails: orgDetailMap => ({
			type: duck.types.UPDATE_ORG_DETAILS,
			orgDetailMap,
		}),
		handleOrgFetchStatuses: (isFetching = false, hasError = false) => ({
			type: duck.types.UPDATE_ORG_FETCH_STATUS,
			isFetching,
			hasError,
		}),
		setCurrentOrg: (orgDetails = {}) => ({
			type: duck.types.SET_CURRENT_USER_ORGANIZATION,
			orgDetails,
		}),
		validateUserRouteChange: routeActionType => ({
			type: duck.types.CHECK_USER_ROUTE_CHANGE,
			routeActionType,
		}),
		setActiveUserRoles: (roles = {}) => ({
			type: duck.types.SET_USER_ROLES,
			roles,
		}),
		fetchNotifications: () => ({
			type: duck.types.FETCH_NOTIFICATIONS,
		}),
		setNotifications: notifications => ({
			type: duck.types.SET_NOTIFICATIONS,
			notifications,
		}),
		singleNotificationUpdateStatus: (id, status) => ({
			type: duck.types.UPDATE_STATUS_SINGLE_NOTIFICATION,
			id,
			status,
		}),
		resetPassword: (values, helpers) => ({
			type: duck.types.RESET_PASSWORD,
			values,
			helpers,
		}),
		handleResetPasswordLoading: loading => ({
			type: duck.types.RESET_PASSWORD_LOADING,
			loading,
		}),
		switchToSuccessOnResetPassword: message => ({
			type: duck.types.RESET_PASSWORD_SUCCESS,
			message,
		}),
		flushResetPasswordState: () => ({
			type: duck.types.FLUSH_RESET_PASSWORD_STATE,
		}),
		verifyOtp: (currentUserEmail, secret, helpers) => ({
			type: duck.types.VERIFY_OTP,
			currentUserEmail,
			secret,
			helpers,
		}),
		onSuccessfulLogin: data => ({
			type: duck.types.ON_SUCCESSFUL_LOGIN,
			data,
		}),
		initiateOTP: (currentUserEmail, successToast) => ({
			type: duck.types.INITIATE_OTP,
			currentUserEmail,
			successToast,
		}),
	}),
})
