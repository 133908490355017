import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ReduxDetector from 'i18next-redux-languagedetector'
import Backend from 'i18next-chained-backend'
import Fetch from 'i18next-fetch-backend'

const Detector = new LanguageDetector()
Detector.addDetector(ReduxDetector)

export const i18nextConfig = {
	language: null,
	// whitelist: ['en', 'es', 'ms', 'id', 'zh', 'ta'],
	whitelist: ['en'],
	fallbackLng: 'en',
	ns: ['common'],
	defaultNS: 'common',
}

export default function configureI18n({
	i18nextConfig: _i18nextConfig,
	redux,
}) {
	i18n.use(Backend)
		.use(Detector)
		.use(initReactI18next)
		.init({
			backend: {
				backends: [Fetch],
				backendOptions: [
					{
						loadPath: '/locales/{{lng}}/{{ns}}.json',
					},
				],
			},
			detection: {
				order: ['localStorage', 'navigator'],
				lookupRedux: redux.lookupRedux,
				cacheUserLanguageRedux: redux.cacheUserLanguageRedux,
				lookupQuerystring: 'lng',
				lookupLocalStorage: 'I18N_LANGUAGE',
				caches: ['redux'],
				excludeCacheFor: ['cimode'],
			},
			whitelist: _i18nextConfig.whitelist,
			fallbackLng: _i18nextConfig.fallbackLng,
			ns: _i18nextConfig.ns,
			defaultNS: _i18nextConfig.defaultNS,
			debug: process.env.NODE_ENV !== 'production',
			interpolation: {
				escapeValue: false,
			},
			react: {
				// Added usesuspense due to an error
				useSuspense: false,
				wait: false,
			},
			nonExplicitWhitelist: true,
			load: 'currentOnly',
		})
}
