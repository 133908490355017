import { all, takeLatest, take, race, put } from 'redux-saga/effects'
import LogHelper from 'sales-app/utils/logger'
import {
	extractOrgIDsFromResponses,
	transformFilterStringsToBEQueries,
	extractFilterQueries,
	extractSortQueries,
} from 'sales-app/utils/shared'
import { CallWithRefreshCheck } from 'sales-app/modules/Auth/saga'
import { getIn, omit, merge } from 'timm'
import { isEmptyObject, removeDuplicates } from 'sales-app/utils/helpers'
import querySerializer from 'query-string'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { AppDuc } from './duc'

const logger = LogHelper('client:appSaga')

function* handleModal(action) {
	try {
		const { onAccept, onReject } = action || {}

		const { success } = yield race({
			success: take(AppDuc.creators.acceptedOnConfirmationModal().type),
			fail: take(AppDuc.creators.declinedOnConfirmationModal().type),
		})

		if (success && onAccept) {
			onAccept()
		} else {
			onReject()
		}
	} catch (e) {
		logger.log(e)
	}
}
export function* DocumentListing(
	requestUrl,
	locationState = {},
	PAGINATION_LIMIT = 20,
	meta = ''
) {
	try {
		const { query } = locationState
		const existingQueryFromUrl = query || {}
		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}

		if (!isEmptyObject(filterQueries)) {
			// form the backend queries from the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}
		const sortQuery = existingQueryFromUrl.sort || []
		const _sortKeys = Array.isArray(sortQuery) ? sortQuery : [sortQuery]

		const __query = {
			...backendTargetQuery,
		}
		const { data = {} } = yield CallWithRefreshCheck(
			`${requestUrl}?${querySerializer.stringify({
				...meta,
				...__query,
				...{ sort: 'desc(createdAt)' },
			})}`
		)

		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(data, ['pageSize']) || PAGINATION_LIMIT),
			total: getIn(data, ['total']),
			nextIndex: getIn(data, ['nextStartID']),
		}

		const sortQueriesFromBE = extractSortQueries(
			{ 'delivery-order': data },
			_sortKeys
		)

		// fetch org ids
		const orgIDs = extractOrgIDsFromResponses([data])
		if (orgIDs.length)
			yield put(
				AuthDuc.creators.fetchOrgDetails(removeDuplicates(orgIDs))
			)

		const { queryTree, stateTree } = extractFilterQueries(data)

		frontendTargetQuery = merge(frontendTargetQuery, queryTree)
		if (sortQueriesFromBE.length) {
			frontendTargetQuery.sort = sortQueriesFromBE
		}

		return (
			{ data, frontendTargetQuery, stateTree, serverPaginationQuery } ||
			{}
		)
	} catch (e) {
		// proxy the error back
		throw e
	}
}

export default function* AppSaga() {
	try {
		yield all([
			takeLatest(
				AppDuc.creators.showConfirmationModal({ meta: {} }).type,
				handleModal
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
