import { DibizOrgsDuc } from 'sales-app/modules/DibizOrgs/duc'
import saga from 'sales-app/modules/DibizOrgs/saga'
import { getIn } from 'timm'

export default async (dispatch, getState, { action, extra }) => {
	const { customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return
	extra.getSagaInjectors().injectSaga('DibizOrgsSaga', { saga })

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location

	const { payload } = locationState

	let activeModule = ''

	activeModule = payload.action

	if (payload.action === 'tileset-management') {
		dispatch(DibizOrgsDuc.creators.fetchTilesetData())
	} else if (payload.action === 'global-products') {
		dispatch(DibizOrgsDuc.creators.flushGlobalProductsList())
		dispatch(DibizOrgsDuc.creators.fetchGlobalProductsList())
	} else if (payload.action === 'certification-management') {
		dispatch(DibizOrgsDuc.creators.flushCertificates())
		dispatch(DibizOrgsDuc.creators.fetchCertificates())
	} else if (payload.action === 'org-groups') {
		dispatch(DibizOrgsDuc.creators.fetchOrgsList())
		dispatch(DibizOrgsDuc.creators.fetchOrganisationGroups())
	} else if (payload.action === 'dashboard') {
		dispatch(DibizOrgsDuc.creators.fetchOrganisationGroups())
		dispatch(DibizOrgsDuc.creators.fetchDashboardInsights())
	} else {
		dispatch(DibizOrgsDuc.creators.fetchOrgList(true, locationState))
	}

	dispatch(DibizOrgsDuc.creators.setActiveModule(activeModule))
	dispatch(DibizOrgsDuc.creators.resetOrgList())
	dispatch(DibizOrgsDuc.creators.reseTilesetData())
}
