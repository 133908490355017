import React from 'react'
import styled from 'styled-components'
import { Box } from 'ui-lib/utils/Box'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DibizIcon from 'ui-lib/icons/dibiz-full-logo-transparent.svg'
import { AppDuc } from 'sales-app/modules/App/duc'
import { AuthDuc } from 'sales-app/modules/Auth/duc'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'sales-app/routes/duc'

import theme from 'ui-lib/utils/base-theme'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from 'grommet'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { ButtonWithNoBorder, ButtonIconWrapper } from 'ui-lib/components/Button'

const LogoSearch = styled.div({
	height: '65px',
	zIndex: 0,
	paddingTop: '25px',
	alignItems: 'center',
	justifyContent: 'space-between',
	display: 'flex',
	width: '100%',
	flexDirection: 'row',
})

const Headers = () => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { payload = {} } = useSelector(AuthDuc.selectors.location)
	const { action } = payload

	return (
		<Box
			row={!isMobile}
			style={{
				width: 'fit-content',
				marginLeft: '4%',
				height: '90px',
				position: isMobile ? 'absolute' : 'fixed',
				left: 0,
				marginBottom: '0px',
				zIndex: 5,
				color: theme.color.white,
			}}
			justifyContent="space-between"
		>
			<Header
				pad="small"
				style={{
					width: 'inherit',
					paddingLeft: isMobile ? '0%' : '5%',
				}}
			>
				<LogoSearch justifyContent="space-between">
					<IconWrapper width={144} height={50}>
						<Icon glyph={DibizIcon} />
					</IconWrapper>
					{isMobile &&
						(action === 'forgot-password' ||
							action === 'reset-password' ||
							action === 'verify-email') && (
							<Box
								row
								justifyContent="flex-end"
								style={{
									width: '40%',
									paddingRight: '5%',
								}}
							>
								<ButtonWithNoBorder
									extendStyles={{
										fontWeight: 'bold',
										fontSize: theme.fontSize.xl,
										textDecoration: 'none',
										color: theme.color.blue5,
									}}
									label={t('common.back')}
									customIcon={
										<ButtonIconWrapper lightBG>
											<Icon glyph={LeftArrowIcon} />
										</ButtonIconWrapper>
									}
									onClick={() => {
										if (action === 'forgot-password') {
											dispatch(
												MainRouteDuc.creators.redirect(
													MainRouteDuc.types.AUTH,
													{
														action: 'sign-in',
													},
													{ redirect_to: '/' }
												)
											)
										} else if (
											action === 'reset-password'
										) {
											dispatch(
												MainRouteDuc.creators.redirect(
													MainRouteDuc.types.AUTH,
													{
														action:
															'forgot-password',
													}
												)
											)
										}
									}}
								/>
							</Box>
						)}
				</LogoSearch>
			</Header>
		</Box>
	)
}

export { Headers }
