import { useCallback } from 'react'
import debounce from 'lodash/debounce'
import { chunkArrayInGroups } from 'ui-lib/utils/helpers'
import { getIn, removeLast } from 'timm'
import { getDateByFormat } from 'sales-app/utils/date'

export const convertToTitleCase = (value = '') =>
	(value || '').replace(/\w\S*/g, txt => {
		const Pieces =
			(txt.indexOf('_') > -1 &&
				txt.split('_').map(piece => convertToTitleCase(piece))) ||
			[]

		return Pieces.length > 0
			? Pieces.join('')
			: txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
	})

/**
 * @function serializeRedirectQuery
 * @param {*} queryObject { key:'value' }
 * @description changes query object into serilized query string
 */
export function serializeRedirectQuery(queryObject = {}) {
	const populatedQuery = Object.keys(queryObject)
		.map(
			(key, index) =>
				`${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(
					typeof queryObject[key] === 'string'
						? queryObject[key]
						: JSON.stringify(queryObject[key])
				)}`
		)
		.reduce((prev, next) => {
			const agg = prev + next

			return agg
		}, '')

	return populatedQuery
}

// function that takes in an array of primitive elements and returns an array with duplicates removed
export const removeDuplicates = arr => {
	const arrayMap = {}
	arr.forEach(element => {
		arrayMap[element] = element
	})

	return Object.keys(arrayMap)
}

export const intersectFlatArrays = (arrA = [], arrB = []) =>
	removeDuplicates(arrA.filter(x => arrB.includes(x)))

// removes whitespaces from string
export const removeWhitespaces = (text = '') => text.replace(/\s/g, '')

export const convertFirstCharToLower = (text = '') =>
	text.charAt(0).toLowerCase() + text.slice(1)

export function isEmptyObject(obj) {
	for (const key in obj)
		if (Object.prototype.hasOwnProperty.call(obj, key)) return false

	return true
}

export const getNextSortOrder = currentOrder => {
	if (currentOrder === 'asc') return 'desc'
	if (currentOrder === 'desc') return 'asc'

	if (!currentOrder || currentOrder === 'remove') return 'asc'
}

export function useDebouncedCallback(callback, delay = 0, options = {}) {
	return useCallback(debounce(callback, delay, options), [
		callback,
		delay,
		options,
	])
}

/**
 * A small helper to sort the array both side. NOTE: if hinted its already in ascending, then it just reverses the array
 * @param array a
 * @param string direction asc|desc
 * @param boolean isAscending
 */
export function sortArray(
	a,
	direction = 'asc',
	fieldParsingHook = _a => _a,
	alreadyInAscending = false
) {
	if (alreadyInAscending) {
		// sorted but in different order, reverse the order
		a.reverse() // or use for loop// assume it be unsorted, use sort alogorithm
	} else {
		a.sort((_a, _b) => {
			const __a = fieldParsingHook(_a)
			const __b = fieldParsingHook(_b)

			if (direction === 'asc') {
				if (__b > __a) {
					return -1
				}
				if (__a > __b) {
					return 1
				}
			} else {
				if (__a > __b) {
					return -1
				}
				if (__b > __a) {
					return 1
				}
			}

			return 0
		})
	}

	return a
}

export const pick = (object, keys = []) =>
	keys.reduce((agg, key) => {
		const obj = agg
		if (object && Object.prototype.hasOwnProperty.call(object, key)) {
			obj[key] = object[key]
		}

		return obj
	}, {})

export const getKeysByRegex = (obj = {}, regex = '') =>
	Object.keys(obj).filter(_key => _key.match(new RegExp(regex, 'i')))

const isEmptyObjOrArray = obj =>
	(typeof obj === 'object' && isEmptyObject(obj)) ||
	(Array.isArray(obj) && !obj.length)

export const pickBy = (sourceObj = {}) => {
	const obj = {}
	for (const key in sourceObj) {
		if (
			sourceObj[key] !== null &&
			sourceObj[key] !== false &&
			sourceObj[key] !== undefined &&
			!isEmptyObjOrArray(sourceObj[key])
		) {
			obj[key] = sourceObj[key]
		}
	}

	return obj
}

export const getPairsAndOddOne = arr => {
	let targetArr = arr
	let lastOdd = null
	if (targetArr.length % 2 > 0) {
		lastOdd = targetArr[targetArr.length - 1]
		targetArr = removeLast(targetArr)
	}

	return { pairs: chunkArrayInGroups(targetArr, 2), lastOdd }
}

// regex to match the url param post domain name in the url.
// eslint-disable-next-line no-useless-escape
const urlPathRegex = /.+?\:\/\/.+?(\/.+?)(?:#|\?|$)/i

export const getRelativeUrlFromAbsoluteUrl = (urlParam = '/') => {
	let url = urlParam.toString()
	const path = urlPathRegex.exec(url)
	if (path) {
		// eslint-disable-next-line prefer-destructuring
		url = path[1] || '/' // get the matched param.

		return url
	}

	return url.indexOf('/') !== 0 ? '/' : url
}

export const transformDataForBarChart = (data = [], type) => {
	const transformedData = []

	if (type === 'products' || type === 'storage-status') {
		data.map(d =>
			transformedData.push({ label: d.name, value: d.quantity })
		)
	}

	if (type === 'certified-suppliers') {
		data.map(d => transformedData.push({ label: d.type, value: d.count }))
	}

	if (type === 'supplier-categories') {
		data.map(d => transformedData.push({ label: d.name, value: d.count }))
	}

	return transformedData
}

export const transformDataforPieChart = (data = [], type) => {
	const transformedData = []

	if (type === 'best-suppliers') {
		data.map(d => transformedData.push({ label: d.name, value: d.percent }))
	}

	if (type === 'best-smallholders') {
		data.map(d => transformedData.push({ label: d.name, value: d.value }))
	}

	if (type === 'best-blocks') {
		data.map(d =>
			transformedData.push({
				label: d.name,
				value: d.productionPercent,
			})
		)
	}

	return transformedData
}

export const getProdList = ({ products }) => {
	const productList = []
	products.map(prod =>
		productList.push({
			id: prod.id,
			label: prod.name,
			quantity: prod.quantity,
			uom: prod.uom,
		})
	)

	return productList
}

export const getSalesSvg = monthlySales => {
	if (monthlySales.currentMonth < monthlySales.previousMonth) {
		return "url('/images/red-wave.svg')"
	}
	if (monthlySales.currentMonth > monthlySales.previousMonth) {
		return "url('/images/green-wave.svg')"
	}
	if (monthlySales.currentMonth === monthlySales.previousMonth) {
		return "url('/images/blue-wave.svg')"
	}
}

export const getPurchaseSvg = monthlyPurchase => {
	if (monthlyPurchase.currentMonth < monthlyPurchase.previousMonth) {
		return "url('/images/red-wave.svg')"
	}
	if (monthlyPurchase.currentMonth > monthlyPurchase.previousMonth) {
		return "url('/images/green-wave.svg')"
	}
	if (monthlyPurchase.currentMonth === monthlyPurchase.previousMonth) {
		return "url('/images/blue-wave.svg')"
	}
}

export const transformCertforStore = details => {
	const certificates = details.map(_certificate => {
		const certificate = getIn(_certificate, ['meta', 'certificate']) || {}

		return {
			id: getIn(_certificate, ['id']) || '',
			certOrg: certificate.issuingBody || '',
			certNum: getIn(_certificate, ['number']) || '',
			expiryDate:
				getDateByFormat(getIn(_certificate, ['expiryEndDate'])) || '',
			certStartDate:
				getDateByFormat(getIn(_certificate, ['expiryStartDate'])) || '',
			certBody: certificate.certificationBody || '',
			license:
				getIn(_certificate, ['meta', 'license', 'licenseNumber']) || '',
			issueDate:
				getDateByFormat(getIn(_certificate, ['issueDate'])) || '',
			scmodel: certificate.supplychainModel || '',
			files: getIn(_certificate, ['files']) || [],
		}
	})

	return certificates
}

export const getRandomNumber = () => {
	const crypto = window.crypto || window.msCrypto
	const array = new Uint32Array(1)
	crypto.getRandomValues(array) // Compliant for security-sensitive use cases
	const number = array[0]

	return number
}
