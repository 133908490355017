import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import en from 'date-fns/locale/en-US'
import ms from 'date-fns/locale/ms'
import { AppDuc } from 'sales-app/modules/App/duc'

/** Date Locales */
const locales = {
	en,
	ms,
}

import { isDateValid } from 'ui-lib/utils/helpers'

export const getDateByFormat = (date, _format, locale) => {
	if (!isDateValid(date)) return '- - -'

	return format(new Date(date), _format || 'd LLL yyyy', {
		locale: locales[locale] || locales.en,
	})
}

/**
 * React Hook helpers
 */

/** The hooks to use in react components */

export const useDateByFormat = (date, _format) => {
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	return useMemo(() => getDateByFormat(date, _format, activeLocale), [
		date,
		_format,
		activeLocale,
	])
}

export { isDateValid }
