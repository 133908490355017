import React from 'react'
import { useSelector } from 'react-redux'
import { NotFoundBlock } from 'sales-app/utils/sharedComponents/Error'
import { CompanyInformation } from 'sales-app/modules/Admin/containers/subModules/CompanyInformation'
import { AdminDuc } from 'sales-app/modules/Admin/duc'

const modulesMap = {
	'company-information': CompanyInformation,
}

const MainDashboard = () => {
	const location = useSelector(AdminDuc.selectors.location)
	const { payload = {} } = location
	const activeModule = payload.rootModule

	const Component = modulesMap[activeModule] || NotFoundBlock

	return <Component />
}

export { MainDashboard }
