export const initialState = {
	activeModule: '',
	loading: false,
	error: false,
	allOrgsList: [],
	approvedOrgsList: [],
	newOrgsList: [],
	rejectedOrgsList: [],
	pagination: {},
	ffbPrices: [],
	globalProducts: {},
	certificates: {},
	tilesetData: {},
	orgDetailsList: [],
	organisationGroups: [],
	dashboardInsights: [],
	orgsList: [],
	orgGroupDetails: [],
}

export const stateConfig = {
	pending: [
		// {
		// 	name: 'unverified',
		// 	label: 'viewCompanyInfo.unverified',
		// },
		// {
		// 	name: 'rejected',
		// 	label: 'viewCompanyInfo.reject',
		// },
		// {
		// 	name: 'in-review',
		// 	label: 'viewCompanyInfo.inReview',
		// },
		{
			name: 'verified',
			label: 'viewCompanyInfo.approved',
		},
	],
	'in-review': [
		// {
		// 	name: 'rejected',
		// 	label: 'viewCompanyInfo.reject',
		// },
		// {
		// 	name: 'in-review',
		// 	label: 'viewCompanyInfo.inReview',
		// },
		{
			name: 'verified',
			label: 'viewCompanyInfo.approved',
		},
	],
	unverified: [
		// {
		// 	name: 'in-review',
		// 	label: 'viewCompanyInfo.inReview',
		// },
		{
			name: 'verified',
			label: 'viewCompanyInfo.approved',
		},
	],
	approved: [],
	reject: [],
}
export const titleData = {
	newRequest: 'admin.titleTab1',
	rejectedOrgs: 'admin.titleTab2',
	approvedOrgs: 'admin.titleTab4',
}
