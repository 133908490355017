/* global dataLayer, clevertap, bmsMQTTClient, _cc10651 */

/**
 * This is a analytics tracking middleware. Right now check is an action has meta of the below shape it gets tracked.
 *
 * action = {
 * 	type: <SomeType>,
 * 	payload: { somePayload },
 *  meta: {
 * 			analytics: { // can be an array of same shape
 * 				type: 'ga',wq
 * 				name, // required
 *				appC, // optional
 *				category, //optional
 *				action // optional,
 *				label // optional,
 *				path // optional,
 * 			}
 * 		}
 * }
 */
// import LogHelper from 'core-app/utils/logger'
// import { getIn } from 'timm'

// // initialize the logger for this component.
// const logger = LogHelper('redux:analyticsMiddleware')
// import { slugify, convertToTitleCase } from 'core-app/utils/helpers'

export default (
	select = ({ meta }) => meta.analytics
) => () => next => action => {
	const returnValue = next(action)
	/*
	 * ga: Google Analytics
	 * WR: Clever Tap
	 * CS: Clickstream
	 * FBP: Facebook Pixel
	 */
	const track = {
		ga: typeof dataLayer !== 'undefined' && dataLayer,
		WR: typeof clevertap !== 'undefined' && clevertap,
		CS: typeof bmsMQTTClient !== 'undefined' && bmsMQTTClient,
		FBP: typeof dataLayer !== 'undefined' && dataLayer,
		LT: typeof _cc10651 !== 'undefined' && _cc10651,
	}

	if (!action || !action.meta || !__CLIENT__) {
		return returnValue
	}

	const event = select(action)
	if (!event) {
		return returnValue
	}

	// const { location: prev } = store.getState()
	;(Array.isArray(event) ? event : [event]).forEach(eventObj => {
		const tracker = eventObj ? track[eventObj.type] : null
		// const {
		// 	name,
		// 	action: actionName = '',
		// 	label = '',
		// 	// path = prev.pathname,
		// } = eventObj

		if (!tracker) return returnValue

		// if (eventObj.type === 'ga' && name) {
		// 	const trackerObj = {
		// 		...eventObj,
		// 		event:
		// 			eventObj.action === 'click'
		// 				? 'gtmstdevent_LE'
		// 				: 'tvc_AllpageEvent', // append the event name here
		// 		action: convertToTitleCase(actionName) || '---',
		// 		label,
		// 		tvc_pagetype: slugify(name),
		// 	}
		// 	if (tracker.push) {
		// 		tracker.push(trackerObj)
		// 		logger.log('Fired google analytics: ', trackerObj)
		// 	}
		// }
	})

	return returnValue
}
