import LogHelper from 'sales-app/utils/logger'
import {
	getIAMEndPoint,
	getPlantationEndPoint,
	getInsightsEndPoint,
	getCoreEndPoint,
	getNewsFeedEndPoint,
} from 'sales-app/utils/sharedConfig'
import { getIn, merge, omit, setIn } from 'timm'
import { AppDuc } from 'sales-app/modules/App/duc'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import request from 'sales-app/utils/request'
import { getTargetFilterQueries } from 'sales-app/utils/shared'
import { DocumentListing } from 'sales-app/modules/App/saga'
import { getDateByFormat } from 'core-app/utils/date'
import { DibizOrgsDuc } from './duc'

const logger = LogHelper('client:DibizOrgsSaga')

const orgStatus = {
	newRequest: 'pending,in-review',
	rejectedOrgs: 'rejected',
	approvedOrgs: 'verified',
}

const PAGINATION_LIMIT = 20

export function* fetchOrgList(action) {
	try {
		const { skipGlobalLoader, locationState } = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('Org-listing'))

		const { payload } = locationState
		const key = payload.action

		yield put(DibizOrgsDuc.creators.fetchLoadingStatus(true))
		const requestUrl = `${getIAMEndPoint()}clients/organizations`
		const filterType = 'status'
		const filterValue = orgStatus[key]
		const prefix = 'state->'

		const currentQuery = getIn(locationState, ['query']) || {}

		const filterCurrentQueries = omit(currentQuery, ['sort', 'q'])

		const targetQueries = getTargetFilterQueries(
			'',
			filterType,
			filterValue,
			prefix
		)
		const targetFilters = merge(
			currentQuery,
			merge(filterCurrentQueries, { [filterType]: targetQueries })
		)
		const { data, serverPaginationQuery } = yield DocumentListing(
			requestUrl,
			setIn(locationState, ['query'], targetFilters),
			PAGINATION_LIMIT
		)

		yield put(
			DibizOrgsDuc.creators.setPaginationQueries(serverPaginationQuery)
		)

		yield put(DibizOrgsDuc.creators.setOrgList(data.list || []))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(DibizOrgsDuc.creators.fetchLoadingStatus(false))
		yield put(AppDuc.creators.hideGlobalLoader('Org-listing'))
	}
}

export function* fetchFFBPriceList() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('ffb-price'))
		const currentMonth = new Date().getMonth() + 1
		const currentYear = new Date().getFullYear()
		const today = new Date().getDate()

		const ffbPricingUrl = `${getPlantationEndPoint()}marketprices?createdAt=eq(${currentYear}-${currentMonth}-01T00:00:00Z~${currentYear}-${currentMonth}-${today}T23:59:59Z)&sort=asc`
		const { data = {} } = yield call(request, ffbPricingUrl)

		yield put(DibizOrgsDuc.creators.setFFBPrices(data.list || []))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('ffb-price'))
	}
}

export function* fetchOrgsList() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-org-list'))

		const requestUrl = `${getIAMEndPoint()}/clients/organizations/list-no-pagination`
		const { data = {} } = yield call(request, requestUrl)

		yield put(DibizOrgsDuc.creators.setOrgsList(data.list || []))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-org-list'))
	}
}

export function* fetchGlobalProductsList(action) {
	const { startID } = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('global-products'))

		let requestUrl = `${getCoreEndPoint()}products`
		if (startID) requestUrl += `?startID=${startID}`
		const { data = {} } = yield call(request, requestUrl)

		const finalData = {
			list: getIn(data, ['list']),
			total: getIn(data, ['total']),
			nextStartID: getIn(data, ['nextStartID']),
		}

		yield put(DibizOrgsDuc.creators.setGlobalProductsList(finalData || []))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('global-products'))
	}
}

export function* fetchOrganisationGroups() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('org-groups'))

		const requestUrl = `${getIAMEndPoint()}clients/orgGroupList`
		const { data = [] } = yield call(request, requestUrl)

		yield put(DibizOrgsDuc.creators.setOrganisationGroups(data))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('org-groups'))
	}
}

export function* fetchDashboardInsights(action) {
	const {
		orgGroupID = '',
		startDate = '',
		endDate = '',
		orgGroups = [],
	} = action

	const organisations = orgGroups
		.filter(og => og.id === orgGroupID)
		.map(org => org.organisations)
		.join(',')

	try {
		yield put(AppDuc.creators.showGlobalLoader('dashboard-insights'))

		let requestUrl = `${getInsightsEndPoint()}dashboard/sales/insights`
		const queryParams = []

		if (orgGroupID) queryParams.push(`orgIDs=${organisations}`)
		if (startDate) queryParams.push(`startDate=${startDate}`)
		if (endDate) queryParams.push(`endDate=${endDate}`)

		if (queryParams.length > 0) requestUrl += `?${queryParams.join('&')}`

		const { data = [] } = yield call(request, requestUrl)

		yield put(DibizOrgsDuc.creators.setDashboardInsights(data))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-insights'))
	}
}

export function* updateFFBPrices(action) {
	const {
		regionPrices = {},
		date = '',
		helpers: { setSubmitting },
	} = action

	try {
		const currentDate = new Date(date)

		// Convert the date to ISO format
		const isoDate = currentDate.toISOString()

		yield put(AppDuc.creators.showGlobalLoader('update-ffb-price'))

		// eslint-disable-next-line guard-for-in
		for (const region in regionPrices) {
			const priceValue = regionPrices[region]
			const mainPrice = Math.floor(priceValue)
			const fractionalPrice = priceValue.toString().split('.')[1] || 0

			const payload = {
				countryCode: 'MY',
				productCode: 'FFB',
				regionCode: region,
				priceAsOf: isoDate,
				price: {
					main: mainPrice,
					fractional: parseInt(fractionalPrice, 10),
				},
			}

			const requestUrl = `${getPlantationEndPoint()}marketprices`
			const options = {
				method: 'POST',
				body: JSON.stringify(payload),
			}

			yield call(request, requestUrl, options)
		}
		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: 'Price updated',
			})
		)
	} catch (err) {
		setSubmitting(false)

		logger.log(err)

		const { message } = err
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('update-ffb-price'))
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.DIBIZORGS, {
				action: 'newRequest',
			})
		)
	}
}

export function* addNewsAndUpdates(action) {
	const {
		payload = {},
		helpers: { setSubmitting },
	} = action

	try {
		yield put(AppDuc.creators.showGlobalLoader('add-news-updates'))

		const finalPayload = {
			...payload,
			urlContentType: 'application/json',
			publisher: 'dibiz-backoffice',
			publisherType: 'admin',
		}

		const requestUrl = `${getNewsFeedEndPoint()}news/post`

		const options = {
			method: 'POST',
			body: JSON.stringify(finalPayload),
		}

		yield call(request, requestUrl, options)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: 'Pushed updates',
			})
		)
	} catch (err) {
		setSubmitting(false)

		logger.log(err)

		const { message } = err
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('add-news-updates'))
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.DIBIZORGS, {
				action: 'newRequest',
			})
		)
	}
}

export function* fetchTilesetData(action) {
	try {
		const { fromUpdate = false, startID = '' } = action
		yield put(AppDuc.creators.showGlobalLoader('tileset-data'))
		yield put(DibizOrgsDuc.creators.fetchLoadingStatus(true))
		const orgArray = ['org-iwxP3knFEzqVi9cE']
		const queryArray = ['limit=20', 'shape=eq(status->progress)']
		if (startID) {
			queryArray.push(`startID=${startID}`)
		}
		const tilesetURL = `${getIAMEndPoint()}backoffice/clients/geodatalist?${queryArray.join(
			'&'
		)}`

		const { data = {} } = yield call(request, tilesetURL)

		if (getIn(data, ['list'])?.length > 0) {
			getIn(data, ['list']).forEach(item => {
				if (!orgArray.find(org => org === item?.organizationID)) {
					orgArray.push(item?.organizationID)
				}
			})
		}
		const orgdetailsURL = `${getIAMEndPoint()}clients/organizations?limit=100&id=${orgArray.toString()}`
		const { data: orgDetailsData } = yield call(request, orgdetailsURL)

		const finalData = {
			list: getIn(data, ['list']) || [],
			total: getIn(data, ['total']),
			nextStartID: getIn(data, ['nextStartID']),
		}
		yield put(
			DibizOrgsDuc.creators.setOrgDetailsList(orgDetailsData?.list || {})
		)
		yield put(
			DibizOrgsDuc.creators.setTilesetData(finalData || {}, fromUpdate)
		)
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(DibizOrgsDuc.creators.fetchLoadingStatus(false))
		yield put(AppDuc.creators.hideGlobalLoader('tileset-data'))
	}
}

export function* UpdateTilesetData(action) {
	try {
		const { geoCoordinate, orgID } = action
		yield put(AppDuc.creators.showGlobalLoader('tileset-data-update'))
		const tilesetURL = `${getIAMEndPoint()}backoffice/organization/${orgID}/geodata/${geoCoordinate}`

		const options = {
			method: 'PUT',
		}
		yield call(request, tilesetURL, options)

		yield put(DibizOrgsDuc.creators.fetchTilesetData(true))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('tileset-data-update'))
	}
}

export function* updateOrgGroup(action) {
	try {
		const { organisations = [], orgGroupID = '' } = action
		yield put(AppDuc.creators.showGlobalLoader('update-org-group'))
		const requestURL = `${getIAMEndPoint()}clients/orgGroup/${orgGroupID}`

		const payload = {
			organisations,
		}

		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield call(request, requestURL, options)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: 'Updated Organisation Group',
			})
		)
		yield put(DibizOrgsDuc.creators.fetchOrganisationGroups())
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('update-org-group'))
	}
}

export function* deleteOrgGroup(action) {
	try {
		const { id } = action
		yield put(AppDuc.creators.showGlobalLoader('delete-org-group'))
		const requestURL = `${getIAMEndPoint()}clients/orgGroup/${id}`

		const options = {
			method: 'DELETE',
		}
		yield call(request, requestURL, options)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: 'Deleted Organisation Group',
			})
		)
		yield put(DibizOrgsDuc.creators.fetchOrganisationGroups())
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('delete-org-group'))
	}
}

export function* addOrgGroup(action) {
	const { data } = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('add-org-group'))
		const requestURL = `${getIAMEndPoint()}clients/orgGroup`

		const options = {
			method: 'POST',
			body: JSON.stringify(data),
		}

		yield call(request, requestURL, options)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: 'Added Organisation Group',
			})
		)
		yield put(DibizOrgsDuc.creators.fetchOrganisationGroups())
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('add-org-group'))
	}
}

export function* readOrgGroup(action) {
	const { id } = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('read-org-group'))

		const requestUrl = `${getIAMEndPoint()}clients/orgGroup/${id}`
		const { data = {} } = yield call(request, requestUrl)

		yield put(DibizOrgsDuc.creators.setOrgGroupDetails(data))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('read-org-group'))
	}
}

export function* addGlobalProduct(action) {
	try {
		const { payload = {} } = action
		yield put(AppDuc.creators.showGlobalLoader('add-global-product'))
		const requestURL = `${getCoreEndPoint()}products`

		const options = {
			method: 'POST',
			body: JSON.stringify(payload),
		}

		yield call(request, requestURL, options)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: 'Added to Global Product List',
			})
		)
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('add-global-product'))
		yield put(
			MainRouteDuc.creators.switchPage(MainRouteDuc.types.DIBIZORGS, {
				action: 'global-products',
			})
		)
	}
}

export function* approveCertificate(action) {
	try {
		const { payload = {} } = action
		const { orgID = '', docID = '', userName = '' } = payload
		yield put(AppDuc.creators.showGlobalLoader('approve-certificate'))
		const requestURL = `${getIAMEndPoint()}backoffice/organizations/${orgID}/documents/${docID}/verification`

		const reqPayload = {
			meta: {
				verifiedBy: userName,
			},
		}

		const options = {
			method: 'PUT',
			body: JSON.stringify(reqPayload),
		}

		yield call(request, requestURL, options)
		yield put(DibizOrgsDuc.creators.flushCertificates())

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: 'Certificate Approved',
			})
		)
		yield put(DibizOrgsDuc.creators.fetchCertificates())
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('approve-certificate'))
	}
}

export function* fetchCertificates(action) {
	const { startID } = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('fetch-certificates'))

		let requestUrl = `${getIAMEndPoint()}backoffice/organizations/documentlist?type=certificate&meta=neq(verificationStatus->verified)`
		if (startID) requestUrl += `&startID=${startID}`
		const { data = {} } = yield call(request, requestUrl)

		const certData = data?.list?.map(d => {
			return {
				id: d?.id,
				orgID: d?.organizationID,
				certName: d?.meta?.certificate?.issuingBody,
				certNum: d?.number,
				issueDate: getDateByFormat(d?.issueDate),
				expiryDate: getDateByFormat(d?.expiryEndDate),
				certStartDate: getDateByFormat(d?.expiryStartDate),
				certBody: d?.meta?.certificate?.certificationBody,
				scModel: d?.meta?.certificate?.supplychainModel?.join(','),
				file: getIn(d, ['files', 0, 'meta', 'fullURL']),
			}
		})

		const orgRequestUrl = `${getIAMEndPoint()}/clients/organizations/list-no-pagination`
		const orgListResponse = yield call(request, orgRequestUrl)

		const finalData = {
			list: certData || [],
			orgList: orgListResponse.data.list || [],
			total: getIn(data, ['total']),
			nextStartID: getIn(data, ['nextStartID']),
		}

		yield put(DibizOrgsDuc.creators.setCertificates(finalData))
	} catch (err) {
		const { message } = err
		logger.log(err)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-certificates'))
	}
}

export default function* DibizOrgsSaga() {
	try {
		yield all([
			takeLatest(DibizOrgsDuc.creators.fetchOrgList().type, fetchOrgList),
			takeLatest(
				DibizOrgsDuc.creators.fetchFFBPriceList().type,
				fetchFFBPriceList
			),
			takeLatest(
				DibizOrgsDuc.creators.fetchTilesetData().type,
				fetchTilesetData
			),
			takeLatest(
				DibizOrgsDuc.creators.UpdateTilesetData().type,
				UpdateTilesetData
			),
			takeLatest(
				DibizOrgsDuc.creators.updateFFBPrices().type,
				updateFFBPrices
			),
			takeLatest(
				DibizOrgsDuc.creators.fetchGlobalProductsList().type,
				fetchGlobalProductsList
			),
			takeLatest(
				DibizOrgsDuc.creators.addGlobalProduct().type,
				addGlobalProduct
			),
			takeLatest(
				DibizOrgsDuc.creators.fetchCertificates().type,
				fetchCertificates
			),
			takeLatest(
				DibizOrgsDuc.creators.addNewsAndUpdates().type,
				addNewsAndUpdates
			),
			takeLatest(
				DibizOrgsDuc.creators.approveCertificate().type,
				approveCertificate
			),
			takeLatest(
				DibizOrgsDuc.creators.fetchOrganisationGroups().type,
				fetchOrganisationGroups
			),
			takeLatest(
				DibizOrgsDuc.creators.fetchDashboardInsights().type,
				fetchDashboardInsights
			),
			takeLatest(
				DibizOrgsDuc.creators.fetchOrgsList().type,
				fetchOrgsList
			),
			takeLatest(
				DibizOrgsDuc.creators.deleteOrgGroup().type,
				deleteOrgGroup
			),
			takeLatest(DibizOrgsDuc.creators.addOrgGroup().type, addOrgGroup),
			takeLatest(DibizOrgsDuc.creators.readOrgGroup().type, readOrgGroup),
			takeLatest(
				DibizOrgsDuc.creators.updateOrgGroup().type,
				updateOrgGroup
			),
		])
	} catch (err) {
		logger.log(err)
	}
}
