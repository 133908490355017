import { getIn } from 'timm'
import LogHelper from 'sales-app/utils/logger'
import { AppDuc } from 'sales-app/modules/App/duc'
import { AdminDuc } from 'sales-app/modules/Admin/duc'
import { CallWithRefreshCheck } from 'sales-app/modules/Auth/saga'
import { getDateByFormat } from 'sales-app/utils/date'
import { transformCertforStore } from 'sales-app/utils/helpers'
import { MainRouteDuc } from 'sales-app/routes/duc'
import { getIAMEndPoint } from 'sales-app/utils/sharedConfig'
import { Toast } from 'ui-lib/components/Toast'
import request from 'sales-app/utils/request'
import { all, takeLatest, put, call } from 'redux-saga/effects'

const logger = LogHelper('client:AdminSaga')

function* viewCompanyDetails(action) {
	try {
		const { companyID } = action
		yield put(AppDuc.creators.showGlobalLoader('fetch-company-info'))

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${companyID}`

		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const farmAddress =
			getIn(data, ['meta', 'properties', 0, 'other', 'farmAddress']) || {}
		const orgDetails = {
			name: getIn(data, ['name']) || '',
			firstName: getIn(data, ['firstName']) || '',
			type: getIn(data, ['categories', 0, 'name']) || '',
			city: getIn(data, ['primaryAddress', 'city']) || '',
			line1: getIn(data, ['primaryAddress', 'line1']) || '',
			line2: getIn(data, ['primaryAddress', 'line2']) || '',
			postalCode: getIn(data, ['primaryAddress', 'postalCode']) || '',
			state: getIn(data, ['primaryAddress', 'state']) || '',
			country: getIn(data, ['primaryAddress', 'country']) || '',
			location: `${getIn(data, ['primaryAddress', 'location', 'lat']) ||
				''},
			${getIn(data, ['primaryAddress', 'location', 'long']) || ''}`,
			latitude: getIn(data, ['primaryAddress', 'location', 'lat']),
			longitude: getIn(data, ['primaryAddress', 'location', 'long']),
			address: `${getIn(data, ['primaryAddress', 'line1']) ||
				''} ${getIn(data, ['primaryAddress', 'city']) || ''}
 ${getIn(data, ['primaryAddress', 'state']) || ''} ${getIn(data, [
				'primaryAddress',
				'country',
			]) || ''} ${getIn(data, ['primaryAddress', 'postalCode']) || ''}`,
			mobile: getIn(data, ['primaryContact', 'mobile']) || '',
			email: getIn(data, ['primaryContact', 'email']) || '',
			logoURL: getIn(data, ['meta', 'logo']) || '',
			farmDetails: {
				area: getIn(data, ['meta', 'properties', 0, 'area']) || '',
				plantationArea:
					getIn(data, ['meta', 'properties', 0, 'plantationArea']) ||
					'',
				plantationTime:
					getDateByFormat(
						getIn(data, ['meta', 'properties', 0, 'plantationTime'])
					) || '',
				farmAddress: `${getIn(farmAddress, ['line1']) ||
					''} ${getIn(farmAddress, ['district']) ||
					''} ${getIn(farmAddress, ['city']) || ''}
 ${getIn(farmAddress, ['state']) || ''} ${getIn(farmAddress, ['country']) ||
					''} `,
				city: getIn(farmAddress, ['city']) || '',
				line1: getIn(farmAddress, ['line1']) || '',
				district: getIn(farmAddress, ['district']) || '',
				state: getIn(farmAddress, ['state']) || '',
				country: getIn(farmAddress, ['country']) || '',
			},
		}
		const status = getIn(data, ['status', 'state']) || ''
		yield put(AdminDuc.creators.setCompanyInfo(orgDetails))
		yield put(AdminDuc.creators.setActiveStatus(status))
		const certificationsURL = `${getIAMEndPoint()}backoffice/organizations/${companyID}/documents`
		const origResponse = yield CallWithRefreshCheck(certificationsURL)
		const certificateList = getIn(origResponse, ['data', 'list']) || []

		const businessDetails = certificateList.filter(
			cert =>
				getIn(cert, ['type']) === 'gst' ||
				getIn(cert, ['type']) === 'tax' ||
				getIn(cert, ['type']) === 'other'
		)

		const _certificates = certificateList.filter(
			cert =>
				getIn(cert, ['meta', 'certificate', 'issuingBody']) ===
					'RSPO' ||
				getIn(cert, ['meta', 'certificate', 'issuingBody']) ===
					'MSPO' ||
				getIn(cert, ['meta', 'certificate', 'issuingBody']) ===
					'ISPO' ||
				getIn(cert, ['meta', 'certificate', 'issuingBody']) === 'ISCC'
		)

		const certificates = transformCertforStore(_certificates)

		yield put(AdminDuc.creators.setBusinessDetails(businessDetails))

		yield put(AdminDuc.creators.setCertificateDetails(certificates))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-company-info'))
	}
}

function* updateOrganisation(action) {
	try {
		const { values, orgID } = action

		const {
			city,
			country,
			line1,
			line2,
			state,
			postalCode,
			latitude,
			longitude,
		} = values
		const requestUrl = `${getIAMEndPoint()}backoffice/organizations/${orgID}`
		const payload = {
			primaryAddress: {
				city,
				country,
				line1,
				line2,
				state,
				postalCode,
				location: {
					lat: latitude,
					long: longitude,
				},
			},
		}

		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: 'SuccessFully Updated',
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ADMIN$WDOCREFERENCE,
				{
					rootModule: 'company-information',
					action: 'view-companyInfo',
					documentReference: orgID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateOrganisationStatus(action) {
	try {
		const { status = {}, orgID } = action
		const { status: _status } = status

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}/status/${_status}`

		const options = {
			method: 'PUT',
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: 'SuccessFully Updated',
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ADMIN$WDOCREFERENCE,
				{
					rootModule: 'company-information',
					action: 'view-companyInfo',
					documentReference: orgID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}
function* updateOrganisationLogo(action) {
	try {
		const { logoURL, orgID } = action
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const payload = {
			meta: { logo: logoURL },
		}

		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: 'Profile Picture successfully updated',
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ADMIN$WDOCREFERENCE,
				{
					rootModule: 'company-information',
					action: 'view-companyInfo',
					documentReference: orgID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* deleteSingleCertificate(action) {
	try {
		const { certID, orgID } = action

		const requestUrl = `${getIAMEndPoint()}backoffice/organizations/${orgID}/documents/${certID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: 'Successfully Deleted',
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ADMIN$WDOCREFERENCE,
				{
					rootModule: 'company-information',
					action: 'view-companyInfo',
					documentReference: orgID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}
function* updateSingleCertificate(action) {
	try {
		const { values, id, orgID } = action
		const requestUrl = `${getIAMEndPoint()}backoffice/organizations/${orgID}/documents/${id}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: 'Successfully Updated Certificate',
		})

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ADMIN$WDOCREFERENCE,
				{
					rootModule: 'company-information',
					action: 'view-companyInfo',
					documentReference: orgID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* addNewCertificate(action) {
	try {
		const { values, orgID } = action
		const requestUrl = `${getIAMEndPoint()}backoffice/organizations/${orgID}/documents`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: 'Successfully Created Certificate',
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ADMIN$WDOCREFERENCE,
				{
					rootModule: 'company-information',
					action: 'view-companyInfo',
					documentReference: orgID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateFarmDetails(action) {
	try {
		const {
			values: {
				area,
				plantationArea,
				plantationTime,
				city,
				line1,
				district,
				state,
				country,
			},
			orgID,
		} = action
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}/updateMeta`

		const payload = {
			meta: {
				properties: [
					{
						type: 'plot',
						area: parseFloat(area, 4),
						areaUOM: 'ha',
						plantationArea: parseFloat(plantationArea, 4),
						plantationAreaUOM: 'ha',
						plantationTime: new Date(plantationTime),
						other: {
							farmAddress: {
								city,
								line1,
								district,
								state,
								country,
							},
						},
					},
				],
			},
		}

		const options = {
			method: 'PATCH',
			body: JSON.stringify(payload),
		}
		yield call(request, requestUrl, options)

		yield Toast({
			type: 'success',
			message: 'Farm Details successfully updated',
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.ADMIN$WDOCREFERENCE,
				{
					rootModule: 'company-information',
					action: 'view-companyInfo',
					documentReference: orgID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* AdminSaga() {
	try {
		yield all([
			takeLatest(
				AdminDuc.creators.viewCompanyDetails().type,
				viewCompanyDetails
			),
			takeLatest(
				AdminDuc.creators.updateOrganisation().type,
				updateOrganisation
			),
			takeLatest(
				AdminDuc.creators.updateOrganisationLogo().type,
				updateOrganisationLogo
			),
			takeLatest(
				AdminDuc.creators.deleteSingleCertificate().type,
				deleteSingleCertificate
			),
			takeLatest(
				AdminDuc.creators.updateSingleCertificate().type,
				updateSingleCertificate
			),
			takeLatest(
				AdminDuc.creators.addNewCertificate().type,
				addNewCertificate
			),
			takeLatest(
				AdminDuc.creators.updateFarmDetails().type,
				updateFarmDetails
			),
			takeLatest(
				AdminDuc.creators.updateOrganisationStatus().type,
				updateOrganisationStatus
			),
		])
	} catch (err) {
		logger.log(err)
	}
}
