import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'sales-app/modules/App/duc'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { BoxShadowTable } from 'sales-app/utils/sharedComponents/BoxShadowTable'
import { MultiSelectWithSearch } from 'ui-lib/components/Select'
import { Modal } from 'ui-lib/components/Modal'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Label } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { organisationGroups } from '../components/columns'
import { DibizOrgsDuc } from '../duc'

const OrganisationGroups = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const orgGroups = useSelector(DibizOrgsDuc.selectors.getOrgGroups)
	const orgList = useSelector(DibizOrgsDuc.selectors.getOrgsList)
	const {
		name: organisationGroupName = '',
		id: orgGroupID = '',
		organisations: _organisations = [],
	} = useSelector(DibizOrgsDuc.selectors.getOrgGroupDetails)

	const _orgList = orgList.map(({ id, name }) => {
		return {
			id,
			name,
		}
	})

	const [orgGroupModalStatus, toggleOrgGroupModalStatus] = useState(false)
	const [deleteModalStatus, toggleDeleteModalStatus] = useState(false)
	const [orgID, setOrgID] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false)

	const [orgGroupName, setOrgGroupName] = useState('')
	const [organisations, setOrganisations] = useState([])

	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const onDelete = id => {
		setOrgID(id)
		toggleDeleteModalStatus(true)
	}

	const onUpdate = id => {
		setIsUpdate(true)
		dispatch(DibizOrgsDuc.creators.readOrgGroup(id))
		toggleOrgGroupModalStatus(true)
	}

	useEffect(() => {
		if (isUpdate) {
			setOrganisations(_organisations)
			setOrgGroupName(organisationGroupName)
		}
	}, [_organisations, isUpdate, organisationGroupName])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title="Global Products" />
			</Box>
			<Box row style={{ padding: '10px 0px' }} justifyContent="flex-end">
				<Button
					primary
					label="Add Organisation Group"
					onClick={() => toggleOrgGroupModalStatus(true)}
				/>
			</Box>
			<Modal
				forceCloseviaButton
				size="large"
				show={orgGroupModalStatus}
				heading={
					isUpdate
						? 'Update Organisation Group'
						: 'Add Organisation Group'
				}
				body={
					<>
						<Box>
							<Label
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Organisation Group Name
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={orgGroupName}
									name="name"
									type="text"
									disabled={isUpdate}
									onChange={e => {
										setOrgGroupName(e.name)
									}}
									returnKeyValue
								/>
							</Box>
						</Box>
						<Box style={{ width: '100%' }}>
							<Label
								required
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Select Organisations
							</Label>
							<Spacer size={8} />

							<MultiSelectWithSearch
								selected={organisations}
								value={organisations}
								options={_orgList}
								onChange={value => {
									setOrganisations(value)
								}}
								returnOnlyValue
							/>
						</Box>
					</>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.submit')}
				onClose={() => {
					toggleOrgGroupModalStatus(false)
					setOrgGroupName('')
					setOrganisations([])
					setIsUpdate(false)
				}}
				isDisabled={!orgGroupName || !organisations.length > 0}
				onConfirm={() => {
					toggleOrgGroupModalStatus(false)

					if (isUpdate) {
						dispatch(
							DibizOrgsDuc.creators.updateOrgGroup(
								organisations,
								orgGroupID
							)
						)
						setIsUpdate(false)
					} else {
						dispatch(
							DibizOrgsDuc.creators.addOrgGroup({
								name: orgGroupName,
								organisations,
							})
						)
					}
					setOrgGroupName('')
					setOrganisations([])
				}}
			/>
			<Modal
				forceCloseviaButton
				size="large"
				show={deleteModalStatus}
				heading="Delete Organisation Group"
				body={<div>Are you sure you want to delete this group?</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => toggleDeleteModalStatus(false)}
				onConfirm={() => {
					toggleDeleteModalStatus(false)
					dispatch(DibizOrgsDuc.creators.deleteOrgGroup(orgID))
				}}
			/>
			<Box>
				<BoxShadowTable
					size="large"
					scroll={!isMobile}
					tableSize={isMobile && 'small'}
					columnConfig={organisationGroups({ t, onDelete, onUpdate })}
					rowData={orgGroups}
				/>
			</Box>
		</Box>
	)
}

export default OrganisationGroups
