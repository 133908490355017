import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'sales-app/modules/App/duc'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { CTAWrapper } from 'sales-app/utils/shared'
import { BoxShadowTable } from 'sales-app/utils/sharedComponents/BoxShadowTable'
import { Modal } from 'ui-lib/components/Modal'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Label } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { globalProducts } from '../components/columns'
import { DibizOrgsDuc } from '../duc'

const GlobalProducts = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [showAddProductModal, toggleAddProductModal] = useState(false)
	const [code, setCode] = useState('')
	const [name, setName] = useState('')
	const [hsnsac, setHsnsac] = useState('')

	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { list: products, total, nextStartID } = useSelector(
		DibizOrgsDuc.selectors.getGlobalProducts
	)

	const handleShowMore = useCallback(() => {
		dispatch(DibizOrgsDuc.creators.fetchGlobalProductsList(nextStartID))
	}, [dispatch, nextStartID])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title="Global Products" />
			</Box>
			<Box row style={{ padding: '10px 0px' }} justifyContent="flex-end">
				<Button
					primary
					label="Add Product"
					onClick={() => toggleAddProductModal(true)}
				/>
			</Box>
			<Modal
				forceCloseviaButton
				size="large"
				show={showAddProductModal}
				heading="Add Global Product"
				body={
					<>
						<Box>
							<Label
								style={{
									fontWeight: '300px',
									fontSize: '16px',
									color: theme.color.accent2,
									width: '100%',
								}}
							>
								Product Name
							</Label>
							<Spacer size={8} />
							<Box style={{ width: '100%' }}>
								<Input
									value={name}
									name="name"
									type="text"
									onChange={e => setName(e.name)}
									returnKeyValue
								/>
							</Box>
						</Box>
						<Box
							row
							justifyContent="space-between"
							style={{ width: '100%' }}
						>
							<Box style={{ width: '48%' }}>
								<Label
									required
									style={{
										fontWeight: '300px',
										fontSize: '16px',
										color: theme.color.accent2,
										width: '100%',
									}}
								>
									Internal Product Code
								</Label>
								<Spacer size={8} />
								<Box style={{ width: '100%' }}>
									<Input
										value={code}
										name="code"
										type="text"
										onChange={e => setCode(e.code)}
										returnKeyValue
									/>
								</Box>
							</Box>
							<Box style={{ width: '48%' }}>
								<Label
									required
									style={{
										fontWeight: '300px',
										fontSize: '16px',
										color: theme.color.accent2,
										width: '100%',
									}}
								>
									HSNSAC
								</Label>
								<Spacer size={8} />
								<Box style={{ width: '100%' }}>
									<Input
										value={hsnsac}
										name="hsnsac"
										type="text"
										onChange={e => setHsnsac(e.hsnsac)}
										returnKeyValue
									/>
								</Box>
							</Box>
						</Box>
					</>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.submit')}
				onClose={() => {
					toggleAddProductModal(false)
					setName('')
					setCode('')
					setHsnsac('')
				}}
				isDisabled={!name || !code || !hsnsac}
				onConfirm={() => {
					toggleAddProductModal(false)
					const payload = { name, code, hsnsac, uom: 'MT' }
					dispatch(DibizOrgsDuc.creators.addGlobalProduct(payload))
					setName('')
					setCode('')
					setHsnsac('')
				}}
			/>
			<Box>
				<BoxShadowTable
					size="large"
					scroll={!isMobile}
					tableSize={isMobile && 'small'}
					columnConfig={globalProducts()}
					rowData={products}
				/>
				<CTAWrapper>
					<Label
						color="inherit"
						bold
						small
						style={{
							padding: '10px 4px',
						}}
					>
						{(products || []).length > 0
							? `${t('common.ShowingListFor')}
							${(products || []).length}
							  ${t('common.of')}
							  ${total}`
							: t('common.errorBlockMessage')}
					</Label>
					<Box
						style={{
							width: '75%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Button
							plain
							disabled={
								(products || []).length === 0 ||
								products.length === total
							}
							extendStyles={{
								background: '#F2F4FF',
								padding: '8px 24px',
								borderRadius: '24px',
								width: '170px',
								textAlign: 'center',
							}}
							label={t('common.showMore')}
							onClick={handleShowMore}
						/>
					</Box>
				</CTAWrapper>
			</Box>
		</Box>
	)
}

export { GlobalProducts }
