import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDuc } from 'sales-app/modules/App/duc'
import { Title } from 'sales-app/utils/sharedComponents/Title'
import { BoxShadowTable } from 'sales-app/utils/sharedComponents/BoxShadowTable'
import { Label } from 'ui-lib/components/Typography'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Box } from 'ui-lib/utils/Box'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { SelectSearch } from 'ui-lib/components/Select'
import theme from 'ui-lib/utils/base-theme'
import { DibizOrgsDuc } from '../duc'
import { salesDashboard } from '../components/columns'

const SalesDashboard = () => {
	const dispatch = useDispatch()
	const [orgGroupID, setOrgGroupID] = useState('')
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()

	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const orgGroups = useSelector(DibizOrgsDuc.selectors.getOrgGroups)
	const { dashBoard = {} } = useSelector(
		DibizOrgsDuc.selectors.getDashboardInsights
	)

	const orgGroupsList = orgGroups.map(({ id, name }) => {
		return {
			id,
			label: name,
		}
	})

	useEffect(() => {
		dispatch(
			DibizOrgsDuc.creators.fetchDashboardInsights(
				orgGroupID,
				startDate,
				endDate,
				orgGroups
			)
		)
	}, [orgGroupID, startDate, endDate, dispatch, orgGroups])

	return (
		<Box padding={8} width="100%" height="100%">
			<Box row style={{ padding: '0 5' }} justifyContent="space-between">
				<Title title="Dashboard" />
			</Box>
			<Box row justifyContent="space-between">
				<Box margin="20px 0px 0px">
					<SelectSearch
						name="orgGroupID"
						value={orgGroupID}
						placeholder="Select Organisation Group"
						options={orgGroupsList}
						onChange={value => {
							setOrgGroupID(value.id)
						}}
						labelKey="label"
						valueKey="id"
						style={{
							width: '350px',
							border: '1px solid #0b20aa',
							borderRadius: 4,
						}}
						returnOnlyValue
					/>
				</Box>
				<Box row>
					<Box alignItems="flex-start" width={180} margin="0px 5px">
						<Label small>From</Label>

						<DatePicker
							name="startDate"
							placeholder="Choose"
							value={startDate}
							maxDate={new Date()}
							onChange={value => {
								setStartDate(value)
							}}
						/>
					</Box>
					<Box alignItems="flex-start" width={180} margin="0px 5px">
						<Label small>To</Label>

						<DatePicker
							name="toDate"
							value={endDate}
							toDate
							maxDate={new Date()}
							placeholder="Choose"
							onChange={value => {
								setEndDate(value)
							}}
						/>
					</Box>
					<ButtonWithNoBorder
						label="Clear"
						style={{
							fontSize: theme.fontSize.xl,
							margin: '0px 12px 0px 0px',
						}}
						onClick={() => {
							setOrgGroupID('')
							setStartDate('')
							setEndDate('')
						}}
					/>
				</Box>
			</Box>
			<Box>
				<BoxShadowTable
					size="large"
					scroll={!isMobile}
					tableSize={isMobile && 'small'}
					columnConfig={salesDashboard({})}
					rowData={dashBoard}
				/>
			</Box>
		</Box>
	)
}

export { SalesDashboard }
