import Duck from 'extensible-duck'
import { merge, setIn, getIn, addLast, replaceAt } from 'timm'

const initialState = {
	loading: {
		pending: false,
		requestQueue: [],
	},
	cookies: {},
	query: {},
	toasts: [],
	isOnline: true,
	lastOnline: new Date(),
	confirmationModal: {
		show: false,
		message: '',
		confirmationLabel: 'Yes',
		declineLabel: 'No',
		isCloseable: true,
		heading: 'Confirm',
	},
	appUpdateModal: {
		show: false,
		rejectStatus: false,
		message: 'There is a new version Available',
		confirmationLabel: 'Update',
		declineLabel: 'Reject',
		isCloseable: true,
		heading: 'Update',
	},
	detection: {
		isMobile: false,
		isDesktop: false,
		isTablet: false,
		isAndroidApp: false,
		isIOSApp: false,
		isWebApp: false,
		isCustomApp: false,
		isPortrait: true,
		isLandScape: false,
		appVersion: 0,
	},
	dealerCollectionPoint: {
		isChildOrgSelected: false,
		selectedOrg: {},
		parentOrg: {},
	},
}

export const AppDuc = new Duck({
	namespace: 'app',
	store: 'global',
	types: [
		'INIT',
		'ERROR',
		'SHOW_GLOBAL_LOADER',
		'HIDE_GLOBAL_LOADER',
		'NO_ACTION_ANALYTICS',
		'SHOW_TOAST',
		'HIDE_TOAST',
		'SYNC_QUERY_PARAMS',
		'CONTROL_BODY_SCROLL',
		'HANDLE_CONFIRMATION_MODAL_SHOW',
		'HANDLE_CONFIRMATION_MODAL_HIDE',
		'HANDLE_CONFIRMATION_MODAL_DECLINE',
		'HANDLE_CONFIRMATION_MODAL_ACCEPT',
		'SET_NETWORK_CONNECTION_STATUS',
		'SET_OUTGOING_DO_LIST',
		'SAVE_USER_FEEDBACK',
		'IS_UPDATE_AVAILABLE',
		'DO_LIST',
		'SHOW_UPDATE_MODAL',
		'HANDLE_UPDATE_MODAL_HIDE',
		'UPDATE_REJECT_STATUS',
		'UPDATE_APP_VERSION',
		'SWITCH_CP',
	],
	initialState,
	reducer: (state, action, duck) => {
		if (!action) return state // will be used for ssr initial state
		switch (action.type) {
			case duck.types.INIT:
			case duck.types.ERROR:
				return state
			case duck.types.SHOW_GLOBAL_LOADER: {
				// if no name is specified, its a noop.
				if (!action.name) return state

				const currentQueue =
					getIn(state, ['loading', 'requestQueue']) || []
				const isLoaderActive = getIn(state, ['loading', 'pending'])
				if (!isLoaderActive) {
					let newState = setIn(
						state,
						['loading', 'requestQueue'],
						addLast(currentQueue, {
							name: action.name,
						})
					)
					newState = setIn(newState, ['loading', 'pending'], true)

					return newState
				}

				return state
			}
			case duck.types.HIDE_GLOBAL_LOADER: {
				// if no name is specified, its a noop.
				if (!action.name) return state

				const currentQueue =
					getIn(state, ['loading', 'requestQueue']) || []
				const isLoaderActive = getIn(state, ['loading', 'pending'])
				const newQueue = currentQueue
					.map(val => {
						if (action.name !== val.name) return val

						return null
					})
					.filter(val => val)
				if (isLoaderActive) {
					let newState = setIn(
						state,
						['loading', 'requestQueue'],
						newQueue
					)
					newState = setIn(
						newState,
						['loading', 'pending'],
						newQueue.length !== 0
					)

					return newState
				}

				return state
			}
			case duck.types.SHOW_UPDATE_MODAL: {
				const { status } = action

				return setIn(state, ['appUpdateModal', 'show'], status)
			}
			case duck.types.UPDATE_REJECT_STATUS: {
				const { status } = action

				return setIn(state, ['appUpdateModal', 'rejectStatus'], status)
			}

			case duck.types.SYNC_QUERY_PARAMS: {
				const existingQuery = getIn(state, ['query']) || {}

				return setIn(
					state,
					['query'],
					merge(existingQuery, action.query)
				)
			}

			case duck.types.SHOW_TOAST: {
				const { messageType, message } = action
				const currentPipeline = getIn(state, ['toasts']) || []
				const isDuplicate =
					currentPipeline.findIndex(t => t.message === message) > -1
				if (isDuplicate) return state

				return setIn(
					state,
					['toasts'],
					addLast(currentPipeline, { messageType, message })
				)
			}

			case duck.types.HIDE_TOAST: {
				const { removeIndex } = action
				const currentPipeline = getIn(state, ['toasts']) || []

				let targetPipeline = replaceAt(
					currentPipeline,
					removeIndex,
					'void'
				)

				// Hack to retain the positions of toasts
				if (targetPipeline.every(a => a === 'void')) targetPipeline = []

				return setIn(state, ['toasts'], targetPipeline)
			}

			case duck.types.CONTROL_BODY_SCROLL: {
				return setIn(state, ['allowBodyScroll'], action.allow)
			}
			case duck.types.HANDLE_CONFIRMATION_MODAL_SHOW: {
				const { additionalMeta } = action
				const currentConfig = getIn(state, ['confirmationModal'])

				return setIn(
					state,
					['confirmationModal'],
					merge(currentConfig, additionalMeta)
				)
			}
			case duck.types.HANDLE_CONFIRMATION_MODAL_HIDE: {
				return setIn(
					state,
					['confirmationModal'],
					initialState.confirmationModal
				)
			}
			case duck.types.HANDLE_UPDATE_MODAL_HIDE: {
				return setIn(
					state,
					['appUpdateModal'],
					initialState.appUpdateModal
				)
			}

			case duck.types.SET_NETWORK_CONNECTION_STATUS: {
				let newState = state
				if (action.isOnline)
					newState = setIn(newState, ['lastOnline'], new Date())

				return setIn(newState, ['isOnline'], action.isOnline)
			}

			case duck.types.SET_OUTGOING_DO_LIST: {
				const { list } = action

				return setIn(state, ['outgoingDOList'], list)
			}

			case duck.types.SWITCH_CP: {
				const { selectOrg } = action

				return setIn(state, ['dealerCollectionPoint'], selectOrg)
			}
			default:
				return state
		}
	},
	selectors: {
		location: state => state.location,
		app: state => state.app,
		page: state => state.page,
		lastOnline: state => getIn(state, ['app', 'lastOnline']),
		isOnline: state => getIn(state, ['app', 'isOnline']),
		activeLocale: state => getIn(state, ['i18next', 'language']),
		loading: new Duck.Selector(selectors => state =>
			getIn(selectors.app(state), ['loading', 'pending']) || false
		),
		detection: new Duck.Selector(selectors => state =>
			getIn(selectors.app(state), ['detection']) || false
		),
		toasts: state => getIn(state, ['app', 'toasts']),
		confirmationModal: state =>
			getIn(state, ['app', 'confirmationModal']) || {},
		appUpdateModal: state => getIn(state, ['app', 'appUpdateModal']) || {},
		isChildOrgSelected: state =>
			getIn(state, [
				'app',
				'dealerCollectionPoint',
				'isChildOrgSelected',
			]) || false,
		dealerCollectionPoint: state =>
			getIn(state, ['app', 'dealerCollectionPoint']) || {},
		getOutgoingDOList: state =>
			getIn(state, ['app', 'outgoingDOList']) || {},
	},

	creators: duck => ({
		init: () => ({
			type: duck.types.INIT,
		}),
		error: message => ({
			type: duck.types.ERROR,
			message,
		}),
		showGlobalLoader: name => ({
			type: duck.types.SHOW_GLOBAL_LOADER,
			name,
		}),
		hideGlobalLoader: name => ({
			type: duck.types.HIDE_GLOBAL_LOADER,
			name,
		}),
		showToast: ({ messageType = 'error', message }) => ({
			type: duck.types.SHOW_TOAST,
			messageType,
			message,
		}),
		hideToast: removeIndex => ({
			type: duck.types.HIDE_TOAST,
			removeIndex,
		}),
		wrapAnalytics: (action, analytics) => ({
			...action,
			meta: {
				analytics,
				...(action.meta || {}),
			},
		}),
		NoActionAnalytics: () => ({
			type: duck.types.NO_ACTION_ANALYTICS,
		}),
		syncQueryParams: (query = {}) => ({
			type: duck.types.SYNC_QUERY_PARAMS,
			query,
		}),
		controlBodyScroll: control => ({
			type: duck.types.CONTROL_BODY_SCROLL,
			control,
		}),
		showConfirmationModal: ({
			onAccept,
			onReject,
			meta: {
				message,
				confirmationLabel,
				declineLabel,
				isCloseable,
				heading,
			},
		}) => ({
			type: duck.types.HANDLE_CONFIRMATION_MODAL_SHOW,
			onAccept,
			onReject,
			additionalMeta: {
				message,
				confirmationLabel,
				declineLabel,
				isCloseable,
				heading,
				show: true,
			},
		}),
		hideConfirmationModal: () => ({
			type: duck.types.HANDLE_CONFIRMATION_MODAL_HIDE,
		}),
		acceptedOnConfirmationModal: () => ({
			type: duck.types.HANDLE_CONFIRMATION_MODAL_ACCEPT,
		}),
		declinedOnConfirmationModal: () => ({
			type: duck.types.HANDLE_CONFIRMATION_MODAL_DECLINE,
		}),
		setNetworkConnectivityStatus: isOnline => ({
			type: duck.types.SET_NETWORK_CONNECTION_STATUS,
			isOnline,
		}),
		setOutgoingDOList: list => ({
			type: duck.types.SET_OUTGOING_DO_LIST,
			list,
		}),
		saveUserFeedback: (feedback, module) => ({
			type: duck.types.SAVE_USER_FEEDBACK,
			feedback,
			module,
		}),
		isUpdateAvailable: () => ({
			type: duck.types.IS_UPDATE_AVAILABLE,
		}),
		DOList: () => ({
			type: duck.types.DO_LIST,
		}),
		showUpdateModal: status => ({
			type: duck.types.SHOW_UPDATE_MODAL,
			status,
		}),
		updateRejectStatus: status => ({
			type: duck.types.UPDATE_REJECT_STATUS,
			status,
		}),
		hideUpdateModal: () => ({
			type: duck.types.HANDLE_UPDATE_MODAL_HIDE,
		}),
		updateAppVersion: () => ({
			type: duck.types.UPDATE_APP_VERSION,
		}),
		switchCP: selectOrg => ({
			type: duck.types.SWITCH_CP,
			selectOrg,
		}),
	}),
})
