import { getIn } from 'timm'
import { isMarketplaceHost } from '../utils/helpers'

const lookUpConfig = key =>
	getIn(window._env_, [key]) || getIn(process.env, [key]) || ''

// All the config env variables should starts with REACT_APP_

export const getIAMEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_IAM_URL'
			: 'REACT_APP_IAM_URL'
	) || ''}/`

export const getMapBoxAccessToken = () =>
	lookUpConfig('REACT_APP_MAPBOX_ACCESS_TOKEN') || ''
