import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { getIn } from 'timm'
import { getDateByFormat } from 'sales-app/utils/date'

const TABLE_ALIAS = {
	regDate: 'admin.regDate',
	orgName: 'admin.orgName',
	orgType: 'admin.orgType',
	location: 'admin.location',
}

export const getColumnConfig = ({ onChange, t }) => {
	return [
		{
			property: 'regDate',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.regDate)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'orgName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.orgName)}</TableTitle>
				</Box>
			),
			render: datum => {
				const orgName = getIn(datum, ['name']) || '---'

				return <Label as="span">{orgName}</Label>
			},
		},
		{
			property: 'orgType',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.orgType)}</TableTitle>
				</Box>
			),
			render: datum => {
				const orgType =
					getIn(datum, ['categories', '0', 'name']) || '---'

				return <Label as="span">{orgType}</Label>
			},
		},
		{
			property: 'location',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.location)}</TableTitle>
				</Box>
			),
			render: datum => {
				const location =
					getIn(datum, ['primaryAddress', 'state']) ||
					getIn(datum, ['primaryAddress', 'country']) ||
					'---'

				return <Label as="span">{location}</Label>
			},
		},
		{
			render: datum => {
				const status = getIn(datum, ['status', 'state'])
				if (status === 'in-review') {
					return (
						<Box
							style={{
								width: '108px',
								height: '24px',
								background:
									'#C7E6E1 0% 0% no-repeat padding-box',
								borderRadius: '5px',
								justifyContent: 'center',
								opacity: '0.9',
							}}
						>
							<Label
								as="span"
								style={{
									font: 'normal normal medium 17px Roboto',
									letterSpacing: '0px',
									color: '#579589',
									opacity: '1',
									textAlign: 'center',
								}}
							>
								{status}
							</Label>
						</Box>
					)
				}
			},
		},

		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label="VIEW"
						onClick={() =>
							onChange('open_document', { row: datum })
						}
					/>
				)
			},
		},
	]
}

export const ffbPriceColumnConfig = () => {
	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Date</TableTitle>
				</Box>
			),
			render: datum => {
				console.log('+++datum+++', datum)

				return <Label as="span">---</Label>
			},
		},
		{
			property: 'North / Utara',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>North / Utara</TableTitle>
				</Box>
			),
			render: datum => {
				const orgName = getIn(datum, ['name']) || '---'

				return <Label as="span">{orgName}</Label>
			},
		},
		{
			property: 'South / Selatan',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>South / Selatan</TableTitle>
				</Box>
			),
			render: datum => {
				const orgType =
					getIn(datum, ['categories', '0', 'name']) || '---'

				return <Label as="span">{orgType}</Label>
			},
		},
		{
			property: 'Central / Tengah',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Central / Tengah</TableTitle>
				</Box>
			),
			render: datum => {
				const location =
					getIn(datum, ['primaryAddress', 'state']) ||
					getIn(datum, ['primaryAddress', 'country']) ||
					'---'

				return <Label as="span">{location}</Label>
			},
		},
		{
			property: 'East Coast / Timur',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>East Coast / Timur</TableTitle>
				</Box>
			),
			render: datum => {
				const location =
					getIn(datum, ['primaryAddress', 'state']) ||
					getIn(datum, ['primaryAddress', 'country']) ||
					'---'

				return <Label as="span">{location}</Label>
			},
		},
		{
			property: 'Sabah',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Sabah</TableTitle>
				</Box>
			),
			render: datum => {
				const location =
					getIn(datum, ['primaryAddress', 'state']) ||
					getIn(datum, ['primaryAddress', 'country']) ||
					'---'

				return <Label as="span">{location}</Label>
			},
		},
		{
			property: 'Sarawak',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Sarawak</TableTitle>
				</Box>
			),
			render: datum => {
				const location =
					getIn(datum, ['primaryAddress', 'state']) ||
					getIn(datum, ['primaryAddress', 'country']) ||
					'---'

				return <Label as="span">{location}</Label>
			},
		},
	]
}

export const salesDashboard = () => {
	return [
		{
			property: 'orgName',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Organization</TableTitle>
				</Box>
			),
			render: datum => {
				const orgName = getIn(datum, ['organisationName'])

				return <Label as="span">{orgName}</Label>
			},
		},
		{
			property: 'DO Raised',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Delivery Order Raised</TableTitle>
				</Box>
			),
			render: datum => {
				const doRaised = getIn(datum, ['entitiesOutgoing'])

				return <Label as="span">{doRaised}</Label>
			},
		},
		{
			property: 'DO Received',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Delivery Order Received</TableTitle>
				</Box>
			),
			render: datum => {
				const doReceived = getIn(datum, ['entitiesIncoming'])

				return <Label as="span">{doReceived}</Label>
			},
		},
		{
			property: 'WB attached',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Weighbridge Attached</TableTitle>
				</Box>
			),
			render: datum => {
				const wbAttached = getIn(datum, ['weighbridge'])

				return <Label as="span">{wbAttached}</Label>
			},
		},
		{
			property: 'Production Mapping',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Production Mapping</TableTitle>
				</Box>
			),
			render: datum => {
				const productionMapping = getIn(datum, ['production'])

				return <Label as="span">{productionMapping}</Label>
			},
		},
	]
}

export const globalProducts = () => {
	return [
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Product Name</TableTitle>
				</Box>
			),
			render: datum => {
				const productName = getIn(datum, ['name']) || '---'

				return <Label as="span">{productName}</Label>
			},
		},
		{
			property: 'HSN',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>HSN</TableTitle>
				</Box>
			),
			render: datum => {
				const hsn = getIn(datum, ['hsnsac']) || '---'

				return <Label as="span">{hsn}</Label>
			},
		},
	]
}

export const getTilesetColumnConfig = ({
	onChange,
	t,
	orgDetailsList = [],
}) => {
	return [
		{
			property: 'gisReference',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', marginLeft: '30px' }}
				>
					<TableTitle>{t('tileset.gisReference')}</TableTitle>
				</Box>
			),
			render: datum => {
				const gisRef = getIn(datum, ['id']) || '---'

				return (
					<Label as="span" style={{ marginLeft: '30px' }}>
						{gisRef}
					</Label>
				)
			},
			size: '20%',
		},
		{
			property: 'tilesetId',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', marginLeft: '30px' }}
				>
					<TableTitle>{t('tileset.tilesetId')}</TableTitle>
				</Box>
			),
			render: datum => {
				const tilesetId = getIn(datum, ['shape', 'tilesetId']) || '---'

				return (
					<Label as="span" style={{ marginLeft: '30px' }}>
						{tilesetId}
					</Label>
				)
			},
			size: '20%',
		},
		{
			property: 'tilesetName',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', marginLeft: '30px' }}
				>
					<TableTitle>{t('tileset.tilesetName')}</TableTitle>
				</Box>
			),
			render: datum => {
				const tilesetName =
					getIn(datum, ['shape', 'tilesetName']) || '---'

				return (
					<Label as="span" style={{ marginLeft: '30px' }}>
						{tilesetName}
					</Label>
				)
			},
			size: '20%',
		},
		{
			property: 'organisation',
			header: (
				<Box
					row
					alignItems="center"
					style={{ cursor: 'pointer', marginLeft: '30px' }}
				>
					<TableTitle>{t('tileset.organisation')}</TableTitle>
				</Box>
			),
			render: datum => {
				const org = orgDetailsList?.find(
					item => item.id === datum?.organizationID
				)
				const orgName = getIn(org, ['name']) || '---'

				return (
					<Label as="span" style={{ marginLeft: '30px' }}>
						{orgName}
					</Label>
				)
			},
			size: '20%',
		},

		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.update')}
						onClick={() => onChange('update', { row: datum })}
					/>
				)
			},
			size: '20%',
		},
	]
}

export const organisationGroups = ({ t, onDelete, onUpdate }) => {
	return [
		{
			property: 'org-group-name',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>Organisation Group</TableTitle>
				</Box>
			),
			render: datum => {
				const productName = getIn(datum, ['name']) || '---'

				return <Label as="span">{productName}</Label>
			},
		},
		{
			property: 'no-of-orgs',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>No. of Organisations</TableTitle>
				</Box>
			),
			render: datum => {
				const numOfOrgs = (getIn(datum, ['organisations']) || []).length

				return <Label as="span">{numOfOrgs}</Label>
			},
		},
		{
			property: 'delete',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.delete')}
						onClick={() => {
							onDelete(datum.id)
						}}
					/>
				)
			},
		},
		{
			property: 'update',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.update')}
						onClick={() => {
							onUpdate(datum.id)
						}}
					/>
				)
			},
		},
	]
}
